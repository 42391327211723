// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.xlsx"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import defaultLoader from '../../../assets/images/default_loader.gif'
import { initGA } from "../../../assets/js/gtag"
import customStyles from "../../../assets/css/customTableStyle"

function DuplicateTracking(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pending, setPending] = useState(true)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [viewModal, setViewModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)

  const [rowId, setRowId] = useState();

  const [scrolling, setScrolling] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const [filterType, setFilterType] = useState(
    localStorage.getItem("order-filter")
      ? localStorage.getItem("order-filter")
      : "FedEx"
  )

  const [invoiceData, setInvoiceData] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)

  const [refundCompletedOrderId, setRefundCompletedOrderId] = useState("");
  const [refundOrderId, setRefundOrderId] = useState("");
  const [refundOrderUniqueId, setRefundOrderUniqueId] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundUserId, setRefundUserId] = useState("");
  const [refundUserEmail, setRefundUserEmail] = useState("");

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: 'User',
      // selector: 'email',
      sortable: true,
      width: "250px",
      selector: row => row.email !== null ? row.email : "-"
    },
    {
      name: "Service Name",
      selector: row => row.service_name,
      //  sortable: true,
      width: "180px",
    },
    {
      name: "To Name",
      // selector: "to_name",
      //  sortable: true,
      // width: "160px",
      selector: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: "Tracking Code",
      // selector: ["tracking_code"],
      sortable: false,
      width: "260px",
      selector: row =>
        row.tracking_code ? (
          <>
            {/*<a*/}
            {/*  target={"_blank"}*/}
            {/*  rel={"noreferrer"}*/}
            {/*  href={`/track/${row.tracking_code}`}*/}
            {/*>*/}
            {row.tracking_code}
            {/*</a>*/}
            <span style={{ color: "#e3e3e3", cursor: "pointer",marginLeft:"5px" }} className={"bx bx-clipboard"} title={"Copy Tracking Number"} onClick={() => {navigator.clipboard.writeText(row.tracking_code);toast.success("Copied!")}}></span>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   name: "Cancellation Status",
    //   selector: ["cancel_status"],
    //   sortable: false,
    //   width: "180px",
    //   cell: row =>
    //     row.cancel_status ? (
    //       <span className={"badge badge-success"}>{row.cancel_status}</span>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      name: "Created At",
      // selector: "created_at",
      sortable: true,
      width: "180px",
      selector: row => moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
    },
    {
      name: "Actions",
      // selector: ["id", "order_id", "discounted_price", "order_unique_id", "user_id", "email"],
      //  sortable: true,
      width: "150px",
      button: true,
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      selector: row => (
        <>
          <button title={"View Order Details"} style={{ marginRight: "10px" }}
                  className={"btn btn-warning btn-sm"}
                  onClick={() => {
                    setRowId(row.order_id)
                    setViewModal(true)
                  }}
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
          <button title={"Refund Order"} style={{ marginRight: "10px" }}
                  className={"btn btn-danger btn-sm"}
                  onClick={() => handleRefundInitiate(row.id, row.order_unique_id, row.discounted_price, row.user_id, row.email, row.order_id)}
          >
            <i className={"bx bx-x"}></i>
          </button>
        </>
      ),
    },
    {
      name: '',
      selector: row => row.order_unique_id,
      width: "0px"
    },
  ];

  const handleRefundInitiate = (completed_order_id, order_unique_id, discounted_price, user_id, email, order_id) => {
    setRefundCompletedOrderId(completed_order_id);
    setRefundOrderId(order_id);
    setRefundOrderUniqueId(order_unique_id);
    setRefundAmount(discounted_price);
    setRefundUserId(user_id);
    setRefundUserEmail(email);
    setRefundModal(true);
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("download-checkbox")
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }
  }

  const retrieveOrdersCancelled = async () => {
    // console.log("here");
    let data = [];
    setFilteredData(data);
    setTableData({columns, data});
    setPending(true);
    setLoading(true);
    try {
      get("/admin/get-duplicated-tracking-orders", { headers }).then(
        response => {
          if(response.status == 200) {
            let data = response.data;
          // console.log("data",data);
            setLoading(false);
            setFilteredData(data);

            // setTableData({columns, data});

            setPending(false);
          }
        }
      )
    } catch (e) {
    }
  }

  useEffect(() => {
    retrieveOrdersCancelled();
  }, [])

  const handleOnPageChange = () => {
    document.getElementById("select-all-checkbox").checked = false;
    if(selectedOrders.length > 0) {
      for (let i=0;i<selectedOrders.length;i++) {
        if(document.getElementById(`order-${selectedOrders[i]}`)) {
          document.getElementById(`order-${selectedOrders[i]}`).checked = true;
        }
      }
    }
  }


  const handleRowClick = row => {
    document.getElementById(`order-${row.pdf}`).click();
  }


  const handleRefund = async () => {
    try {
      let body = {
        completed_order_id: refundCompletedOrderId,
        refunding_amount: refundAmount,
        user_id: refundUserId,
        email: refundUserEmail,
        order_id: refundOrderId,
        order_unique_id: refundOrderUniqueId
      }
    // console.log("body", body);

      post('/admin/refund-duplicate-tracking-order', body, {headers}).then((response) => {
        if(response.status == 200) {
          toast.success(response.message);
          setRefundCompletedOrderId("")
          setRefundOrderId("")
          setRefundAmount("")
          setRefundUserId("")
          setRefundUserEmail("")
          retrieveOrdersCancelled();
          setRefundModal(false);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {

    }
  }

  //meta title
  document.title = `Duplicate Tracking Orders | ${config.APP_DETAILS.APP_NAME}`

  return (
    <div className="page-content" style={{position:"relative"}}>
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Duplicate Tracking Orders" />
      {/*<span style={{fontSize:"12px",fontWeight:"normal",position:"fixed", bottom:"80px", right:"0px",padding:"5px", backgroundColor:"white",zIndex:"999", display: (selectedOrdersCount>0?"block":"none")}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>*/}
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
          <CardBody>
            {/*<h1>page {page}</h1>*/}
            {filteredData.length>0?filteredData.map((el,index) => (
              <>
                <h6>Tracking Code: <b>{el.tracking_code}</b><br/>Duplication Count: <b>{el.orders.length}</b></h6>
                <DataTable
                  key={index}
                  columns={columns} customStyles={customStyles}
                  data={el.orders}
                  // pagination={50}
                  progressPending={pending}
                  progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                  // paginationPerPage={50}
                  // paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  highlightOnHover={true}
                  onChangePage={handleOnPageChange}
                  className={"order-table"}
                />
                <br/>
                <br/>
                </>
            )) :(!loading?<h4>No Duplicate Tracking Found</h4>:<h4>Loading..</h4>)}

          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

        {/*Modal*/}
        <Modal
          isOpen={refundModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setRefundModal(!refundModal)
          }}
          onClosed={() => {
            setRefundCompletedOrderId("")
            setRefundOrderId("")
            setRefundAmount("")
            setRefundUserId("")
            setRefundUserEmail("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setRefundModal(!refundModal)
              }}
            >
              Refund Order ({refundOrderUniqueId})
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleRefund();
                }}
              >
                <label>Refunding Amount</label>
                <input type={"number"} step="any" onWheel={(e) => e.target.blur()} value={refundAmount} required={true} placeholder={"Enter Refunding Amount"} onChange={(e) => setRefundAmount(e.target.value)} className={"form-control non-arrow-number"}/>
                <br />
                <button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Refund
                </button><br/><br/>
                <p className={"text-danger"}>Refunding {refundAmount?"$"+refundAmount:""} to {refundUserEmail}</p>
              </Form>
              {/*<br />*/}
              {/*<br />*/}
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  )
}
DuplicateTracking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DuplicateTracking
