// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"
import config from "./../../../config"

import { saveAs } from 'file-saver';

import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

function EasypostWebhookLogs() {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);

  const columns = [
    {
      name: 'Batch ID',
      selector: row => row.batch_id,
      //  sortable: true,
      width: '380px'
    },
    {
      name: 'Status',
      selector: row => row.status,
      //  sortable: true,
      width: '170px'
    },
    {
      name: 'Response',
      // selector: 'json',
      //  sortable: true,
      width: '400px',
      selector: row => <span onDoubleClick={() => handleDownloadError(row.json)} title={"Double click to download"}>{row.json.toString().substring(0,100)}</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      width: "210px",
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    }
  ];

  const handleDownloadError = (error) => {
    const data = error.toString();
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    saveAs(blob, 'error-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
    toast.success("Error log downloaded");
  }


  // const tableData = ;
  const retrieveLogs = async () => {
    try {
      get('/admin/get-easypost-webhook-logs', { headers }).then((response) => {
        // // console.log("response", response);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveLogs();
  }, []);


  //meta title
  document.title = `Easypost Webhook Logs | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Logs" breadcrumbItem="Easypost Webhook" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Easypost Webhook Logs...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
EasypostWebhookLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default EasypostWebhookLogs;