// src/components/filter.
import React, { useEffect, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import config from "./../../../config"


function AddUser(props) {
   
  // Page Title
   document.title = `Add User | ${config.APP_DETAILS.APP_NAME}`;

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };

   const [referredData, setReferredData] = useState([]);
   const [userEmails, setUserEmails] = useState({})
   
   // Get User Emails from API
  const retrieveUsers = () => {
    try {
      get('/admin/getUsers', {headers}).then((response) => {
        // console.log("getUsers", response);
        let arr = [];
        for(let i=0; i<response.data.length; i++){
          arr.push({value: response.data[i].id, label:response.data[i].email})
        }
        setReferredData(arr)
      }).catch(e => console.log("ee",e))
    }
    catch (e) {
      // // console.log(e)
    }
  }

  // Emails Select 
  const handleEmailSelection = (key, value) => {
    setUserEmails(prevUserEmail => ({...prevUserEmail, [key]:value}))
  }

  // Add User
  const hanldeAddUser = (e) => {
    e.preventDefault();
    const body = {
      created_by: JSON.parse(localStorage.getItem("authUser")).id,
      refer_by_user_id: userEmails.referredBy.value,
      refer_user_id: userEmails.referredTo.value
    }

    post('/admin/create-referrer', body, {headers}).then((response) => {
      console.log("Add User Response", response);
      if (response.status == 200) {
        toast.success(response.message, "Successfull");
        setTimeout(() => {
          props.history.push('/referral')
        }, 1000);
      } else {
        toast.error(response.message, "Error");
      } 
    })
  }
  
  useEffect(() => {
    retrieveUsers();
  },[])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Reference" breadcrumbItem="Add User" />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden-not">
              <CardBody>
                <div className="">
                  <Form className="form-horizontal">
                    <div className="mb-3">
                      <Label className="form-label">Email Referred By <span style={{color:"red"}}>*</span></Label>
                      <Select
                        options={referredData.filter((item) => item?.label != userEmails['referredTo']?.label)}
                        placeholder="Select Email"
                        value={userEmails['referredBy']}
                        onChange={value => handleEmailSelection('referredBy', value)}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Referred To <span style={{color:"red"}}>*</span></Label>
                      <Select
                        options={referredData.filter((item) => item?.label != userEmails['referredBy']?.label)}
                        // options={referredTo}
                        placeholder="Select Email"
                        value={userEmails['referredBy'] === '' ? "Select Email" : (userEmails['referredTo'])}
                        onChange={value => handleEmailSelection('referredTo', value)}
                        isSearchable={true}
                        isClearable={true}
                        isDisabled={userEmails['referredBy'] ? false : true}
                      />
                    </div>
                    <div>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-success w-md"
                          type="submit"
                          onClick={(e) => hanldeAddUser(e)}
                          disabled={userEmails['referredTo'] ? false : true}
                        >
                          Add User
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddUser;