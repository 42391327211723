// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle
} from "reactstrap"
import avatar from "../../../assets/images/users/avatar-1.jpg";
import config from "./../../../config"
import customStyles from "../../../assets/css/customTableStyle"
function AdminTickets(props) {
  let fileInput = useRef(null);
  const headersMultipart = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [pending, setPending] = useState(true);

  const [modal, setModal] = useState(false);
  const [ticketThread, setTicketTread] = useState([]);

  const [ticketId, setTicketId] = useState("");
  const [ticketUserId, setTicketUserId] = useState("");
  const [attachments, setAttachments] = useState([]);

  const columns = [
    {
      name: 'Ticket ID',
      selector: row => row.unique_id,
    //  sortable: true,
      width: "180px"
    },
    {
      name: 'User Name',
      // selector: ['first_name','last_name'],
      width: '150px',
    //  sortable: true,
      selector: row => row.first_name + " " + row.last_name
    },
    {
      name: 'Email',
      // selector: 'email',
      width: '230px',
    //  sortable: true,
      selector: row => row.email?row.email:"-"
    },
    {
      name: 'Category',
      // selector: 'category',
      width: '150px',
    //  sortable: true,
      selector: row => row.category?row.category:"-"
    },
    {
      name: 'Status',
      // selector: ['id', 'status', 'response_status'],
    //  sortable: true,
      width: "200px",
      selector:
        row =>
          <select className={"form-select"} disabled={row.status === "Closed" ? true: false} style={{border:(row.status === "Open" ? "2px solid #f46a6a" : (row.status === "Closed" ? "2px solid #34c38f" : "2px solid #f1b44c"))}} onChange={handleStatusChange} data-id={row.id}>
            <option value={"Open"} selected={row.status === "Open" ? true : false}>Open</option>
            <option value={"Waiting For Response"}selected={row.status === "Waiting For Response" ? true : false}>Waiting For Response</option>
            <option value={"Closed"} selected={row.status === "Closed" ? true : false}>Closed</option>
          </select>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      width: '120px',
     sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "170px",
      selector: (row) =>
        <>
          <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleThread(row.id)}>View Thread</button>
        </>
    },
  ];

  const handleStatusChange = async (e) => {
    const id = e.target.getAttribute("data-id");
    const {value} = e.target;
    let body = {
      id: id,
      status: value
    }
    // console.log("bodyyy",body);
    post('/admin/update-ticket-status', body, {headers}).then((response) => {
      if(response.status === 200) {
        toast.success(response.message);
        retrieveTickets();
      }
      else {
        toast.error(response.message);
      }
    })
  }


  const handleThread = async (id) => {
    setTicketId(id);
    const body = {
      id: id
    };
    post('/user/get-ticket-by-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        setTicketTread(response.data);
        setTicketUserId(response.data[0].user_id);
        validationRespond.resetForm();
        fileInput.current = null;
        setModal(true);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  const retrieveTickets = async () => {
    try {
      get('/admin/get-tickets', {headers}).then((response) => {
        setData(response.data)
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveAttachments = async () => {
    try {
      get('/user/get-attachments', {headers}).then((response) => {
        setAttachments(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleViewFile = async (fileName) => {
    get(`/user/ticket-attachment/${fileName}`, {headers,responseType: 'blob'}).then((response) => {
      if(response instanceof Blob){
        const url = URL.createObjectURL(response);
        window.open(url,'_blank');
      }
      else {
        // console.log("not a blob")
      }
    });
  }

  const handleChange = (event) => {
    // console.log(fileInput.current.files);
  };

  const validationRespond = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: (values) => {
      dispatch(handleResponseTicket(values));
    }
  });

  const handleResponseTicket = (values) => {
    try {
      const {message} = values;
      let loading = toast.loading("Responding Ticket...");
      document.body.style.pointerEvents = "none";
      const formData = new FormData();
      for (let i=0;i<fileInput.current.files.length;i++) {
        formData.append('files', fileInput.current.files[i]);
      }
      formData.append('message', message);
      formData.append('user_id', JSON.parse(localStorage.getItem("authUser")).id);
      formData.append('ticket_belongs_to', ticketUserId);
      formData.append('ticket_id', ticketId);
      postFile('/user/user-respond-ticket', formData, {headersMultipart}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        if(response.status === 200) {
          retrieveAttachments().then(() => {
            const body = {
              id: ticketId
            };
            post('/user/get-ticket-by-id', body, {headers}).then((responsee) => {
              if(responsee.status === 200) {
                setTicketTread(responsee.data);
                validationRespond.resetForm();
                fileInput.current.value = null;
                document.getElementById("fileInput").files = null;
                toast.success(response.message);
              }
            });
          })
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    if(JSON.parse(localStorage.getItem("authUser")).role_id === 2) {
      props.history.push("/my/tickets");
    }
    retrieveTickets().then(() => {
      retrieveAttachments();
    });
  }, []);

  //meta title
  document.title = `Tickets | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Tickets" />
        <Card>
          <CardBody>
            {/*<h4 className="card-title mb-4">Tickets</h4>*/}
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Ticket...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Ticket Thread
            </ModalHeader>
            <ModalBody className={"ticket-bd"}>
              {/*{ticketThread[0].message}*/}
              {ticketThread.map((part, id) => {
                return (
                  <div className={"col-md-12 mt-4 mb-4 shadow-sm rounded p-4 " +(part.user_id === JSON.parse(localStorage.getItem("authUser")).id ? "admin-response" : "user-response")} key={id}>
                    <h5><img
                      src={avatar}
                      alt=""
                      className="avatar-sm rounded-circle img-thumbnail"
                    /> {part.first_name + " " + part.last_name} {(part.user_id === JSON.parse(localStorage.getItem("authUser")).id ? <span style={{fontSize:"10px"}} className={"badge badge-success"}>Admin</span> : "")}
                      <span style={{float:"right",fontSize:"12px"}}>{moment(part.created_at).tz(moment.tz.guess()).format("DD MMM, YYYY hh:mm A")}</span>
                    </h5>
                    <div className={"container border rounded shadow-xm p-2"}>
                      {part.message}
                    </div><br/>
                    <h6>Attachments</h6>
                    {attachments.some(el=>el.ticket_id===part.id)?attachments.map((att, attId) => {
                      return (
                        <>
                          {att.ticket_id === part.id? <span className={"badge badge-success m-2 p-2"} onClick={() => handleViewFile(att.file_name)}>{att.file_name.toString().substring(19, att.file_name.toString().length)}</span>: ""}
                        </>
                      )
                    }):<p className={"text-center"}>No Attachment Found</p>}
                  </div>
                );
              })}
              {ticketThread.length>0?(ticketThread[0].status==="Closed"?"":
                <div className="col-md-12">
                  <hr/>
                  <Card className="overflow-hidden">
                    <CardBody>
                      <CardTitle className="mb-4">Respond Ticket</CardTitle>
                      <div className="">
                        <form
                          className="form-horizontal"
                          encType={"multipart/form-data"}
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationRespond.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Message</Label>
                            <Input
                              name="message"
                              // value={name}
                              className="form-control"
                              placeholder="Enter Message"
                              type="textarea"
                              onChange={validationRespond.handleChange}
                              onBlur={validationRespond.handleBlur}
                              value={validationRespond.values.message || ""}
                              invalid={
                                validationRespond.touched.message && validationRespond.errors.message ? true : false
                              }
                            />
                            {validationRespond.touched.message && validationRespond.errors.message ? (
                              <FormFeedback type="invalid">{validationRespond.errors.message}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Select Attachments</Label>
                            <input
                              type="file"
                              className="form-control"
                              ref={fileInput}
                              id={"fileInput"}
                              multiple={true}
                              accept={"image/*,.pdf,.xlsx,.csv"}
                              onChange={handleChange} />
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-success w-md "
                                type="submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </CardBody>
                  </Card>
                </div>):""
              }
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
AdminTickets.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default AdminTickets;