// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Order bulk import template.csv'
import templateXlsx from '../../../assets/templete/Order bulk import template.xlsx'
import templateXls from '../../../assets/templete/Order bulk import xls template.xls'
import axios from 'axios';
import ups_logo from '../../../assets/images/ups-logo.png';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody, CardHeader,ButtonDropdown,Dropdown, DropdownItem,DropdownToggle, DropdownMenu
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import config from "./../../../config"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"

let selectedOrders = [];
function ShopifyOtherOrders(props) {

  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const [failedOrders, setFailedOrders] = useState([]);
  const [isRetry, setIsRetry] = useState(false);


  // const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);

  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [selectAvailableService, setSelectAvailableService] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedCarrierName, setSelectedCarrierName] = useState(null);
  const [selectAvailableCarrier, setSelectAvailableCarrier] = useState(false);

  const [balance, setBalance] = useState("-");

  const [calculatedAmounts, setCalculatedAmounts] = useState([]);
  const [calculatedAmountsAboveFiveLbs, setCalculatedAmountsAboveFiveLbs] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [filterType, setFilterType] = useState(localStorage.getItem("shopify-order-filter")?localStorage.getItem("shopify-order-filter"):"NoService");
  const [pending, setPending] = useState(true);

  const [drp_link, setdrp_link] = useState(false);

  const [verificationModal, setVerificationModal] = useState(false);

  const [orderErrors, setOrderErrors] = useState([]);
  const [orderErrorModal, setOrderErrorModal] = useState(false);

  const [rowId, setRowId] = useState()

  const [stores, setStores] = useState([]);

  const columns = [
    // {
    //   name: <input type={"checkbox"} title={"Select All"} className={"select-all-checkbox"} onChange={checkAll} />,
    //   selector: ['id','name'],
    //   // sortable: true,
    //   width: "60px",
    //   cell: row => <input type={"checkbox"} className={"order-checkbox"} id={`order-${row.id}`} value={row.id} name="order_id" onChange={handleOrderCheck}/>
    // },
    // {
    //   name: 'Order ID',
    //   selector: 'order_unique_id',
    //   sortable: true,
    //   cell: row => row.order_unique_id ? row.order_unique_id : "-"
    // },
    // {
    //   name: 'Store Name',
    //   selector: 'store_name',
    //   sortable: true,
    //   // cell: row => row.name ? row.name : <span style={{ color: "red" }}>Not Assigned</span>
    // },
    // {
    //   name: 'Service Name',
    //   selector: 'name',
    //   sortable: true,
    //   cell: row => row.name ? row.name : <span style={{ color: "red" }}>Not Assigned</span>
    // },
    {
      name: 'From Name',
      selector: 'from_name',
      sortable: true,
      cell: row => row.from_name ? row.from_name : "-"
    },
    {
      name: 'To Name',
      selector: 'to_name',
      sortable: true,
      cell: row => row.to_name ? row.to_name : "-"
    },
    // {
    //   name: 'Created At',
    //   selector: 'created_at',
    //   sortable: true,
    //   width: "150px",
    //   cell: (row) => moment(row.created_at).format('MMM DD, YYYY')
    // },
    {
      name: 'Updated At',
      selector: 'updated_at',
      sortable: true,
      width: "150px",
      cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      selector: ['id', 'error_message', 'is_verified'],
      sortable: true,
      width: "100px",
      style: {
        textAlign:"center",
        justifyContent:"center"
      },
      // cell: (row) => row.error_message ? <span className={"badge badge-danger"}>{row.error_message}</span> : "-"
      cell: (row) => row.is_verified == 1 && row.error_message != "Try Again"?<span className={"badge badge-success"}>Passed</span>:(row.error_message ?
        <>
          <span className={"badge badge-danger"} style={{cursor:"pointer"}} onClick={() => {handleFailedOrderErrorMessage(row.id)}}>Failed</span>
          {/*<span className={"text-danger fw-medium text-center"} title={row.error_message.toString().replaceAll("null,","")}><i style={{cursor:"pointer",fontSize:"15px"}} className={"bx bx-error"}></i></span>*/}
        </> : "-")
    },
    {
      name: 'Financial Status',
      selector: 'store_order_status',
      sortable: true,
      width: "150px",
      cell: (row) => row.store_order_status?
        JSON.parse(row.store_order_status).financial_status?JSON.parse(row.store_order_status).financial_status:"null"
        :"-"
    },
    {
      name: 'Fulfillment Status',
      selector: 'store_order_status',
      sortable: true,
      width: "160px",
      cell: (row) => row.store_order_status?
        JSON.parse(row.store_order_status).fulfillment_status?JSON.parse(row.store_order_status).fulfillment_status:"null"
        :"-"
    },
    {
      name: 'Actions',
      selector: 'id',
      width: "150px",
      button: true,
      cell: (row) =>
        <>
          <Button className={"btn btn-success btn-sm"} onClick={() => {
            setRowId(row.id)
            setViewModal(true)
          }} style={{ float: "right", fontSize: "13px", marginRight: "10px" }} title="View Order"><i className={"bx bx-file"}></i></Button>
          {/*<Button className={"btn btn-light btn-sm"} data-id={row["id"]} onClick={() => {*/}
          {/*  props.history.push("/order/edit/" + row["id"])*/}
          {/*}} style={{ float: "right", fontSize: "13px", marginRight: "10px" }} title="Edit Order"><i className={"bx bx-edit"}></i></Button>*/}
          {/*<Button className={"btn btn-danger btn-sm"} onClick={async () => { await handleDelete(row.id) }} style={{ float: "right", fontSize: "13px" }} title="Delete Order"><i className={"bx bx-trash"}></i></Button>*/}
        </>
    }
  ];

  const handleFailedOrderErrorMessage = async (id) => {
    try {
      get(`/user/get-order-errors/${id}`, { headers }).then((response) => {
        // console.log("get-order-errors", response);
        if(response.status === 200) {
          // console.log(response.data[0].error_message.split(","));
          setOrderErrors(response.data[0].error_message);
          setOrderErrorModal(true);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleOrderCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      selectedOrders.push(value);
    }
    else {
      let index = selectedOrders.findIndex(el => el == value);
      selectedOrders.splice(index, 1);
    }
  }

  const retrieveServices = async () => {
    try {
      get('/admin/get-services', { headers }).then((response) => {
        // // console.log("response", response);
        setServices(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carriers', { headers }).then((response) => {
        // console.log("carriers", response);
        setCarriers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveNoServiceOrders = async () => {
    try {
      localStorage.setItem("shopify-order-filter", 'NoService');
      setPending(true);
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/shopify/get-no-service-orders', body, { headers }).then((response) => {
        // // console.log("response", response);
        // setData(response.data.filter(el => JSON.parse(el.store_order_status).financial_status != "paid" || JSON.parse(el.store_order_status).financial_status == "paid" && JSON.parse(el.store_order_status).fulfillment_status != null && JSON.parse(el.store_order_status).fulfillment_status != "unshipped"));
        setFilteredData(response.data.filter(el => JSON.parse(el.store_order_status).financial_status != "paid" || JSON.parse(el.store_order_status).financial_status == "paid" && JSON.parse(el.store_order_status).fulfillment_status != null && JSON.parse(el.store_order_status).fulfillment_status != "unshipped"));
        let data = response.data.filter(el => JSON.parse(el.store_order_status).financial_status != "paid" || JSON.parse(el.store_order_status).financial_status == "paid" && JSON.parse(el.store_order_status).fulfillment_status != null && JSON.parse(el.store_order_status).fulfillment_status != "unshipped");
        // console.log("response.data" ,response.data);
        // console.log("table data" , data);
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveFedexOrders = async () => {
    try {
      localStorage.setItem("shopify-order-filter", 'FedEx');
      setPending(true);
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/shopify/get-fedex-orders', body, { headers }).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsOrders = async () => {
    try {
      localStorage.setItem("shopify-order-filter", 'UPS');
      setPending(true);
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/shopify/get-ups-orders', body, { headers }).then((response) => {
        // setData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveDhlOrders = async () => {
    try {
      localStorage.setItem("shopify-order-filter", 'DHL');
      setPending(true);
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/shopify/get-dhl-orders', body, { headers }).then((response) => {
        // // console.log("response", response);
        // setData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveUspsOrders = async () => {
    try {
      localStorage.setItem("shopify-order-filter", 'USPS');
      setPending(true);
      let body ={
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/shopify/get-usps-orders', body, {headers}).then((response) => {
        // // console.log("response", response);
        // setData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const getUserBalance = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/get-balance', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance);
        setBalance(response.balance);
      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  useEffect(() => {
    // // console.log("here");
    setIsRetry(false);
    setFailedOrders([]);
    setResponseSuccess(null);
    selectedOrders = [];
    getUserBalance();
    retrieveOrdersByFilterType();
    retrieveStores();
    // retrieveOrders();
    retrieveCarriers().then(() => {
      retrieveServices();
    });
  }, []);

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };

  const retrieveOrdersByFilterType = async () => {
    if (filterType == "NoService") {
      await retrieveNoServiceOrders();
    }
    if (filterType == "FedEx") {
      await retrieveFedexOrders();
    }
    else if (filterType == "UPS") {
      await retrieveUpsOrders()
    }
    else if (filterType == "DHL") {
      await retrieveDhlOrders()
    }
    else if (filterType == "USPS") {
      await retrieveUspsOrders()
    }

    return true;
  }

  const handleImportSubmit = event => {
    event.preventDefault();
    setModal(!modal);
    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Importing shopify orders...");
    // if(selectedService !== "") {
    const formData = new FormData();
    formData.append('file', fileInput.current.files[0]);
    formData.append('id', JSON.parse(localStorage.getItem("authUser")).id);

    postFile("/shopify/import-orders", formData).then((response) => {
      // // console.log("response", response);
      if (response.status === 200) {
        retrieveOrdersByFilterType().then(() => {
          document.body.style.pointerEvents = "auto";
          toast.remove(loading);
          toast.success(response.message);
        });
      }
      else {
        toast.error(response.message);
      }
    })
  };

  const handleDelete = async (id) => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure you want to delete this order?");
    if (confrm) {
      const body = {
        id: id
      };
      let loading = toast.loading("Deleting Order...");
      document.body.style.pointerEvents = "none";
      post('/user/delete-order', body, { headers }).then((response) => {
        // // console.log("delete-order", response);
        document.body.style.pointerEvents = "auto";
        if (response.status === 200) {
          if (filterType === "FedEx") {
            retrieveFedexOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message);
            });
          }
          else if (filterType === "UPS") {
            retrieveUpsOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message);
            })
          }
          else if (filterType === "DHL") {
            retrieveDhlOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message);
            })
          }
          else if (filterType === "USPS") {
            retrieveUspsOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message);
            })
          }
          // toast.success(response.message);
          // retrieveOrders();
        }
        else {
          toast.remove(loading)
          toast.success(response.message);
        }
      })
    }
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName('order-checkbox');
    let selectAllCheckbox = document.getElementsByClassName('select-all-checkbox');
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false;
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  function checkAll(e) {
    const { checked } = e.target;
    let checkboxes = document.getElementsByClassName('order-checkbox');
    selectedOrders = [];

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false;
        selectedOrders = [];
      }
      else {
        checkboxes[i].checked = true;
        selectedOrders.push(checkboxes[i].value);
      }
    }
  }

  const fedexValidation = async () => {
    let loading = toast.loading("Validating Orders...");
    return new Promise(function(resolve, reject) {
      let body = {
        ids: selectedOrders.toString()
      }
      post('/user/validate-fedex-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        resolve(response);
      }).catch(() => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Your request was not processed completely, Please try again")
      });
    })
  }

  const upsValidation = async () => {
    let loading = toast.loading("Validating Orders...");
    return new Promise(function(resolve, reject) {
      let body = {
        ids: selectedOrders.toString()
      }
      post('/user/validate-ups-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        resolve(response);
      }).catch(() => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Your request was not processed completely, Please try again")
      });
    })
  }

  const dhlValidation = async () => {
    let loading = toast.loading("Validating Orders...");
    return new Promise(function(resolve, reject) {
      let body = {
        ids: selectedOrders.toString()
      }
      post('/user/validate-dhl-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        resolve(response);
      }).catch(() => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Your request was not processed completely, Please try again")
      });;
    })
  }

  const uspsValidation = async () => {
    let loading = toast.loading("Validating Orders...");
    return new Promise(function(resolve, reject) {
      let body = {
        ids: selectedOrders.toString()
      }
      post('/user/validate-usps-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        resolve(response);
      }).catch(() => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Your request was not processed completely, Please try again")
      });;
    })
  }

  const handleValidate = async () => {
    document.body.style.pointerEvents = "none";
    if(filterType == "FedEx") {
      let response = await fedexValidation();
      selectedOrders = response.success_orders;
      return {
        'orders': response.success_orders
      };
    }
    else if(filterType == "UPS") {
      let response = await upsValidation();
      selectedOrders = response.success_orders;
      return {
        'orders': response.success_orders
      };
    }
    else if(filterType == "DHL") {
      let response = await dhlValidation();
      selectedOrders = response.success_orders;
      return {
        'orders': response.success_orders
      };
    }
    else if(filterType == "USPS") {
      let response = await uspsValidation();
      selectedOrders = response.success_orders;
      return {
        'orders': response.success_orders
      };
    }
  }

  const handleVerification = async () => {
    if (selectedOrders.length > 0) {
      handleValidate().then((data) => {
        document.body.style.pointerEvents = "auto";
        setVerificationModal(!verificationModal);
      });
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleCalculatedPrice = async () => {
    retrieveOrdersByFilterType().then(() => {
      setVerificationModal(false);
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: selectedOrders.toString()
      };
      post('/user/calculate-order-amount', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          // // console.log("data", response);
          setCalculatedAmounts(response.data);
          setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs);

          let price = 0;
          if(response.data.length > 0) {
            price = await response.data.map(item => item.price).reduce((prev, next) => prev + next);
          }


          let above5lbsPrice = 0;
          if(response.dataAboveFiveLbs.length > 0) {
            above5lbsPrice = await response.dataAboveFiveLbs.map(item => item.price).reduce((prev, next) => prev + next);
          }

          // console.log("price", price);
          // console.log("above5lbsPrice", above5lbsPrice);

          setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice));
          setOrderDetailsModal(!orderDetailsModal);
        }
      });
    });
  }

  const handleBulkDelete = async () => {
    if (selectedOrders.length > 0) {
      let confirmation = confirm("Are you sure you want to delete these orders?");
      if(confirmation) {
        let loading = toast.loading("Deleting Orders...");
        document.body.style.pointerEvents = "none";
        let body = {
          ids: selectedOrders.toString()
        };
        post('/user/delete-bulk-orders', body, { headers }).then(async (response) => {
          if (response.status === 200) {
            toast.remove(loading);
            document.body.style.pointerEvents = "auto";
            await uncheckAll();
            selectedOrders = [];
            retrieveOrdersByFilterType().then(() => {
              toast.success(response.message);
            });
          }
          else {
            toast.remove(loading);
            toast.error(response.message);
          }
        });
      }
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleExport = async () => {
    if(selectedOrders.length > 0) {
      let loading = toast.loading("Exporting orders...");
      document.body.style.pointerEvents = "none";
      const header = "FromCountry,FromName,FromCompany,FromPhone,FromStreet1,FromStreet2,FromCity,FromZip,FromState,ToCountry,ToName,ToCompany,ToPhone,ToStreet1,ToStreet2,ToCity,ToZip,ToState,Length,Height,Width,Weight";
      let orders = "";

      let body = {
        ids: selectedOrders.toString()
      };
      post('/user/get-bulk-orders-by-ids', body, { headers }).then((response) => {
        // console.log("bulk orders export", response);
        if(response.status === 200) {
          let ordersByApi = response.data;
          for (let i=0;i<ordersByApi.length;i++) {
            let order = ordersByApi[i];
            orders = orders + `${order.from_country},${order.from_name},${order.from_company},${order.to_phone},${order.from_street1},${order.from_street2},${order.from_city},${order.from_zip},${order.from_state},${order.to_country},${order.to_name},${order.to_company},${order.to_phone},${order.to_street1},${order.to_street2},${order.to_city},${order.to_zip},${order.to_state},${order.length},${order.height},${order.width},${order.weight}\n`
          }
        }
        // // console.log("orders",orders);
        return response;
      }).then((response) => {
        if(response.status === 200) {
          let data = header + "\n" + orders;
          const blob = new Blob([data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, 'order-ids-'+moment(new Date).format("DD-MMM-YYYY")+'.csv');
          uncheckAll();
          selectedOrders = [];
          toast.success("Orders exported successfully");
          document.body.style.pointerEvents = "auto";
          toast.remove(loading);
        }
        else {
          document.body.style.pointerEvents = "auto";
          toast.remove(loading);
          toast.error(response.message);
        }
      })
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleRetry = async () => {
    if (failedOrders.length > 0) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: failedOrders.toString()
      };
      post('/user/calculate-order-amount', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          // // console.log("data", response);
          setCalculatedAmounts(response.data);
          setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs);
          setOrderDetailsModal(!orderDetailsModal);
        }
      });
    }
  }

  const upsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    post('/user/print-orders-ups', body, { headers }).then(async (response) => {
      // // console.log("response", response);
      toast.remove(loading);
      if (response.pdf && response.pdf.length > 0) {
        const pdfUrls = response.pdf;
        const zip = new JSZip();
        let loadingComp = toast.loading("Creating compressed file...");
        new Promise(async function(resolve, reject) {
          for (let i = 0; i < pdfUrls.length; i++) {
            await get('user/get-pdf/' + pdfUrls[i], { headers })
              .then(async response => {
                await zip.file(pdfUrls[i], response);
                if(i === (pdfUrls.length - 1)) {
                  resolve(zip);
                }
              });
          }
        }).then(async () => {
          await zip.generateAsync({ type: "blob" })
            .then(function (content) {
              toast.remove(loadingComp);
              saveAs(content, "orders.zip");
            });
        })
      }
      selectedOrders = [];
      await getUserBalance();
      await retrieveUpsOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      if(response.success_orders.length > 0 ) {
        let body = {
          data: response.success_orders
        };
        // console.log("generate tracking body", body);
        await post('/user/generate-tracking', body, {headers});
      }
    });
  }

  const fedExPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: isRetry ? failedOrders.toString() : selectedOrders.toString()
    };
    post('/user/print-orders-fedex-updated', body, { headers }).then(async (response) => {
      // // console.log("print-orders response", response);
      toast.remove(loading);
      if (response.failedOrders.length > 0) {
        setFailedOrders(response.failedOrders);
        setIsRetry(true);
      }
      else {
        setFailedOrders([]);
        setIsRetry(false);
      }
      if (response.pdf && response.pdf.length > 0) {
        const pdfUrls = response.pdf;
        const zip = new JSZip();

        let loadingComp = toast.loading("Creating compressed file...");
        new Promise(async function(resolve, reject) {
          for (let i = 0; i < pdfUrls.length; i++) {
            await get('user/get-pdf/' + pdfUrls[i], { headers })
              .then(async response => {
                await zip.file(pdfUrls[i], response);
                if(i === (pdfUrls.length - 1)) {
                  resolve(zip);
                }
              });
          }
        }).then(async () => {
          await zip.generateAsync({ type: "blob" })
            .then(function (content) {
              toast.remove(loadingComp);
              saveAs(content, "orders.zip");
            });
        })
      }
      selectedOrders = [];
      await retrieveFedexOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      if(response.success_orders.length > 0 ) {
        let body = {
          data: response.success_orders
        };
        // console.log("generate tracking body", body);
        await post('/user/generate-tracking', body, {headers});
      }
    });
  }

  const dhlPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    post('/user/submit-orders-dhl-usps', body, { headers }).then(async (response) => {
      // // console.log("print-orders response", response);
      setTimeout(async () => {
        toast.remove(loading);
        await getUserBalance();
        await retrieveDhlOrders();
        selectedOrders = [];
        uncheckAll();
        document.body.style.pointerEvents = "auto";
        if (response.status === 200) {
          setResponseSuccess(true);
          setResponseMessage(response.message);
        }
        else {
          toast.remove(loading);
          setResponseSuccess(false);
          setResponseMessage(response.message);
        }
      },3000)
    });
  }

  const uspsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    post('/user/submit-orders-dhl-usps', body, { headers }).then(async (response) => {
      // // console.log("print-orders response", response);
      setTimeout(async () => {
        toast.remove(loading);
        await getUserBalance();
        await retrieveUspsOrders();
        selectedOrders = [];
        uncheckAll();
        document.body.style.pointerEvents = "auto";
        if (response.status === 200) {
          setResponseSuccess(true);
          setResponseMessage(response.message);
        }
        else {
          toast.remove(loading);
          setResponseSuccess(false);
          setResponseMessage(response.message);
        }
      },3000)
    });
  }

  const handleProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders");
        await fedExPrint();
      }
      else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders");
        await upsPrint();
      }
      else if (filterType === "DHL") {
        // //DHL
        // console.log("printing dhl orders");
        await dhlPrint();
      }
      else if (filterType === "USPS") {
        // //DHL
        // console.log("printing usps orders");
        await uspsPrint();
      }
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleService = async (e) => {
    const { value } = e.target;
    // console.log("selected service", selectedService);
    setSelectedService(value);
    setSelectedServiceName(e.target.getAttribute("data-name"));
  }

  const handleNoServiceOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    setFilterType("NoService");
    selectedOrders = [];
    uncheckAll();
    retrieveNoServiceOrders();
  }

  const handleFedExOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    setFilterType("FedEx");
    selectedOrders = [];
    uncheckAll();
    retrieveFedexOrders();
  }

  const handleUPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    setFilterType("UPS");
    selectedOrders = [];
    uncheckAll();
    retrieveUpsOrders();
  }

  const handleDHLOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    setFilterType("DHL");
    selectedOrders = [];
    uncheckAll();
    retrieveDhlOrders();
  }

  const handleUSPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    setFilterType("USPS");
    selectedOrders = [];
    uncheckAll();
    retrieveUspsOrders();
  }

  const handleCarrier = async (e) => {
    setSelectedCarrier(e.target.value);
    setSelectedCarrierName(e.target.getAttribute("data-name"));
    setSelectedService('');
    setSelectedServiceName(null);
    setSelectAvailableService(false);
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id");
    for(let i=0;i<ele.length;i++) {
      ele[i].checked = false;
    }
  }

  const handleImport = () => {
    setModal(!modal);
    setSelectedCarrier('');
    setSelectedCarrierName(null);
    setSelectedService('');
    setSelectedServiceName(null);
    setSelectAvailableService(false);
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id");
    for(let i=0;i<ele.length;i++) {
      ele[i].checked = false;
    }
  }

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/shopify/getStores', body, {headers}).then((response) => {
        // console.log("response", response);
        setStores(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleImportShopifyOrder = async () => {
    const body = {
      store_id: stores[0].id,
      user_id: JSON.parse(localStorage.getItem("authUser")).id
    };
    let loading = toast.loading(`Importing ${stores[0].name} orders...`);
    post('/shopify/import-shopify-orders', body, {headers}).then(async (response) => {
      // console.log("import-shopify-orders", response);
      toast.remove(loading);
      if(response.status === 200) {
        await retrieveNoServiceOrders();
        toast.success(`${stores[0].name} orders imported successfully`);
      }
      else {
        toast.success(response.message);
      }
    })
  }

  //meta title
  document.title = `Shopify Orders | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Shopify" breadcrumbItem="Other Orders" />
        {/* <Table columns={columns} data={data} /> */}
        {/*<Card>*/}
        {/*  <CardBody>*/}
        {/*    <div className={"row"}>*/}
        {/*      <div className={"col-md-12 col-lg-11 col-xl-10 col-xxl-8 btn-div"} style={{ height: "fit-content" }}>*/}
        {/*        /!*<button onClick={() => props.history.push("/order/create")} className={"btn btn-success btn-sm waves-effect waves-light"}>Create Order</button>*!/*/}
        {/*        /!*<button onClick={handleImport} className={"btn btn-primary btn-sm waves-effect waves-light"}>Import Shopify Orders By File</button>*!/*/}
        {/*        /!*<button onClick={handleImportShopifyOrder} className={"btn btn-primary btn-sm waves-effect waves-light"}>Import Shopify Orders</button>*!/*/}
        {/*      </div>*/}
        {/*      <div className={"col-md-1 col-lg-1 col-xl-2 col-xxl-4"} style={{ position: "relative" }}>*/}
        {/*        <br/>*/}
        {/*        {*/}
        {/*          JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?*/}
        {/*            <div className={"order-desktop-balance"} style={{ right: '10px', top: '-10px', position: "absolute" }}>*/}
        {/*              <h6>Balance<br /><span style={{ fontSize: "1.5rem" }}>${amountFormat(balance)}</span></h6>*/}
        {/*            </div> : ""*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </CardBody>*/}
        {/*</Card>*/}
        <Card className={"order-mobile-balance"}>
          <CardBody>
            {
              JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                <div>
                  <h6>Balance<hr /><span style={{ fontSize: "1.5em" }}>${amountFormat(balance)}</span></h6>
                </div> : ""
            }
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {/*<ButtonDropdown*/}
            {/*  isOpen={drp_link}*/}
            {/*  toggle={() => {*/}
            {/*    setdrp_link(!drp_link);*/}
            {/*  }}*/}
            {/*  style={{float:"right",marginLeft:"10px"}}*/}
            {/*>*/}
            {/*  <DropdownToggle className={"btn btn-sm"} caret color="light">*/}
            {/*    <i className="mdi mdi-dots-vertical"></i>*/}
            {/*  </DropdownToggle>*/}
            {/*  <DropdownMenu>*/}
            {/*    <DropdownItem onClick={handleBulkDelete}>Bulk Delete</DropdownItem>*/}
            {/*    <DropdownItem onClick={handleExport}>Export Orders</DropdownItem>*/}
            {/*  </DropdownMenu>*/}
            {/*</ButtonDropdown>*/}
            {/*{filterType==="DHL"?<button className={"btn btn-sm btn-warning"} style={{float:"right"}} onClick={handleVerification}>Submit</button>:(filterType==="USPS"?<button className={"btn btn-sm btn-warning"} style={{float:"right"}} onClick={handleVerification}>Submit</button>:<button className={"btn btn-sm btn-warning"} style={{float:"right"}} onClick={handleVerification}>Print <i className={"bx bx-printer"}></i></button>)}*/}
            {/*{failedOrders.length>0?<button className={"btn btn-danger"} style={{float:"right",marginRight:"10px"}} onClick={handleRetry}>Retry Failed Orders <i className={"bx bx-redo"}></i></button>:""}*/}
            {/*<button className={"btn btn-sm btn-outline-dark " + (filterType==="NoService"?"active":"")} style={{marginRight:"5px"}} onClick={handleNoServiceOrders}>No Service</button>*/}
            {/*<div className={"vr"}></div>*/}
            {/*<button className={"btn btn-sm btn-outline-dark " + (filterType==="FedEx"?"active":"")} style={{marginRight:"5px"}} onClick={handleFedExOrders}>FedEx</button>*/}
            {/*<div className={"vr"}></div>*/}
            {/*<button className={"btn btn-sm btn-outline-dark " + (filterType === "UPS" ? "active" : "")} style={{ marginLeft: "5px", marginRight: "5px" }} onClick={handleUPSOrders}>UPS</button>*/}
            {/*<div className={"vr"}></div>*/}
            {/*<button className={"btn btn-sm btn-outline-dark " + (filterType==="DHL"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} onClick={handleDHLOrders}>DHL</button>*/}
            {/*<div className={"vr"}></div>*/}
            {/*<button className={"btn btn-sm btn-outline-dark " + (filterType==="USPS"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} onClick={handleUSPSOrders}>USPS</button>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {responseSuccess === false ? (
              <>
                <br/>
                <Alert color="danger">
                  {responseMessage}
                </Alert>
              </>
            ) : null}
            {responseSuccess === true ? (
              <>
                <br/>
                <Alert color="success">
                  {responseMessage} {isRetry ? <span>{failedOrders.length} orders failed due to excessive resource consumption, Please <span style={{ fontWeight: "bold", textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={handleRetry}>Try Again</span></span> : ""}
                </Alert>
              </>
            ) : null}
            <br />
            {/*<h4 style={{ marginBottom: "-40px" }}>{filterType} Orders</h4>setData */}
            <DataTableExtensions
              {...tableData}
              // exportHeaders={true}
              filterPlaceholder={`Filter Orders...`}
            >
              <DataTable keyField={"id"} columns={columns} data={filteredData} pagination={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={50} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={orderDetailsModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          toggle={() => {
            setOrderDetailsModal(!orderDetailsModal);
            selectedOrders = [];
          }}
        >
          <div>
            <ModalHeader toggle={() => {
              setOrderDetailsModal(!orderDetailsModal);
              selectedOrders = [];
            }}>
              Order Cost Calculation
            </ModalHeader>
            <ModalBody>
              <table className={"table table-bordered"}>
                <tbody>
                <tr style={{border:"0px"}}>
                  <th style={{border:"0px"}}>
                    <img src={filterType == "FedEx" ? fedex : (filterType == "UPS" ? ups: (filterType == "USPS" ? usps : dhl))} height={"50px"} />
                  </th>
                  <th style={{ textAlign: "right",border:"0px" }}>Current Balance<br/><h4>${amountFormat(balance)}</h4></th>
                </tr>
                </tbody>
              </table>
              <br />
              <table className={"table table-bordered"}>
                <thead>
                <tr>
                  <th>Service</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                  <th>Discount Type</th>
                  <th>Discount</th>
                  <th>Final Cost</th>
                </tr>
                </thead>
                <tbody>
                {calculatedAmounts.length > 0 ?
                  calculatedAmounts.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td>{el.service_name}</td>
                        <td style={{ textAlign: "right" }}>${amountFormat(el.actual_service_price)}</td>
                        <td style={{ textAlign: "right" }}>{el.quantity}</td>
                        <td>{el.applied_discount !== 'None' ? el.applied_discount : "-"}</td>
                        <td>{el.discount_type !== 'None' ? el.discounted_value + " " + el.discount_type : "-"}</td>
                        <td style={{ textAlign: "right" }}>${amountFormat(el.price)}</td>
                      </tr>
                    );
                  }):<tr><td colSpan={6}>-</td></tr>
                }
                <tr>
                  <th className={"bg-light"} colSpan={6}>Above 5 lb. Orders</th>
                </tr>
                {calculatedAmountsAboveFiveLbs.length > 0 ?
                  calculatedAmountsAboveFiveLbs.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td>{el.service_name}</td>
                        <td style={{ textAlign: "right" }}>${amountFormat(el.actual_service_price)}</td>
                        <td style={{ textAlign: "right" }}>{el.quantity}</td>
                        <td>{el.applied_discount !== 'None' ? el.applied_discount : "-"}</td>
                        <td>{el.discount_type !== 'None' ? el.discounted_value + " " + el.discount_type : "-"}</td>
                        <td style={{ textAlign: "right" }}>${amountFormat(el.price)}</td>
                      </tr>
                    );
                  }) : <tr><td colSpan={6}>-</td></tr>}
                </tbody>
                <tfoot>
                <tr>
                  <th style={{ textAlign: "right" }} colSpan={5}>Total</th>
                  {/*<th style={{textAlign:"right"}}>{calculatedAmounts.length>0?calculatedAmounts.map(item => item.price).reduce((prev, next) => prev + next):0}</th>*/}
                  <th style={{ textAlign: "right" }}>${amountFormat(totalAmount)}</th>
                </tr>
                </tfoot>
              </table>
              {/*<br/>*/}
              {totalAmount <= parseFloat(balance) ?
                <button style={{ float: "right" }} className={"btn btn-success float-right"} title={"Click to proceed the order"} onClick={handleProceed}>Proceed</button> :
                <button style={{ float: "right" }} className={"btn btn-danger float-right"} title={"You have insufficient balance to proceed this order."}>Insufficient Balance</button>
              }
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-lg"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Import Bulk Orders
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleImportSubmit}>
                <Label className="form-label">Select A File (.xlsx / .csv / .xls)</Label>
                <input type="file" required={true} className="form-control mb-3" ref={fileInput} onChange={handleChange} accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} />
                <button className="btn btn-primary w-md mt-3" type="submit">Import</button>
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={verificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            retrieveOrdersByFilterType().then(() => {
              setVerificationModal(!verificationModal);
              selectedOrders = [];
            });
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                retrieveOrdersByFilterType().then(() => {
                  setVerificationModal(!verificationModal);
                  selectedOrders = [];
                });
              }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>{selectedOrders.length} orders validation passed.</Alert>
              <hr/>
              <button className={"btn btn-warning"} style={{float:"right"}} disabled={selectedOrders.length>0?false:true} onClick={handleCalculatedPrice}>Proceed To Checkout</button><br/><br/>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={orderErrorModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderErrorModal(!orderErrorModal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderErrorModal(!orderErrorModal);
              }}
            >
              Order Errors
            </ModalHeader>
            <ModalBody>
              <ul>
                {orderErrors!=""?orderErrors.split(",").map((part, id) => {
                  if (part && part != "null") {
                    return (<li className={"text-danger"} key={id}>{part}</li>);
                  }
                }):""}
              </ul>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />
      </div>
    </div>
  );
}
ShopifyOtherOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default ShopifyOtherOrders;