// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"
import loader from "../../../assets/images/loader.gif"
import ReactJson from 'react-json-view'
import get_rates_response from "../../../assets/js/get_rates_response"
import config from "./../../../config"


function AddressValidation(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [address, setAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const [addressResult, setAddressResult] = useState(null);
  const [addressValidationResponse, setAddressValidationResponse] = useState(null);
  const [addressValidationError, setAddressValidationError] = useState(null);

  useEffect(() => {
  }, [])

  const confirmationLevel = [{
    'confirmationLevel': 'CONFIRMATION_LEVEL_UNSPECIFIED',
    'displayName': 'UNSPECIFIED',
    'color': 'gray',
    'backgroundColor': '#efefef'
  },{
    'confirmationLevel': 'CONFIRMED',
    'displayName': 'CONFIRMED',
    'color': 'white',
    'backgroundColor': '#188038'
  },{
    'confirmationLevel': 'UNCONFIRMED_BUT_PLAUSIBLE',
    'displayName': 'PLAUSIBLE',
    'color': 'white',
    'backgroundColor': '#e37400'
  },{
    'confirmationLevel': 'UNCONFIRMED_AND_SUSPICIOUS',
    'displayName': 'SUSPICIOUS',
    'color': 'white',
    'backgroundColor': '#c60000'
  },
  ]

  const handleAddressValidate = async (e) => {
    e.preventDefault();
    try {
      let loading = toast.loading("Validating Address");
      setLoading(true);
      setAddressResult(null);
      setAddressValidationResponse(null);
      setAddressValidationError(null);
      const body = {
        address
      }
      post('/user/verify-address', body, {headers}).then((response) => {
        // console.log("response", response);
        toast.remove(loading);
        setLoading(false);
        if(response.status == 200) {
          setAddressResult(response.data.result);
          setAddressValidationResponse(response.data);
          setAddressValidationError(null);
        }
        else if (response.status == 403) {
          setAddressResult(null);
          setAddressValidationResponse(null);
          setAddressValidationError(response.data);
        }
        else {
          setAddressResult(null);
          setAddressValidationResponse(null);
          setAddressValidationError(null);
          toast.error("Something went wrong");
        }
      }).catch((error) => {
        toast.error("Something went wrong");
      })
    }
    catch (e) {
      toast.error("Something went wrong");
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //meta title
  document.title = `Address Validation | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Address Validation" />
        <Row className="justify-content-center">
          <Col md={12}>
            <Card className="overflow-hidden">
              <CardBody>
                {/*<p style={{fontSize:"11px",marginBottom:"0px"}}>ⓘ Supported Region: US</p>*/}
                <form onSubmit={handleAddressValidate}>
                  <div className={"row"}>
                    <div className={"col-sm-9 col-md-10 mt-2 mb-2"}>
                      <input type={"text"} onChange={(e) => setAddress(e.target.value)} placeholder={"Enter an Address (i.e. 261 N UNIVERSITY DR STE 500, PLANTATION, FL 33324-2009, US)"} required={true} className={"form-control"}/>
                    </div>
                    <div className={"col-sm-3 col-md-2 mt-2 mb-2"}>
                      <button className={"btn btn-primary"} type={"submit"} style={{width:"100%"}}>Validate</button>
                    </div>
                  </div>
                </form>
                {loading?
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img style={{ width: "10%" }} src={loader} />
                  </div>:null}
                {addressResult?
                  <div className={"col-md-12"}
                  >
                    <hr/>
                    <h4>Validated Address</h4>
                    <br/>
                    {addressResult.address.postalAddress?
                      <fieldset>
                        <legend>Postal Address</legend>
                        <div>
                          {addressResult.address.postalAddress.regionCode?<p><b>Region Code:</b> {addressResult.address.postalAddress.regionCode}</p>:null}
                          {addressResult.address.postalAddress.languageCode?<p><b>Language Code:</b> {addressResult.address.postalAddress.languageCode}</p>:null}
                          {addressResult.address.postalAddress.postalCode?<p><b>Postal Code:</b> {addressResult.address.postalAddress.postalCode}</p>:null}
                          {addressResult.address.postalAddress.administrativeArea?<p><b>Administrative Area:</b> {addressResult.address.postalAddress.administrativeArea}</p>:null}
                          {addressResult.address.postalAddress.locality?<p><b>Locality:</b> {addressResult.address.postalAddress.locality}</p>:null}
                          {addressResult.address.postalAddress.addressLines?<p><b>Address lines:</b> {addressResult.address.postalAddress.addressLines.toString()}</p>:null}
                        </div>
                      </fieldset>:null}
                    {addressResult.address.addressComponents.length>0?
                      <fieldset>
                        <legend>Address Components</legend>
                        <div className={"mt-3"} style={{display:"flex",justifyContent:"space-evenly",width:"100%",flexWrap:"wrap"}}>
                          {addressResult.address.addressComponents.map((el, index) => (
                            <div key={index} style={{width:"250px",height:"auto",padding:"10px 20px 15px 20px",margin:"10px 20px"}} className={"border rounded"}>
                              <h6 style={{fontWeight:"600"}}>{capitalizeFirstLetter(el.componentType.toString().replaceAll("_", " "))}</h6>
                              <p>{el.componentName.text}</p>
                              <span className={"px-2 py-1 rounded"} style={{backgroundColor:(confirmationLevel.filter(cl => cl.confirmationLevel == el.confirmationLevel)[0].backgroundColor),color:(confirmationLevel.filter(cl => cl.confirmationLevel == el.confirmationLevel)[0].color),}}>{confirmationLevel.filter(cl => cl.confirmationLevel == el.confirmationLevel)[0].displayName}</span>
                            </div>
                          ))}
                        </div>
                        <br/>
                      </fieldset>:null}
                    {addressValidationResponse?
                      <fieldset>
                        <legend>API Response</legend>
                        <ReactJson src={addressValidationResponse} enableClipboard={true}/>
                      </fieldset>
                      :null}
                  </div>:null
                }
                {
                  addressValidationError?
                    <div className={"col-md-12"}>
                    <hr style={{marginBottom:"25px"}}/>
                      <fieldset>
                        <legend className={"text-danger"}>Address Validation Error</legend>
                        <p><b>Code:</b> {addressValidationError.code}</p>
                        <p><b>Message:</b> {addressValidationError.message}</p>
                        <p><b>Status:</b> {addressValidationError.status}</p>
                      </fieldset>
                    </div>:null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
AddressValidation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default AddressValidation;