import React from "react"
import PlacesAutocomplete from "react-places-autocomplete"
import { Card, CardBody, Input, Label } from "reactstrap"
import { Country, State, City } from "country-state-city"

const AddressSection = ({
  title,
  address,
  handleChange,
  handleCountry,
  handleAddressStreet1,
}) => {
  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">{title}</h4>
        <div className={"row"}>
          <div className={"col-md-12"}>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <div className="form-group">
                  <Label className="form-label">
                    Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    required
                    onChange={handleChange}
                    value={address.name}
                  />
                </div>
              </div>
              <div className={"col-md-4"}>
                <div className="form-group">
                  <Label className="form-label">Company (Optional)</Label>
                  <Input
                    name="company"
                    className="form-control non-arrow-number"
                    placeholder="Enter Company (Optional)"
                    type="text"
                    onChange={handleChange}
                    value={address.company}
                  />
                </div>
              </div>
              <div className={"col-md-4"}>
                <div className="form-group">
                  <Label className="form-label">
                    Phone <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="phone"
                    className="form-control non-arrow-number"
                    placeholder="Enter Phone"
                    type="text"
                    onWheel={e => e.target.blur()}
                    onChange={handleChange}
                    value={address.phone}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-12 mt-2"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <PlacesAutocomplete
                  value={address.street1}
                  onChange={value =>
                    handleChange({ target: { name: "street1", value } })
                  }
                  onSelect={handleAddressStreet1}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative" }}>
                      <div className="form-group">
                        <Label className="form-label">
                          Address Street 1{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="street1"
                          className="form-control"
                          {...getInputProps({
                            placeholder: "Enter Street 1",
                          })}
                          type="text"
                          required
                        />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "65px",
                          left: "0px",
                          backgroundColor: "white",
                          zIndex: "99",
                          width: "100%",
                        }}
                      >
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map(suggestion => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#ff6600"
                              : "#f1f1f1",
                            padding: "5px 10px",
                            border: "1px solid #efefef",
                            cursor: "pointer",
                          }
                          return (
                            <div
                              key={suggestion.id}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className={"col-md-6"}>
                <div className="form-group">
                  <Label className="form-label">
                    Address Street 2 (Optional)
                  </Label>
                  <Input
                    name="street2"
                    className="form-control"
                    placeholder="Enter Street 2 (Optional)"
                    type="text"
                    onChange={handleChange}
                    value={address.street2}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-12 mt-2"}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <div className="form-group">
                  <Label className="form-label">
                    Country <span style={{ color: "red" }}>*</span>
                  </Label>
                  <select
                    name="country"
                    className="form-select"
                    onChange={handleCountry}
                    required
                    value={address.country}
                  >
                    <option disabled value={""}>
                      Select a country
                    </option>
                    {Country.getAllCountries().map((part, id) => (
                      <option key={id} value={part.isoCode}>
                        {part.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={"col-md-3"}>
                <div className="form-group">
                  <Label className="form-label">
                    City <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="city"
                    className="form-control"
                    placeholder="Enter City"
                    type="text"
                    onChange={handleChange}
                    value={address.city}
                    required
                  />
                </div>
              </div>
              <div className={"col-md-3"}>
                <div className="form-group">
                  <Label className="form-label">
                    Zip <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="zip"
                    className="form-control non-arrow-number"
                    placeholder="Enter Zip"
                    type="text"
                    onChange={handleChange}
                    value={address.zip}
                    required
                  />
                </div>
              </div>
              <div className={"col-md-3"}>
                <div className="form-group">
                  <Label className="form-label">
                    State <span style={{ color: "red" }}>*</span>
                  </Label>
                  <select
                    name="state"
                    className="form-select"
                    onChange={handleChange}
                    value={address.state}
                    required={
                      State.getAllStates().filter(
                        el => el.countryCode === address.country
                      ).length > 0
                    }
                  >
                    <option disabled value={""}>
                      Select a state
                    </option>
                    {State.getAllStates()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter(el => el.countryCode === address.country)
                      .map((part, id) => (
                        <option key={id} value={part.isoCode}>
                          {part.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
export default AddressSection
