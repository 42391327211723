import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import namelogo from "assets/images/nullship-logo.png"
// import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png"
import img12 from "assets/images/img12.png"
import anim_logo from "../../assets/images/ainimationlogo.gif"
// import header background img
import bgimg from "assets/images/bgimg.png"
import LoginFooter from "components/LoginFooter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/nullship-icon.png"
import { post } from "../../helpers/api_helper"
import { Toaster } from "react-hot-toast"
import { toast } from "react-hot-toast"
import config from "../../config"
import AuthImg from "../../assets/images/AuthImg.svg"
import AuthHeader from "components/Common/AuthHeader"
import EmailVer from "../../assets/images/EmailVer.svg"
import AuthFooter from "components/Common/AuthFooter"
import OtpInput from "react-otp-input"
const Otp = props => {
  const inputRef = useRef(null)
  //meta title
  document.title = `OTP | ${config.APP_DETAILS.APP_NAME}`
  const dispatch = useDispatch()
  const {
    match: { params },
  } = props

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")
  const [token, setToken] = useState(params.token)
  const [code, setCode] = useState("")
  const [buttonDisable, setButtonDisable] = useState(true)
  const [resendEnable, setResendEnable] = useState(false)
  const [seconds, setSeconds] = useState(60)

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${params.token}`,
  }

  useEffect(() => {
    //   let body = {
    //     token: params.token
    //   }
    //  post('/user/verify-otp-expiry', body, {headers}).then((response) => {
    //    if(response.status === 500) {
    //      toast.error("Otp expired");
    //       props.history.push("/");
    //    }
    //    else {
    //      inputRef.current.focus()
    //    }
    //  })
  }, [])

  useEffect(() => {
    console.log("gagaga")
    const interval = setInterval(() => {
      if (seconds == 0) {
        setResendEnable(true)
      }

      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  const handleOtp = async () => {
    try {
      if (code.length === 6) {
        const body = {
          code: code,
          token: token,
        }
        await post("/user/verify-otp", body, { headers }).then(response => {
          // console.log("response", response.message);
          if (response.status === 200) {
            localStorage.removeItem("authUser")
            localStorage.removeItem("logout-event")
            localStorage.removeItem("token")
            localStorage.setItem("authUser", JSON.stringify(response.user))
            // localStorage.setItem("gapi", response.google_api);
            localStorage.setItem("token", response.token)
            localStorage.setItem("store", "0")
            localStorage.setItem("skt", "false")
            if (response.user.role_id === 2) {
              localStorage.setItem("balance", response.user.balance)
              //Store 0 = Manual Store
              localStorage.setItem("store", 0)
            } else {
              localStorage.setItem("theme", "light")
            }
            props.history.push("/dashboard")
          } else {
            toast.error(response.message)
          }
        })
      } else {
        toast.error("Otp code must contain 6 numbers")
      }
    } catch (e) {
      // console.log(e);
    }
  }

  const handleCode = e => {
    setCode(e.target.value)
    if (e.target.value.length === 6) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }

  const handleResend = () => {
    if (resendEnable) {
      let body = {
        token: params.token,
      }
      let loading = toast.loading("Resending OTP...")
      post("/user/resend-otp", body, { headers }).then(response => {
        toast.remove(loading)
        if (response.status === 200) {
          toast.success(response.message)
          //  props.history.push("/");
        } else {
          toast.error(response.message)
        }
      })
    }
  }
  useEffect(() => {
    if (code.length === 6) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [code])
  return (
    <React.Fragment>
      <Toaster position="top-center" reverseOrder={false} />
      {/* <div className=" home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5"
                                                // height={"120px"}
          width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <div>
        <div
          className="col-xl-11 "
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: "10px",
              margin: 0,
            }}
          >
            <CardBody
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Row>
                <Col xl={6} className="segap p-0 ps-3 pe-3 ps-md-5 pe-md-5">
                  <div className="account-pages  pt-sm-3">
                    <Container></Container>
                    <Row className="justify-content-center">
                      <Col
                        md={12}
                        lg={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100vh",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="overflow-hidden d-flex flex-column align-items-center justify-content-center">
                          <AuthHeader
                            label="Changed your mind?"
                            link="/login"
                            btnLabel="Go back"
                          />
                          <div className="d-flex flex-column align-items-center justify-content-center mt-3 width80">
                            <img src={EmailVer} alt="Account" />
                            <h3 className="mt-2">Enter Verification Code</h3>
                            <p className="mb-3">We have sent an email to</p>
                            <div
                              style={{
                                border: "1px solid #e1e4ea",
                                width: "100%",
                                height: "1px",
                              }}
                            ></div>
                          </div>
                          <CardBody className="pt-0">
                            {/* <div>
                    <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                    </div>
                  </div> */}
                            <div className="p-2">
                              <Form
                                className="form-horizontal"
                                onSubmit={() => {
                                  document.getElementById("verify").click()
                                }}
                              >
                                <div className="mb-3 mt-3">
                                  {/* <Label className="form-label text-dark">
                                    Otp Code
                                  </Label> */}
                                  <OtpInput
                                    value={code}
                                    onChange={setCode}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    ref={inputRef}
                                    renderInput={props => (
                                      <input
                                        {...props}
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid #e1e4ea",
                                          height: "40px",
                                          width: "50px",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                  {/* <Input
                                    name="number"
                                    className="form-control text-center"
                                    style={{
                                      fontSize: "20px",
                                      letterSpacing: "20px",
                                    }}
                                    placeholder="------"
                                    type="code"
                                    minLength={6}
                                    maxLength={6}
                                    ref={inputRef}
                                    autoFocus
                                    onChange={handleCode}
                                  /> */}
                                </div>
                                <Row className="m-auto">
                                  <Col className="text-center mt-3">
                                    <button
                                      className="btn text-white m-auto btn-block ntb w-100"
                                      type="button"
                                      id="verify"
                                      disabled={buttonDisable}
                                      onClick={handleOtp}
                                      style={{
                                        backgroundColor: "#835af4",
                                        borderRadius: "12px",
                                      }}
                                      onMouseEnter={e =>
                                        (e.target.style.backgroundColor =
                                          "#6999e0")
                                      }
                                      onMouseLeave={e =>
                                        (e.target.style.backgroundColor =
                                          "#835af4")
                                      }
                                    >
                                      Verify
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                              <p className="mt-4 text-center">
                                Experiencing issues receiving the code?
                              </p>
                              <div className="mt-2 text-center">
                                <a
                                  // className={
                                  //   resendEnable
                                  //     ? "text-danger"
                                  //     : "text-secondary"
                                  // }
                                  onClick={handleResend}
                                  style={{
                                    fontWeight: "bold",
                                    borderBottom: "2px solid",
                                  }}
                                >
                                  {" "}
                                  Resend Code{" "}
                                  {!resendEnable
                                    ? " (" +
                                      seconds +
                                      (parseInt(seconds) < 10
                                        ? " second)"
                                        : " seconds)")
                                    : ""}
                                </a>
                              </div>
                            </div>
                          </CardBody>
                        </div>
                        <AuthFooter />
                      </Col>
                      {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                    </Row>
                  </div>
                </Col>
                <Col
                  xl={6}
                  style={{
                    background: "#fcf7fa",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={AuthImg}
                      alt="AuthImg"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

Otp.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Otp)
