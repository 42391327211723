import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { LoginCurve, User } from "iconsax-react"
import config from "./../../../config";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [profile_picture, setprofile_picture] = useState(user1);

  const [username, setusername] = useState("User");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.first_name);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  // useEffect(() => {
  //   const pic = localStorage.getItem("profile_picture");
  //   if(pic){
  //     setprofile_picture(`${config.site.BASE_URL_FOR_IMAGES}profile-picture/${localStorage.getItem("profile_picture")}`)
  //   }else{
  //     setprofile_picture(user1)
  //   }
  // }, [localStorage.getItem("profile_picture")])
  useEffect(() => {
    const updateProfilePicture = () => {
      const pic = localStorage.getItem("profile_picture");
      if (pic) {
        setprofile_picture(`${config.site.BASE_URL_FOR_IMAGES}profile-picture/${pic}`);
      } else {
        setprofile_picture(user1);
      }
    };

    // Initial check
    updateProfilePicture();

    // Event listener for storage changes
    window.addEventListener('storage', updateProfilePicture);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('storage', updateProfilePicture);
    };
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            // src={user1}
            src={profile_picture}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{JSON.parse(localStorage.getItem("authUser")).first_name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile"><DropdownItem>
            {" "}
            {/*<i className="bx bx-user font-size-16 align-middle me-1" />*/}
            <i style={{ marginRight: "10px" }}><User size={12} /></i>
            {props.t("Profile")}{" "}
          </DropdownItem></Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i style={{ marginRight: "10px" }}><LoginCurve color={"#FF6969"} size={13} /></i>
            <span style={{ color: "#FF6969" }}>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
