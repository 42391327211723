// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"
import Select from "react-select"
import config from "./../../../config"

let selectedUser = "";
let selectedUserId = "";
const displayNames = [
  {
    "cron_name": "sellercloud_import_order",
    "display_name": "SellerCloud Order Sync Scheduler"
  },
  {
    "cron_name": "sellercloud_disable_shipped_void_cancelled_order_cron",
    "display_name": "SellerCloud Order Status Update Scheduler"
  },
  {
    "cron_name": "sellercloud_picklist_update_cron",
    "display_name": "SellerCloud Picklist Update Scheduler"
  },
  {
    "cron_name": "shipstation_import_order",
    "display_name": "ShipStation Order Sync Scheduler"
  },
  {
    "cron_name": "shipstation_disable_shipped_on_hold_cancelled_order_cron",
    "display_name": "ShipStation Order Status Update Scheduler"
  },
]
function RunningCronReport() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [users, setUsers] = useState([]);
  const [pending, setPending] = useState(false);

  const retrieveUsers = async () => {
    try {
      get('/admin/getActiveUsers', {headers}).then((response) => {
        if(response.data.length > 0) {
          response.data.sort((a, b) => {
            const nameA = a.email.toLowerCase();
            const nameB = b.email.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        let users = [{value: "all", label: "All Users"}];
        for (let i=0;i<response.data.length;i++) {
          users.push({
            label: response.data[i].email,
            value: response.data[i].id
          })
        }

        setUsers(users);
        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveRunningCronReport= async () => {
    try {
      setPending(true);
      get('/admin/running-cron-report', {headers}).then((response) => {
        for (let i=0;i<response.data.length;i++) {
          if(response.data[i].cron_name) {
            response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length>0?displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name:response.data[i].cron_name;
          }
        }
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveCronReport= async (e) => {
    try {
      if(e.value == "all") {
        await retrieveAllCronReport();
      }
      else {
        setPending(true);
        selectedUser = e.label;
        selectedUserId = e.value;
        get('/admin/running-cron-report/'+e.value, {headers}).then((response) => {
          for (let i=0;i<response.data.length;i++) {
            if(response.data[i].cron_name) {
              response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length>0?displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name:response.data[i].cron_name;
            }
          }
          setData(response.data);
          let data = response.data;
          // console.log("table data" , { columns, data });
          setTableData({ columns, data });
          setPending(false);
        })
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const reloadCronReport = async () => {
    try {
      retrieveRunningCronReport();
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'User Email',
      // selector: 'email',
      width: '260px',
      selector: (row) => (
        <span>
          {row.email?row.email:"-"}
        </span>
      )
    },
    {
      name: 'Store ID',
      // selector: 'store_id',
      sortable: true,
      width: '100px',
      selector: (row) => (
        <span>
          {row.store_id && row.store_id != "999999999" ?row.store_id:"-"}
        </span>
      )
    },
    {
      name: 'Warehouse ID - Store Name',
      // selector: 'store_name',
      sortable: true,
      width: '250px',
      selector: (row) => (
        <span>
          {row.warehouse_id} - {row.store_name}
        </span>
      )
    },
    {
      name: 'Store Type',
      selector: row => row.store_type,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Cron Name',
      // selector: 'cron_name',
      width: '300px',
      selector: (row) => (
        <span title={row.cron_name}>
          {row.cron_name}
        </span>
      )
    },
    {
      name: 'Upcoming Fetching Date',
      // selector: 'start_date',
      sortable: true,
      width:"210px",
      selector: (row) => (
        row.cron_name == "sellercloud_picklist_update_cron"?"-":
          <span>
          {moment(new Date(row.start_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Started At',
      // selector: 'created_at',
      sortable: true,
      width:"210px",
      selector: (row) => (
        <span>
          {moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Ended At',
      // selector: 'end_date',
      sortable: true,
      width:"210px",
      selector: (row) => (
        row.end_date?
          <span>
          {moment(new Date(row.end_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>:"-"
      )
    },
    {
      name: 'Message',
      // selector: 'message',
      sortable: true,
      width:"210px",
      selector: (row) => (<span>{row.message?
        (row.message.toString().includes("displayMessage")?JSON.parse(row.message).displayMessage:
          row.message.toString().split("-->").map((el,index) => (<p key={index}>{el.length<180?el:el.substr(0,180)}</p>)))
        :"-"}</span>)
    },
    {
      name: 'Status',
      // selector: 'running_status',
      width: "120px",
      sortable: true,
      selector: (row) => (
        row.running_status == 1?
          <span className={"badge badge-warning"}>Running</span> :
          (row.running_status == 2?
              <span className={"badge badge-danger"}>Aborted</span> :
              (row.running_status == 4 ?
                  <span className={"badge badge-danger"}>Error</span> :
                  <span className={"badge badge-success"}>Completed</span>
              )
          )
      )
    },
    {
      name: 'Action',
      // selector: 'running_status',
      width: "180px",
      selector: (row) => (
        row.running_status == 1?
          <button className={"btn btn-danger"} onClick={() => handleForceStop(row.id)}>Force Stop</button>
          :"-"
      )
    }
  ];

  const handleForceStop = (id) => {
    if(confirm("Are you sure! you want to force stop the scheduler?")) {
      const body = {
        id: id
      }
      post('/admin/stop-cron-job', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          toast.success(response.message);
          await reloadCronReport();
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  useEffect(() => {
    retrieveUsers();
    retrieveRunningCronReport();
  }, []);

  //meta title
  document.title = `Running Schedulers | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Report" breadcrumbItem="Running Schedulers" />
        {/* <Table columns={columns} data={data} /> */}
        {/*<Card>*/}
        {/*  <CardBody style={{display:"flex"}}>*/}
        {/*    <div className={"col-md-4"}>*/}
        {/*      <label>Select User</label>*/}
        {/*      <Select*/}
        {/*        options={users}*/}
        {/*        onChange={async (selectedGroup) => {*/}
        {/*          // console.log("selectedGroup",selectedGroup);*/}
        {/*          await retrieveCronReport(selectedGroup)*/}
        {/*        }}*/}
        {/*        className="select2-selection"*/}
        {/*      />*/}
        {/*      /!*<select className={"form-select"} onChange={retrieveCronReport}>*!/*/}
        {/*      /!*  <option disabled={true} selected={true}>{users.length==0?"Loading users...":"Select a User"}</option>*!/*/}
        {/*      /!*  {users.length>0?*!/*/}
        {/*      /!*    users.map((part, id) => (*!/*/}
        {/*      /!*      <option value={part.id} key={id}>{part.email}</option>*!/*/}
        {/*      /!*    ))*!/*/}
        {/*      /!*    :""}*!/*/}
        {/*      /!*</select>*!/*/}
        {/*    </div>*/}
        {/*  </CardBody>*/}
        {/*</Card>*/}
        <Card>
          <CardBody>
            <button className={"btn btn-light"} style={{marginBottom:"-44px"}} onClick={reloadCronReport}>Reload</button>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Running Schedulers...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
RunningCronReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default RunningCronReport;