// src/components/filter.
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useDispatch } from "react-redux"
import axios from "axios"
import JSZip from "jszip"
import { PDFDocument } from "pdf-lib"
import { saveAs } from "file-saver"
import {
  Card,
  CardBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import customStyles from "../../../assets/css/customTableStyle"
import CustomFilters from "../../../components/CustomFilters"
import print from "print-js"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import CustomMultiselectFilters from "../../../components/CustomMultiselectFilters"
import {
  MergedPackingSlip,
  MergedPackingSlipTemplate2,
  PackingSlip,
  PackingSlipTemplate2,
} from "./templates"
let selectedOrders = []
let dataCompleted = false
let page = 1
// let filteredData = [];
// let tableData = {};
let clickedSideBarJson = []
let sideBarData = []
let pdfBlobs = []
// let mergedPackingSlip = [];
let filteredAllData = []
let picklistFilter = []
let hideShowPicklists = true
function OrdersCompleted(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0)

  const [mergedPackingSlip, setMergedPackingSlip] = useState([])
  const [mergedPackingSlipTemplete2, setMergedPackingSlipTemplete2] = useState(
    []
  )

  // const [filteredData, setFilteredData] = useState([])
  // const [tableData, setTableData] = useState([])

  const [allData, setAllData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState(null)

  const [pending, setPending] = useState(true)
  const [hidePrinted, setHidePrinted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [viewModal, setViewModal] = useState(false)

  const [rowId, setRowId] = useState()

  const [scrolling, setScrolling] = useState(false)
  const [loadingOrders, setLoadingOrders] = useState(false)

  const [filterType, setFilterType] = useState("USPS")

  const [carriers, setCarriers] = useState([])
  const [stores, setStores] = useState([])

  const [invoiceData, setInvoiceData] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)

  const [fetchLastMonth, setFetchLastMonth] = useState(true)

  //Sort states
  const [nameSort, setNameSort] = useState("")
  const [weightSort, setWeightSort] = useState("")
  const [quantitySort, setQuantitySort] = useState("")
  const [filterShipment, setFilterShipment] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [tagFilter, setTagFilter] = useState("")

  const [tags, setTags] = useState([])
  const [tagsForFilter, setTagsForFilter] = useState([])
  const [picklists, setPicklists] = useState([])

  const [orderItemInfoModal, setOrderItemInfoModal] = useState(false)
  const [orderItemType, setOrderItemType] = useState("")
  const [orderItemData, setOrderItemData] = useState("")

  const [duplicateOrderModal, setDuplicateOrderModal] = useState(false)
  const [duplicateOrderId, setDuplicateOrderId] = useState("")
  const [duplicateOrderUniqueId, setDuplicateOrderUniqueId] = useState("")
  const [duplicateOrderCount, setDuplicateOrderCount] = useState("")

  const columns = [
    {
      name: (
        <input
          type={"checkbox"}
          title={"Select All"}
          className={"select-all-checkbox"}
          id={"select-all-checkbox"}
          onChange={checkAll}
        />
      ),
      // selector: ["pdf", "is_printed"],
      // sortable: true,
      width: "60px",
      selector: row => (
        <>
          {row.pdf.toString().includes(".zpl") ? (
            <span
              style={{
                position: "absolute",
                fontSize: "8px",
                top: "2px",
                fontWeight: "500",
              }}
            >
              ZPL
            </span>
          ) : null}
          <input
            type={"checkbox"}
            className={"download-checkbox"}
            id={`order-${row.pdf}`}
            value={row.pdf}
            name="order_id"
            onChange={handleOrderCheck}
          />
          {row.is_printed == 1 ? (
            <span
              style={{
                position: "absolute",
                fontSize: "8px",
                bottom: "2px",
                left: "7px",
                fontWeight: "500",
                color: "#02ad30",
              }}
            >
              PRINTED
            </span>
          ) : null}
        </>
      ),
    },
    {
      name: "Item Names",
      // sortable: true,
      width: "200px",
      reorder: true,
      selector: row => {
        if (
          row.custom_items_data &&
          JSON.parse(
            row.store_type == "ebay"
              ? JSON.parse(row.custom_items_data)
              : row.custom_items_data
          ).length > 0
        ) {
          if (row.store_type == null) {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.description + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shopify") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.name + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "ebay") {
            let data = JSON.parse(JSON.parse(row.custom_items_data))
            let finalData = data.map(
              (el, id) => el.title + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "walmart") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.item.productName + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "zenventory") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.itemmaster.description + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "sellercloud") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.DisplayName + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.name + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.sellable.product_title + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          }
          else if (row.store_type == "shiphero") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el?.sellable?.product_title + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {/* {finalData} */}
                {"--"}
              </span>
            )
          }
          else if (row.store_type == "orderdesk") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map((el, id) => el?.name + (id != data?.length - 1 ? ", " : "") )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Item Names" +
                    (row.id ? " of " + row.id : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {data.map((el, id) => el?.name + (id != data?.length - 1 ? ", " : "") )}
              </span>
            )
          }
        } else {
          return "-"
        }
      },
    },
    {
      name: "Product IDs",
      // sortable: true,
      width: "200px",
      reorder: true,
      selector: row => {
        if (
          row.custom_items_data &&
          JSON.parse(
            row.store_type == "ebay"
              ? JSON.parse(row.custom_items_data)
              : row.custom_items_data
          ).length > 0
        ) {
          if (row.store_type == null) {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.product_identifier + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shopify") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "ebay") {
            let data = JSON.parse(JSON.parse(row.custom_items_data))
            let finalData = data.map(
              (el, id) => el.lineItemId + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "walmart") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.item.sku + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "zenventory") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.customerorderitemid + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "sellercloud") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.ProductID + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.orderItemId + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.sellable.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          }
          else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.sellable.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                      (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {/* {finalData} */}
                {"--"}

              </span>
            )
          }
          else if (row.store_type == "orderdesk") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map((el, id) => el.id + (id != data.length - 1 ? ", " : ""))
            return (
              <span onClick={() => {setOrderItemType("Product IDs" +(row.id ? " of " + row.id : ""));setOrderItemData(finalData);setOrderItemInfoModal(true);}} style={{ cursor: "pointer" }}>
                {data?.map((el, id) => el.id )}
                {/* {"--"} */}
              </span>
            )
          }
        } else {
          return "-"
        }
      },
    },
    {
      name: "Product SKU",
      sortable: true,
      width: "200px",
      reorder: true,
      selector: row => {
        if (
          row.custom_items_data &&
          JSON.parse(
            row.store_type == "ebay"
              ? JSON.parse(row.custom_items_data)
              : row.custom_items_data
          ).length > 0
        ) {
          // if(row.store_type == null) {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.product_identifier + (id != (data.length - 1) ? ", ": ""));
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "shopify") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.id + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "ebay") {
          //   let data = JSON.parse(JSON.parse(row.custom_items_data));
          //   let finalData = data.map((el, id) => el.lineItemId + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "walmart") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.item.sku + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "zenventory") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.customerorderitemid + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "sellercloud") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.ProductID + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else
          if (row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              console.log("data[i].sku", data[i].sku)
              if (data[i].sku) {
                skus.push(data[i].sku)
              }
            }
            console.log("skus", skus)
            return skus.length > 0 ? skus.toString() : "-"
          } else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              // console.log("data[i].sku",data[i].sku)
              if (data[i].sellable?.sku_code) {
                skus.push(data[i].sellable.sku_code)
              }
            }
            // console.log("skus",skus);
            return skus.length > 0 ? skus.toString() : "-"
          }  
          else if (row.store_type == "shiphero") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              // console.log("data[i].sku",data[i].sku)
              if (data[i].sku) {
                skus.push(data[i].sku)
              }
            }
            // console.log("skus",skus);
            return skus.length > 0 ? skus.toString() : "-"
          }
           else {
            return "-"
          }
        } else {
          return "-"
        }
      },
    },
    // {
    //   name: 'Shipment ID',
    //   selector: 'wallet_batch_id',
    //   sortable: true,
    //   width: "330px",
    //   cell: row => row.wallet_batch_id !== null ? row.wallet_batch_id : "-"
    // },
    {
      name: "Service Name",
      selector: row => row.service_name,
      //  sortable: true,
      width: "170px",
      reorder: true,
    },
    {
      name: "From Name",
      // selector: 'from_name',
      sortable: true,
      minWidth: "170px",
      selector: row => (row.from_name ? row.from_name : "-"),
    },
    {
      name: "To Name",
      // selector: "to_name",
      sortable: true,
      width: "170px",
      selector: row => (row.to_name ? row.to_name : "-"),
      reorder: true,
    },
    // {
    //   name: 'Tag',
    //   selector: ['tag','tag_color'],
    //   //  sortable: true,
    //   minWidth: "90px",
    //   maxWidth: "90px",
    //   cell: row => row.tag ?<span title={row.tag} style={{backgroundColor: row.tag_color?row.tag_color:"#e3e3e3", color: (getBrightness(row.tag_color?row.tag_color:"#e3e3e3") > 128 ? 'black' : 'white')}} className={"badge badge-tag"}>{row.tag}</span> : "-"
    // },
    {
      name: "Tags",
      // selector: "tags",
      //  sortable: true,
      minWidth: "90px",
      maxWidth: "90px",
      reorder: true,
      selector: row =>
        row.tags.length > 0
          ? row.tags.map((el, index) => (
              <span
                key={index}
                title={el.tag}
                style={{
                  backgroundColor: el.color,
                  width: "8px",
                  height: "20px",
                  display: "block",
                  marginLeft: index > 0 ? "5px" : "0px",
                  borderRadius: "5px",
                }}
              ></span>
            ))
          : "-",
    },
    {
      name: "Order #",
      // selector: "order_number",
      sortable: true,
      width: "140px",
      conditionalCellStyles: [
        {
          when: row => row.order_number,
          style: {
            color: "#e0530cff",
            fontWeight: "500",
            cursor: "pointer",
          },
        },
      ],
      button: true,
      reorder: true,
      selector: row => {
        return row.order_number
      },
      cell: row => {
        const storeOrderJson = row.store_order_json? JSON.parse(row.store_order_json): null
        return row.order_number ? (
          <span onClick={() => {setRowId(row.order_id);setViewModal(true)}}>
            { row.store_type == "orderdesk"  ? storeOrderJson.source_id : row.order_number }
          </span>
        ) : (
          "-"
        )
      },
    },
    {
      name: "Package #",
      // selector: "package_number",
      sortable: true,
      width: "120px",
      // conditionalCellStyles: [
      //   {
      //     when: row => row.package_number,
      //     style: {
      //       color: "#FF6600", fontWeight: "500", cursor: "pointer"
      //     }
      //   }],
      // button: true,
      reorder: true,
      selector: row =>
        row.package_number ? (
          <span>
            {row.package_number +
              (row.package_index ? " (" + row.package_index + ")" : "")}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Picklist",
      // selector: "picklists",
      sortable: true,
      reorder: true,
      width: "130px",
      button: true,
      selector: row =>
        row.store_type == "sellercloud" ? (
          row.picklists ? (
            <div
              style={{
                width: "170px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {row.picklists.split(",").map((el, index) => (
                <span
                  key={index}
                  className={"badge border border-dark text-dark mt-1"}
                  style={{ marginLeft: "5px" }}
                >
                  {el}
                </span>
              ))}
            </div>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
      // omit: hideShowPicklists
    },
    {
      name: "Store Order Date",
      // selector: "store_order_json",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: row => {
        if (row.store_order_json) {
          let order = JSON.parse(row.store_order_json)
          if (row.store_type == "shopify") {
            return (
              <span>{moment(order.created_at).format("DD MMMM YYYY")}</span>
            )
          } else if (row.store_type == "ebay") {
            return (
              <span>{moment(order.updated_at).format("DD MMMM YYYY")}</span>
            )
          } else if (row.store_type == "walmart") {
            return <span>{moment(order.orderDate).format("DD MMMM YYYY")}</span>
          } else if (row.store_type == "zenventory") {
            return (
              <span>{moment(order.ordereddate).format("DD MMMM YYYY")}</span>
            )
          } else if (row.store_type == "sellercloud") {
            return (
              <span>
                {moment(order.TimeOfOrder).format("DD MMMM YYYY hh:mm A")}
              </span>
            )
          } else if (row.store_type == "shipstation") {
            return (
              <span>
                {moment(order.createDate).format("DD MMMM YYYY hh:mm A")}
              </span>
            )
          } else if (row.store_type == "veeqo") {
            return (
              <span>
                {moment(order.created_at).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          }
          else if (row.store_type == "shiphero") {
            return (
              <span>
                {moment(order.order_history[0].created_at).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          }
          else if (row.store_type == "orderdesk") {
            return (
              <span>{moment(order.created_at).format("MMM DD, YYYY hh:mm A")}</span>
            )
          }
        } else {
          return "-"
        }
      },
      omit: localStorage.getItem("store") == 0,
    },
    {
      name: "Order Date",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: row => row.order_date,
      format: row =>moment(row.order_date).tz(moment.tz.guess()).format("DD MMMM YYYY hh:mm A"),
    },

    {
      name: "Completed At",
      // selector: "created_at",
      sortable: true,
      width: "180px",
      reorder: true,
      selector: row => row.created_at,
      format: row =>
        moment(row.created_at)
          .tz(moment.tz.guess())
          .format("DD MMMM YYYY hh:mm A"),
    },
    {
      name: "Tracking Number",
      // selector: 'after_ship_id',
      // selector: ["tracking_code"],
      // sortable: false,
      reorder: true,
      width: "260px",
      selector: row =>
        row.tracking_code ? (
          <>
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href={`/track/${row.tracking_code}`}
            >
              {row.tracking_code}
            </a>
            <span
              style={{ color: "#e3e3e3", cursor: "pointer", marginLeft: "5px" }}
              className={"bx bx-clipboard"}
              title={"Copy Tracking Number"}
              onClick={() => {
                navigator.clipboard.writeText(row.tracking_code)
                toast.success("Copied!")
              }}
            ></span>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   name: 'Cost',
    //   // selector: 'after_ship_id',
    //   selector: ['discounted_price'],
    //   sortable: false,
    //   width: "80px",
    //   cell: row => row.discounted_price ? "$" + amountFormat(row.discounted_price) : "-"
    // },
    // {
    //   name: 'Updated At',
    //   selector: ['tracking_updated_at'],
    //   sortable: false,
    //   width: "130px",
    //   cell: row => row.tracking_updated_at ? moment(row.tracking_updated_at).format("DD-MM-YYYY hh:mm A") : "-"
    // },
    {
      name: "Tracking Status",
      // selector: ["tracking_status"],
      // sortable: false,
      width: "130px",
      button: true,
      reorder: true,
      selector: row =>
        row.tracking_status ? (
          <span className={"badge badge-success"}>{row.tracking_status}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      // selector: ["id", "order_id", "tracking_status", "carrier_name"],
      //  sortable: true,
      width: "180px",
      button: true,
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      selector: row => (
        <>
          <button
            title={"View Order Details"}
            className={"btn btn-success btn-sm"}
            onClick={() => {
              setRowId(row.order_id)
              setViewModal(true)
            }}
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
          <button
            title={"Export Order Invoice"}
            className={"btn btn-warning btn-sm"}
            onClick={() => handleInvoice(row.id)}
            style={{ marginLeft: "10px" }}
          >
            <i className={"bx bx-file"}></i>
          </button>
          <button
            title={"Cancel Order"}
            className={"btn btn-danger btn-sm"}
            onClick={() => handleCancel(row.id)}
            style={{ marginLeft: "10px" }}
          >
            <i className={"bx bx-x"}></i>
          </button>
          {localStorage.getItem("store") == "0" ? (
            <button
              title={"Duplicate Order"}
              className={"btn btn-info btn-sm"}
              onClick={() => handleDuplicate(row.order_id, row.order_unique_id)}
              style={{ marginLeft: "10px" }}
            >
              <i className={"bx bx-duplicate"}></i>
            </button>
          ) : null}
        </>
      ),
    },
    {
      name: "",
      selector: row => row.order_unique_id,
      omit: true,
    },
    {
      name: "",
      selector: row => row.wallet_batch_id,
      omit: true,
    },
    {
      name: "",
      selector: row => row.reference_number,
      omit: true,
    },
    {
      name: "",
      selector: row => row.custom_reference_1,
      omit: true,
    },
    {
      name: "",
      selector: row => row.custom_reference_2,
      omit: true,
    },
  ]

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  // Function to update the window width state
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  // Add an event listener to the window when the component mounts
  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowWidth)
    }
  }, [])

  function printZpl(zpl) {
    let loading = toast.loading("Printing...")
    const printWindow = window.open("", "", "width=600,height=800")
    printWindow.document.open()
    printWindow.document.write(`<pre>${zpl}</pre>`)
    printWindow.document.close()
    printWindow.print()
    printWindow.close()
    toast.remove(loading)
  }

  // Calculate brightness
  const getBrightness = color => {
    const hexColor = color.replace("#", "")
    const r = parseInt(hexColor.slice(0, 2), 16)
    const g = parseInt(hexColor.slice(2, 4), 16)
    const b = parseInt(hexColor.slice(4, 6), 16)
    // console.log("ddd", 0.299 * r + 0.587 * g + 0.114 * b)
    return 0.299 * r + 0.587 * g + 0.114 * b
  }

  const handleDuplicate = async (order_id, order_unique_id) => {
    try {
      setDuplicateOrderCount(1)
      setDuplicateOrderId(order_id)
      setDuplicateOrderUniqueId(order_unique_id)
      setDuplicateOrderModal(true)
    } catch (e) {}
  }

  const handleSubmitDuplicateOrder = async e => {
    e.preventDefault()
    try {
      if (confirm("Are you sure! you want to duplicate the order?")) {
        let loading = toast.loading("Duplicating Order...")

        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).id,
          order_id: duplicateOrderId,
          order_count: duplicateOrderCount,
          type: filterType,
        }

        post("/user/duplicate-order", body, { headers }).then(
          async response => {
            toast.remove(loading)
            if (response.status == 200) {
              toast.success(response.message)
              setDuplicateOrderModal(false)
            } else {
              toast.error(response.message)
            }
          }
        )
      }
    } catch (e) {
      console.log("duplicate order error", e)
    }
  }

  const handleCancel = async completed_order_id => {
    try {
      if (confirm("Are you sure! you want to cancel the order?")) {
        setPending(true)
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        uncheckAll()
        // page = 1;
        // filteredData = [];
        // let data = [];
        // tableData = {columns, data};

        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).id,
          completed_order_id: completed_order_id,
        }

        // console.log("handleCancel", body);

        post("/user/cancel-completed-order", body, { headers }).then(
          response => {
            if (response.status == 200) {
              toast.success(response.message)
              retrieveCount(localStorage.getItem("order-filter"))
              retrieveOrdersCompleted(localStorage.getItem("order-filter"))
            } else {
              toast.error(response.message)
            }
          }
        )
      }
    } catch (e) {}
  }

  const handleBulkCancel = async () => {
    try {
      // console.log("selectedOrders",selectedOrders);
      if (selectedOrders.length > 0) {
        if (confirm("Are you sure! you want to cancel the selected orders?")) {
          setPending(true)
          // page = 1;
          // filteredData = [];
          // let data = [];
          // tableData = {columns, data};

          let body = {
            id: JSON.parse(localStorage.getItem("authUser")).id,
            completed_order_ids: selectedOrders.toString(),
          }

          // console.log("handleBulkCancel", body);

          post("/user/cancel-bulk-completed-order", body, { headers }).then(
            response => {
              if (response.status == 200) {
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                uncheckAll()
                toast.success(response.message)
                retrieveCount(localStorage.getItem("order-filter"))
                retrieveOrdersCompleted(localStorage.getItem("order-filter"))
              } else {
                toast.error(response.message)
              }
            }
          )
        }
      } else {
        toast.error("Please select at least one order")
      }
    } catch (e) {}
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 20,
      padding: 20,
      flexGrow: 1,
    },
  })
  const styless = StyleSheet.create({
    page: {
      padding: 20,
    },
    table: {
      display: "table",
      width: "auto",
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    table2: {
      display: "table",
      width: "auto",
      marginTop: "15px",
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      // margin: "5px",
      flexDirection: "row",
      borderBottom: "0.5px solid black",
      borderLeft: "0.5px solid black",
      borderRight: "0.5px solid black",
    },
    tableRow3: {
      margin: "5px",
      // border: "1px solid gray",
      width: "100%",
      flexDirection: "row",
    },
    tableColHeader: {
      width: "40%",
      // borderStyle: "solid",
      fontSize: "14px",
      fontWeight: "800",
    },
    tableRowHeader1: {
      width: "100%",
      borderTop: "0.5px solid black",
      backgroundColor: "#B2E4EB",
      height: "30px",
      fontSize: "13px",
      fontWeight: "600",
    },
    tableData1: {
      width: "80%",
      // border: "1px solid gray",
      // height: "30px",
      fontSize: "12px",
      fontWeight: "500",
    },
    tableData2: {
      width: "20%",
      // border: "1px solid gray",
      fontSize: "12px",
      fontWeight: "500",
    },
    tableRowHeader2: {
      width: "35%",
      height: "50px",
      backgroundColor: "#2A3042",
      // borderStyle: "solid",
      fontSize: "18px",
      fontWeight: "800",
    },
    tableCol: {
      width: "60%",
      fontSize: "14px",
      fontWeight: "600",
      // borderStyle: "solid",
    },
    tableCol3: {
      width: "65%",
      fontSize: "12px",
      fontWeight: "600",
      // borderStyle: "solid",
      marginRight: "40px",
    },
    tableColHeader3: {
      width: "65%",
      // borderStyle: "solid",
      fontSize: "12px",
      fontWeight: "600",
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      fontWeight: "bold",
    },
    tableCol2: {
      width: "50%",
      borderRightWidth: 1,
      // borderRightColor: "#000",
      paddingLeft: 5,
      paddingRight: 5,
    },
    tableCell2: {
      fontSize: 10,
    },
  })

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={{
              position: "absolute",
              right: "67px",
              fontSize: "16px",

              marginTop: "68px",
              fontWeight: "bold",
            }}
          >
            {config.APP_DETAILS.APP_NAME}, LLC Invoice
          </Text>
          <Text
            style={{
              position: "absolute",
              right: "50px",
              marginTop: "88px",
              fontSize: "10px",
              fontWeight: "hairline",
              opacity: "0.8",
              // color: "#a3a2a2",
            }}
          >
            Email to us about your account visit{" "}
            {config.site.URL.toString().replace("https://", "")}
          </Text>
          <Line
            style={{
              position: "absolute",
              marginTop: "102px",
              width: "270px",
              right: "10px",
              marginBottom: 10,
              borderBottomWidth: 1,
              borderBottomColor: "gray",
            }}
          ></Line>
          <Text
            style={{
              position: "absolute",
              right: "176px",
              marginTop: "103px",
              opacity: "0.8",
              fontSize: "13px",
              fontWeight: "heavy",
            }}
          >
            Invoice Summary
          </Text>
          <Line
            style={{
              position: "absolute",
              marginTop: "120px",
              width: "270px",
              right: "10px",
              marginBottom: 10,
              borderBottomWidth: 1,
              borderBottomColor: "gray",
            }}
          ></Line>
          <Text
            style={{
              position: "absolute",
              right: "210px",
              marginTop: "125px",
              fontSize: "9px",
              opacity: "0.8",
              fontWeight: "hairline",
              // color: "#a3a2a2",
            }}
          >
            Invoice Number:
          </Text>
          <Text
            style={{
              position: "absolute",
              right: "10px",
              marginTop: "125px",
              fontSize: "9px",
              opacity: "0.8",
              fontWeight: "hairline",
              // color: "#a3a2a2",
            }}
          >
            {invoiceData ? invoiceData.order_unique_id : "-"}
          </Text>
          <Text
            style={{
              position: "absolute",
              right: "223px",
              marginTop: "140px",
              fontSize: "9px",
              opacity: "0.8",
              fontWeight: "hairline",
              // color: "#a3a2a2",
            }}
          >
            Invoice Date:
          </Text>
          <Text
            style={{
              position: "absolute",
              right: "10px",
              marginTop: "140px",
              fontSize: "9px",
              fontWeight: "hairline",
              opacity: "0.8",
              // color: "#a3a2a2",
            }}
          >
            {invoiceData
              ? invoiceData.date.substr(0, 10).split("-").reverse().join("-")
              : "-"}
          </Text>
          <Line
            style={{
              position: "absolute",
              marginTop: "155px",
              width: "270px",
              right: "10px",
              marginBottom: 10,
              borderBottomWidth: 1,
              borderBottomColor: "gray",
            }}
          ></Line>
          <Text
            style={{
              position: "absolute",
              right: "200px",
              marginTop: "160px",
              fontSize: "13px",
              opacity: "0.8",
            }}
          >
            Total Amount
          </Text>
          <Text
            style={{
              position: "absolute",
              right: "10px",
              marginTop: "160px",
              opacity: "0.8",
              fontSize: "13px",
            }}
          >
            ${amountFormat(parseFloat(invoiceData ? invoiceData.price : 0))}{" "}
          </Text>
          <Image
            src={logo}
            style={{
              width: "130px",
              // position: "absolute",
              // right: "10px",
              backgroundColor: "#384eb4",
              padding: "10px",
              borderRadius: "5px",
            }}
          />
          <Text
            style={{
              fontSize: "9px",
              fontWeight: 400,
              color: "#000000",
              opacity: "0.8",
              // fontFamily: "Courier New",
              marginTop: "9px",
            }}
          >
            Account Number:
          </Text>
          <Text
            style={{
              fontSize: "13px",
              marginTop: "3px",
              fontWeight: "light",
              // fontWeight: "hairline",
              color: "#000000",
              opacity: "0.8",
            }}
          >
            nullship-{invoiceData ? invoiceData.user_id : "-"}
          </Text>
          <Text
            style={{
              fontSize: "9px",
              fontWeight: 400,
              color: "#000000",
              opacity: "0.8",
              marginTop: "12px",
              marginBottom: "3px",
            }}
          >
            Bill from Address :
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "light",
              opacity: "0.8",
              color: "#000000",
            }}
          >
            {invoiceData ? invoiceData.from_name : "-"}
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "light",
              opacity: "0.8",
              color: "#000000",
            }}
          >
            {invoiceData ? invoiceData.from_street1 : "-"}
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "light",
              opacity: "0.8",
              color: "#000000",
            }}
          >
            {invoiceData ? invoiceData.from_city : "-"} -{" "}
            {invoiceData ? invoiceData.from_zip : "-"}
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "light",
              opacity: "0.8",
              color: "#000000",
            }}
          >
            {invoiceData ? invoiceData.from_state : "-"} -{" "}
            {invoiceData ? invoiceData.from_country : "-"}
          </Text>
          {/* <Line
            style={{
              marginTop: 20,
              marginBottom: 10,
              borderBottomWidth: 1,
              borderBottomColor: "gray",
            }}
          ></Line> */}
          <Text
            style={{
              fontSize: "14px",
              // textAlign: "center",
              marginTop: "40px",
              marginBottom: "5px",
            }}
          >
            This invoice is for the billing period{" "}
            {invoiceData
              ? invoiceData.date.substr(0, 10).split("-").reverse().join("-")
              : "-"}
          </Text>
          <Text
            style={{
              fontSize: "8px",
              // textAlign: "center",
              // marginTop: "0px",
              opacity: "0.8",
              marginBottom: "4px",
            }}
          >
            Greetings from {config.APP_DETAILS.APP_NAME} Services, we’re writing to provide you with
            an electronic invoice for your use of {config.APP_DETAILS.APP_NAME} services. Additional
            information about your bill, individual service charge details, and
            your account history are available on the {config.APP_DETAILS.APP_NAME} portal.
          </Text>
          {/* <View style={styless.table}>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Account:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>{invoiceData ? invoiceData.email : "email"}</Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Name:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  {invoiceData ? invoiceData.first_name : "-"}{" "}
                  {invoiceData ? invoiceData.last_name : "-"}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Date:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  {moment(invoiceData ? invoiceData.date : new Date()).format(
                    "MMM DD, YYYY"
                  )}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Service Fee:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                  )}{" "}
                  USD
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Order ID:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>{invoiceData ? invoiceData.order_unique_id : "-"}</Text>
              </View>
            </View>
          </View> */}
          <View style={styless.table2}>
            <View style={styless.tableRow}>
              <View style={styless.tableRowHeader1}>
                <Text style={{ margin: "6px" }}>Summary</Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>
                  {invoiceData ? invoiceData.carrier_name : "-"} -{" "}
                  {invoiceData ? invoiceData.service_name : "-"}
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $
                  {amountFormat(
                    parseFloat(
                      invoiceData
                        ? parseFloat(invoiceData.price) -
                            (invoiceData.insurance_amount
                              ? invoiceData.insurance_amount
                              : 0)
                        : 0
                    )
                  )}{" "}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>Charges</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $
                  {amountFormat(
                    parseFloat(
                      invoiceData
                        ? parseFloat(invoiceData.price) -
                            (invoiceData.insurance_amount
                              ? invoiceData.insurance_amount
                              : 0)
                        : 0
                    )
                  )}{" "}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>Credits</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $0.00
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>
                  Cost of Insurance
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $
                  {amountFormat(
                    parseFloat(
                      invoiceData
                        ? invoiceData.insurance_amount
                          ? invoiceData.insurance_amount
                          : 0
                        : 0
                    )
                  )}{" "}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>Tax</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $0.00
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text
                  style={{ margin: "6px", fontSize: "13px", fontWeight: "600" }}
                >
                  Total Amount
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{
                    margin: "6px",
                    fontSize: "13px",
                    textAlign: "right",
                    fontWeight: "600",
                  }}
                >
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0)
                  )}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View style={styless.table2}>
            <View style={styless.tableRow}>
              <View style={styless.tableRowHeader1}>
                <Text style={{ margin: "6px" }}>
                  Detail for Consolidated Bill
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>
                  {invoiceData ? invoiceData.carrier_name : "-"} -{" "}
                  {invoiceData ? invoiceData.service_name : "-"}
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $
                  {amountFormat(
                    parseFloat(
                      invoiceData
                        ? parseFloat(invoiceData.price) -
                            (invoiceData.insurance_amount
                              ? invoiceData.insurance_amount
                              : 0)
                        : 0
                    )
                  )}{" "}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>Charges</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $
                  {amountFormat(
                    parseFloat(
                      invoiceData
                        ? parseFloat(invoiceData.price) -
                            (invoiceData.insurance_amount
                              ? invoiceData.insurance_amount
                              : 0)
                        : 0
                    )
                  )}{" "}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>VAT **</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $0.00
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>
                  Estimated US sales tax to be collected
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $0.00
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "4px", fontSize: "7px" }}>CT</Text>
              </View>
              <View style={styless.tableData2}>
                <Text
                  style={{ margin: "4px", textAlign: "right", fontSize: "7px" }}
                >
                  $0.00
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              marginTop: "45px",
              width: "65%",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            ** This is not a VAT, ST or GST invoice. Related tax invoices can be
            accessed by going to the Bills page on your Billing Management
            Console.
          </Text>
          <Text
            style={{
              marginTop: "5px",
              width: "65%",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            **** Please reference the tax invoice for a breakout of the Canadian
            taxes by type
          </Text>
          <Text
            style={{
              position: "absolute",
              marginTop: "565px",
              width: "30%",
              right: "20px",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            Service Provider:
          </Text>
          <Text
            style={{
              position: "absolute",
              marginTop: "575px",
              width: "30%",
              right: "20px",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            {config.APP_DETAILS.APP_NAME} LLC
          </Text>
          <Text
            style={{
              position: "absolute",
              marginTop: "585px",
              width: "30%",
              right: "20px",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            261 N UNIVERSITY DR STE 500 # 1077
          </Text>
          <Text
            style={{
              position: "absolute",
              marginTop: "595px",
              width: "30%",
              right: "20px",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            PLANTATION, FL 33324-2009
          </Text>
          <Text
            style={{
              position: "absolute",
              marginTop: "605px",
              width: "30%",
              right: "20px",
              fontSize: "8px",
              fontWeight: "400",
            }}
          >
            UNITED STATES
          </Text>
        </View>
      </Page>
    </Document>
  )

  const handleInvoice = async id => {
    // console.log("id of order complete", id)
    let body = {
      id: id,
    }
    let loading = toast.loading("Exporting invoice...")
    post("/user/get-completed-order-by-id", body, { headers }).then(
      response => {
        // console.log("responseee", response)
        if (response.status === 200) {
          setInvoiceData(response.data[0])
          setTimeout(() => {
            document.getElementsByClassName("pdf-downloader")[0].click()
            // const input = document.getElementById('invoice');
            // html2canvas(input).then((canvas) => {
            //   const imgData = canvas.toDataURL('image/png');
            //   const pdf = new jsPDF();
            //   pdf.addImage(imgData, 'PNG', 0, 0);
            //   pdf.save(`${response.data[0].order_unique_id}.pdf`); // Replace 'myPDF' with the name you want to give your PDF file
            //   setInvoiceData(null);
            toast.remove(loading)
            toast.success("Invoice exported")
            // });
          }, 1000)
        } else {
          toast.error(response.message)
          toast.remove(loading)
        }
      }
    )
  }

  function checkAll(e) {
    const { checked } = e.target
    let checkboxes = document.getElementsByClassName("download-checkbox")
    selectedOrders = []
    let arr = clickedSideBarJson

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false
        selectedOrders = []
        arr = []
      } else {
        checkboxes[i].checked = true
        if (!selectedOrders.includes(checkboxes[i].value)) {
          selectedOrders.push(checkboxes[i].value)
          let index = sideBarData.findIndex(el => el.pdf == checkboxes[i].value)
          arr.push(sideBarData[index])
        }
      }
    }

    clickedSideBarJson = arr

    setSelectedOrdersCount(selectedOrders.length)
    // console.log("selected", selectedOrders)
  }

  function uncheckAll() {
    let selectAllCheckbox = document.getElementsByClassName(
      "select-all-checkbox"
    )
    let checkboxes = document.getElementsByClassName("download-checkbox")
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  const handleOrderCheck = e => {
    const { value, checked } = e.target
    let arr = clickedSideBarJson
    if (checked) {
      if (!selectedOrders.includes(value)) {
        selectedOrders.push(value)
        let index = sideBarData.findIndex(el => el.pdf == value)
        // console.log("allData[index]",sideBarData[index]);
        arr.push(sideBarData[index])
        clickedSideBarJson = arr
      }
    } else {
      let index = selectedOrders.findIndex(el => el == value)
      selectedOrders.splice(index, 1)

      let indexToRemove = arr.findIndex(el => el.pdf == value)
      arr.splice(indexToRemove, 1)
      clickedSideBarJson = arr
      // selectedOrders.filter((el) => el !== value);
    }

    // console.log("filteredData",filteredData);
    // console.log("clickedSideBarJson",clickedSideBarJson);

    setSelectedOrdersCount(selectedOrders.length)
    document.getElementsByClassName("right-bar-enabled")[0].click()
    // console.log("selectedOrders", selectedOrders)
  }

  const handleDownload = async () => {
    if (selectedOrders.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      try {
        //making pdf status to downloaded
        const zip = new JSZip()
        let notExistingFilesCount = 0
        for (let i = 0; i < selectedOrders.length; i++) {
          // console.log("label request", i, "order",selectedOrders[i])
          await axios
            .get(`${baseUrl()}/user/get-pdf/${selectedOrders[i]}`, {
              headers,
              responseType: "blob",
            })
            .then(async response => {
              if (selectedOrders[i].toString().includes(".zpl")) {
                if (response.headers["file-name"]) {
                  const blob = new Blob([response.data], { type: "text/plain" })
                  // const url = URL.createObjectURL(blob);
                  // window.open(url, '_blank');
                  await zip.file(
                    `${i}-${response.headers["file-name"]}.zpl`,
                    blob
                  )
                } else {
                  notExistingFilesCount++
                }
              } else {
                // console.log("response",response);
                if (response.headers["file-name"]) {
                  // console.log(response.headers["file-name"]);
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  })
                  // const url = URL.createObjectURL(blob);
                  // window.open(url, '_blank');
                  await zip.file(
                    `${i}-${response.headers["file-name"]}.pdf`,
                    blob
                  )
                  // console.log("label add in zip")
                }
                // else if(response.data && response.data.status == 500) {
                else {
                  notExistingFilesCount++
                }
              }
            })
            .catch(err => {
              // console.log("Something went wrong, Please try again",err)
              document.body.style.pointerEvents = "auto"
              toast.remove(loading)
              toast.error("Something went wrong")
            })
        }

        if (Object.keys(zip.files).length > 0) {
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
          let loading1 = toast.loading("Downloading orders...")
          if (notExistingFilesCount > 0) {
            // toast.remove(loading1);
            toast.error(`${notExistingFilesCount} files do not exist`)
          }
          zip.generateAsync({ type: "blob" }).then(async function (content) {
            saveAs(content, "orders.zip")

            let order_ids = []
            for (let i = 0; i < selectedOrders.length; i++) {
              order_ids.push(
                filteredAllData.filter(el => el.pdf == selectedOrders[i])[0]
                  .order_id
              )
            }
            let payload = {
              order_ids: order_ids.toString(),
            }
            console.log("payload", payload)
            post("/user/mark-pdf-printed", payload, { headers })
              .then(async response => {
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                await retrieveOrderCounts(fetchLastMonth)
                await retrieveOrdersCompleted(filterType, fetchLastMonth)
                document.getElementsByClassName(
                  "select-all-checkbox"
                )[0].checked = false
                document.body.style.pointerEvents = "auto"
              })
              .catch(async errr => {
                console.log("error while change is printed status", errr)
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                await retrieveOrderCounts(fetchLastMonth)
                await retrieveOrdersCompleted(filterType, fetchLastMonth)
                document.getElementsByClassName(
                  "select-all-checkbox"
                )[0].checked = false
                document.body.style.pointerEvents = "auto"
              })
            toast.remove(loading1)
          })
        } else {
          document.body.style.pointerEvents = "auto"
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
          toast.error(
            "Files do not exist. Please share your order number to admin for the PDF Label"
          )
        }
      } catch (e) {
        console.log("error", e)
        toast.remove(loading)
        document.body.style.pointerEvents = "auto"
        toast.error("Something went wrong")
      }
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  const handleCombineLabelAndPackingSlip = async () => {
    if (selectedOrders.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      try {
        const zip = new JSZip()
        let notExistingFilesCount = 0
        let notExistingPackingSlipFilesCount = 0
        for (let i = 0; i < selectedOrders.length; i++) {
          // console.log("label request", i, "order",selectedOrders[i])
          await axios
            .get(`${baseUrl()}/user/get-pdf/${selectedOrders[i]}`, {
              headers,
              responseType: "blob",
            })
            .then(async response => {
              if (selectedOrders[i].toString().includes(".pdf")) {
                // console.log("response",response);
                if (response.headers["file-name"]) {
                  // console.log(response.headers["file-name"]);
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  })
                  // const url = URL.createObjectURL(blob);
                  // window.open(url, '_blank');
                  await zip.file(`${response.headers["file-name"]}.pdf`, blob)
                  // console.log("label add in zip")
                }
                // else if(response.data && response.data.status == 500) {
                else {
                  notExistingFilesCount++
                }
              }
            })
            .catch(err => {
              // console.log("Something went wrong, Please try again",err)
              document.body.style.pointerEvents = "auto"
              toast.remove(loading)
              toast.error("Something went wrong")
            })
          //Getting packing slip
          await axios
            .get(`${baseUrl()}/user/get-packing-slip/${selectedOrders[i]}`, {
              headers,
              responseType: "blob",
            })
            .then(async response => {
              if (selectedOrders[i].toString().includes(".pdf")) {
                // console.log("response",response);
                if (response.headers["file-name"]) {
                  // console.log(response.headers["file-name"]);
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  })
                  // const url = URL.createObjectURL(blob);
                  // window.open(url, '_blank');
                  await zip.file(`${response.headers["file-name"]}.pdf`, blob)
                  // console.log("label add in zip")
                }
                // else if(response.data && response.data.status == 500) {
                else {
                  notExistingPackingSlipFilesCount++
                }
              }
            })
            .catch(err => {
              // console.log("Something went wrong, Please try again",err)
              document.body.style.pointerEvents = "auto"
              toast.remove(loading)
              toast.error("Something went wrong")
            })
        }

        if (Object.keys(zip.files).length > 0) {
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
          let loading1 = toast.loading("Downloading orders...")
          if (notExistingFilesCount > 0) {
            // toast.remove(loading1);
            toast.error(`${notExistingFilesCount} label files do not exist`)
          }

          if (notExistingPackingSlipFilesCount > 0) {
            toast.error(
              `${notExistingFilesCount} packing slip files do not exist`
            )
          }

          zip.generateAsync({ type: "blob" }).then(async function (content) {
            saveAs(content, "combined_label_and_packing_slips.zip")
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            await retrieveOrderCounts(fetchLastMonth)
            await retrieveOrdersCompleted(filterType, fetchLastMonth)
            document.getElementsByClassName(
              "select-all-checkbox"
            )[0].checked = false
            toast.remove(loading1)
          })
        } else {
          document.body.style.pointerEvents = "auto"
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          toast.remove(loading)
          toast.error(
            "Files do not exist. Please share your order number to admin for the PDF Label"
          )
        }
      } catch (e) {
        toast.remove(loading)
        toast.error("Something went wrong")
      }
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  const handleMergedCombineLabelAndPackingSlip = async () => {
    console.log("handleMergedCombineLabelAndPackingSlip")
    if (selectedOrders.length > 0) {
      // console.log("selectedOrders",selectedOrders);
      pdfBlobs = []
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      let notExistingFilesCount = 0
      let notExistingPackingSlipFilesCount = 0

      for (let i = 0; i < selectedOrders.length; i++) {
        // console.log("request to download",selectedOrders[i]);
        await axios
          .get(`${baseUrl()}/user/get-pdf/${selectedOrders[i]}`, {
            headers,
            responseType: "blob",
          })
          .then(async response => {
            console.log("download", selectedOrders[i])
            if (
              !selectedOrders[i].toString().includes(".zpl") &&
              !selectedOrders[i].toString().includes(".txt")
            ) {
              if (response.headers["file-name"]) {
                console.log("response", response)
                const pdfBlob = new Blob([response.data], {
                  type: "application/pdf",
                })
                pdfBlobs.push(pdfBlob)
              } else {
                // console.log(selectedOrders[i],"response",response)
                notExistingFilesCount++
              }
            }
          })
          .catch(err => {
            // console.log("err",err);
          })

        await axios
          .get(`${baseUrl()}/user/get-packing-slip/${selectedOrders[i]}`, {
            headers,
            responseType: "blob",
          })
          .then(async response => {
            console.log("download", selectedOrders[i])
            if (
              !selectedOrders[i].toString().includes(".zpl") &&
              !selectedOrders[i].toString().includes(".txt")
            ) {
              if (response.headers["file-name"]) {
                console.log("response", response)
                const pdfBlob = new Blob([response.data], {
                  type: "application/pdf",
                })
                pdfBlobs.push(pdfBlob)
              } else {
                // console.log(selectedOrders[i],"response",response)
                notExistingPackingSlipFilesCount++
              }
            }
          })
          .catch(err => {
            // console.log("err",err);
          })
      }

      if (notExistingFilesCount > 0) {
        toast.error(notExistingFilesCount + " label file do not exits")
      }

      if (notExistingPackingSlipFilesCount > 0) {
        toast.error(
          notExistingPackingSlipFilesCount + " packing slip file do not exits"
        )
      }

      let order_ids = []
      for (let i = 0; i < selectedOrders.length; i++) {
        order_ids.push(
          filteredAllData.filter(el => el.pdf == selectedOrders[i])[0].order_id
        )
      }
      let payload = {
        order_ids: order_ids.toString(),
      }
      console.log("payload", payload)
      post("/user/mark-pdf-printed", payload, { headers })
        .then(async response => {
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          document.body.style.pointerEvents = "auto"
        })
        .catch(async errr => {
          console.log("error while change is printed status", errr)
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          document.body.style.pointerEvents = "auto"
        })

      toast.remove(loading)
      let loading1 = toast.loading("Downloading orders...")
      const mergedBlob = await mergePDFs(pdfBlobs)
      const blob = new Blob([mergedBlob], { type: "application/pdf" })

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob)

      // Create a temporary link to trigger the download
      const a = document.createElement("a")
      a.href = url
      a.download = "merged-label-and-packing-slip.pdf"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()

      // Clean up
      window.URL.revokeObjectURL(url)
      toast.remove(loading1)
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  const recursiveRetrieve = (type, page, orderData = []) => {
    let body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
      carrier: type,
      page: page,
    }
    post("/user/get-orders-completed-by-carrier", body, { headers }).then(
      async response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            let res
            if (orderData.length == 0) {
              res = response.data.filter(el => el.pdf != null)
            } else {
              res = orderData.concat(response.data.filter(el => el.pdf != null))
            }
            // filteredData = res
            // let data = res;
            // // console.log("table data", { columns, data })
            // tableData = { columns, data };
            setPending(false)
            let nextPage = +page + +1
            // console.log("next page", nextPage)
            await recursiveRetrieve(type, nextPage, res)
          } else {
            // filteredData =orderData
            // let data = orderData;
            // // console.log("table data", { columns, data })
            // tableData = { columns, data };
            setPending(false)
            return data
          }
        } else {
          // filteredData =orderData
          // let data = orderData;
          // // console.log("table data", { columns, data })
          // tableData = { columns, data };
          setPending(false)
          return data
        }
      }
    )
  }

  const retrieveCount = async type => {
    try {
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        carrier: type,
      }
      // console.log("body",body);
      post("/user/get-count-of-completed-order-by-carrier", body, {
        headers,
      }).then(
        // post("/user/get-orders-completed-by-carrier", body, { headers }).then(
        response => {
          // // console.log("response.data.length", response.data.length);
          if (response.status == 200) {
            setTotalCount(response.data)
          }
        }
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const handleTableData = async responseData => {
    // console.log("responseData",responseData);
    await handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      responseData
    )
    setAllData(responseData)
    sideBarData = responseData
  }

  const retrieveOrdersCompleted = async (
    type,
    fetch_last_month = fetchLastMonth
  ) => {
    setPending(true)
    document.getElementById("show").checked = false
    let body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
      carrier: type,
      fetch_last_month: fetch_last_month,
      store_id: localStorage.getItem("store"),
    }
    // console.log("body",body);
    // post("/user/get-orders-completed-by-carrier-and-offset", body, { headers }).then(
    post("/user/get-orders-completed-by-carrier", body, { headers }).then(
      async response => {
        if (response.status == 200) {
          let data = response.data.filter(el => el.pdf != null)
          await handleTableData(data)
        }
      }
    )
  }

  // const retrieveOrdersCompleted = async (type) => {
  //   localStorage.setItem("order-filter", type)
  //   try {
  //     if(!scrolling) {
  //       setScrolling(true);
  //       setLoadingOrders(true);
  //       let body = {
  //         id: JSON.parse(localStorage.getItem("authUser")).id,
  //         carrier: type,
  //         page: page
  //       }
  //       // console.log("body",body);
  //       post("/user/get-orders-completed-by-carrier-and-offset", body, { headers }).then(
  //         // post("/user/get-orders-completed-by-carrier", body, { headers }).then(
  //         response => {
  //           // console.log("response.data.length", response.data.length);
  //
  //           if(response.data.length > 0) {
  //             let mergeData = filteredData.concat(response.data.filter(el => el.pdf != null))
  //             filteredData = mergeData;
  //             // // console.log("shipping_details", JSON.parse(response.data[0].shipping_details).shipment_id);
  //             let data = mergeData;
  //             // console.log("table data", { columns, data })
  //             tableData = { columns, data };
  //             setPending(false)
  //             setLoading(false);
  //             dataCompleted = false;
  //             page  = page + 1;
  //             // getTrackingStatus(data)
  //           }
  //           else {
  //             dataCompleted = true;
  //             setLoading(false);
  //             setPending(false);
  //           }
  //
  //           if(filteredData.length == totalCount) {
  //             dataCompleted = true;
  //           }
  //
  //           setScrolling(false);
  //           setLoadingOrders(false);
  //         }
  //       )
  //     }
  //
  //   } catch (e) {
  //     setScrolling(false);
  //     setLoadingOrders(false);
  //     // console.log(e)
  //   }
  // }

  const [fedexOrderCount, setFedexOrderCount] = useState("-")
  const [upsOrderCount, setUpsOrder] = useState("-")
  const [upsv2OrderCount, setUpsv2Order] = useState("-")
  const [dhlOrderCount, setDhlOrder] = useState("-")
  const [uspsOrderCount, setUspsOrder] = useState("-")
  const [upsv5OrderCount, setUpsv5Order] = useState("-")

  const retrieveOrderCounts = async (fetch_last_month = fetchLastMonth) => {
    setFedexOrderCount("-")
    setUpsOrder("-")
    setUpsv2Order("-")
    setDhlOrder("-")
    setUspsOrder("-")
    let body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
      fetch_last_month: fetch_last_month,
      store_id: localStorage.getItem("store"),
    }
    post("/user/get-completed-order-counts", body, { headers }).then(
      response => {
        if (response.status == 200) {
          setFedexOrderCount(response.data.fedex_order_count)
          setUpsOrder(response.data.ups_order_count)
          setUpsv2Order(response.data.upsv2_order_count)
          setDhlOrder(response.data.dhl_order_count)
          setUspsOrder(response.data.usps_order_count)
          setUpsv5Order(response.data.upsv5_order_count)
        }
      }
    )
  }

  const [currentStore, setCurrentStore] = useState([])
  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/user/getStores", body, { headers }).then(response => {
        // console.log("stores in nav", response);
        let arr = []
        arr.push({
          name: "Manual Store",
          value: "0",
          type: "Manual Store",
        })
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            name: response.data[i].name,
            value: response.data[i].id.toString(),
            type: response.data[i].store_type,
          })
        }

        // console.log("user stores",arr);
        setStores(arr)
        setCurrentStore(
          arr.filter(el => el.id == localStorage.getItem("store"))[0]
        )

        // if(arr.filter(el => el.value == localStorage.getItem("store"))[0].type == "sellercloud") {
        //   hideShowPicklists = false;
        // }
        // else {
        //   hideShowPicklists = true;
        // }
        //
        // columns[8].omit = hideShowPicklists;
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrievePicklist = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).id
    const body = {
      user_id: id,
      store_id: localStorage.getItem("store"),
    }
    post(`/user/get-completed-picklists`, body, { headers }).then(response => {
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{ name: "Remove Filter", value: "none" }]
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            name: response.data[i].picklists,
            value: response.data[i].picklists,
          })
        }
        setPicklists(arr)
      } else {
        toast.error(response.message)
      }
    })
  }

  const retrieveTags = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).id
    get(`/user/get-tags/${id}`, { headers }).then(response => {
      // console.log("get-order-errors", response)
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{ name: "Remove Filter", value: "none" }]
        for (let i = 0; i < response.data.length; i++) {
          arr.push({ name: response.data[i].tag, value: response.data[i].id })
        }
        setTagsForFilter(arr)
        setTags(response.data)
      } else {
        toast.error(response.message)
      }
    })
  }

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carriers", { headers }).then(response => {
        // console.log("carriers", response)
        setCarriers(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // console.log("here");
    // if(config.site.MODE == "production") {
    //   initGA();
    // }
    clickedSideBarJson = []
    selectedOrders = []
    setSelectedOrdersCount(selectedOrders.length)
    page = 1
    dataCompleted = false
    // filteredData = [];
    // let data = [];
    // tableData = {columns, data};
    retrieveStores()
    retrievePicklist()
    retrieveCarriers()
    retrieveTags()
    retrieveOrdersCompleted(filterType)
    retrieveOrderCounts()
    retrieveCount(filterType)
  }, [])

  async function handleScroll() {
    // console.log("scrolling")
    // if ((parseInt(window.innerHeight + document.documentElement.scrollTop)) == parseInt(document.documentElement.scrollHeight)) { // this is causing loading issue data was not loading
    if (
      parseInt(document.documentElement.scrollHeight) -
        parseInt(window.innerHeight + document.documentElement.scrollTop) <=
      5
    ) {
      // console.log("scrolling bottom",dataCompleted);
      if (!dataCompleted) {
        setLoading(true)
      }
      await retrieveOrdersCompleted(filterType)
    }
  }

  const handleLoadMore = async () => {
    if (!dataCompleted) {
      setLoading(true)
    }
    await retrieveOrdersCompleted(filterType)
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [filteredData]);

  const handleFedExOrders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("FedEx")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("FedEx")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("FedEx")
    }
  }

  const handleUPSOrders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("UPS")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPS")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("UPS")
    }
  }

  const handleUPSv2Orders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("UPSv2")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPSv2")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("UPSv2")
    }
  }

  const handleDHLOrders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("DHL")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("DHL")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("DHL")
    }
  }

  const handleUSPSOrders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("USPS")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("USPS")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("USPS")
    }
  }

  const handleUPSv5Orders = async () => {
    if (!loadingOrders) {
      await retrieveOrderCounts()
      await retrieveCount("UPSv5")
      setPending(true)
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPSv5")
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      uncheckAll()
      page = 1
      dataCompleted = false
      // filteredData = [];
      // let data = [];
      // tableData = { columns, data };
      await retrieveOrdersCompleted("UPSv5")
    }
  }

  const handleOnPageChange = () => {
    document.getElementById("select-all-checkbox").checked = false
    if (selectedOrders.length > 0) {
      for (let i = 0; i < selectedOrders.length; i++) {
        if (document.getElementById(`order-${selectedOrders[i]}`)) {
          document.getElementById(`order-${selectedOrders[i]}`).checked = true
        }
      }
    }
  }

  const handleRowClick = row => {
    // Handle row click event here
    // let data = row
    // console.log("data", data);
    // // // console.log("data.id", data.id)
    // // // console.log("tableData", tableData)
    // let index = tableData.data.findIndex(el => el.id == data.id)
    // setOrderUniqueId(tableData.data[index].order_unique_id)
    //
    // retrieveOrder(data.id)
    // uncheckAll();
    // document.getElementById(`order-${data.id}`).checked = true;
    // selectedOrders = [data.id];
    // // console.log("handleRowClick selectedOrders", selectedOrders);
    // selectedOrderJson.push(data);
    // // console.log("selectedOrderJson", selectedOrderJson);
    // setClickedJson(selectedOrderJson);
    // console.log(`order-${row.id}`)
    document.getElementById(`order-${row.pdf}`).click()
  }

  const expandableRows = row => {
    // console.log("row",row);
    return (
      <>
        <div
          className={"col-md-12 mt-2 mb-2 row p-2"}
          style={{
            backgroundColor:
              localStorage.getItem("theme") == "dark" ? "#222224" : "#f7f5f5",
          }}
        >
          <h5>Item Details</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {row.data.is_custom_form >= 0 ? (
              row.data.custom_items_data?.length > 0 ? (
                JSON.parse(
                  row.data.store_type == "ebay"
                    ? JSON.parse(row.data.custom_items_data)
                    : row.data.custom_items_data
                ).map((el, id) => {
                  if (row.data.store_type == null) {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.product_identifier}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.description ? el.description : "-"}
                          </div>
                        </h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  } else if (row.data.store_type == "shopify") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.name ? el.name : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.price ? el.price : "-"}{" "}
                          {el.price_set.shop_money.currency_code}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "ebay") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.lineItemId}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.title ? el.title : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.lineItemCost.value} {el.lineItemCost.currency}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "walmart") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          SKU: {el.item.sku}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.orderLineQuantity.amount
                              ? el.orderLineQuantity.amount
                              : "-"}
                            x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.item.productName ? el.item.productName : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.charges.charge[0].chargeAmount.amount}{" "}
                          {el.charges.charge[0].chargeAmount.currency}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "zenventory") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.customerorderitemid}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.itemmaster.description
                              ? el.itemmaster.description
                              : "-"}
                          </div>
                        </h5>
                        <p>${el.customerprice}</p>
                      </div>
                    )
                  } else if (row.data.store_type == "sellercloud") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.ProductID}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.Qty ? el.Qty : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.DisplayName ? el.DisplayName : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.DisplayDescription ? el.DisplayDescription : "-"}
                        </p>
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>
                            ${el.AdjustedSitePrice}
                          </p>
                          <p style={{ width: "50%", textAlign: "right" }}>
                            <span style={{ fontWeight: "500" }}>Weight:</span>{" "}
                            {el.Weight ? el.Weight + "lb." : "-"}
                          </p>
                        </div>
                        <p>
                          <span style={{ fontWeight: "500" }}>L x W x H:</span>{" "}
                          {el.Length ? el.Length : "-"} x{" "}
                          {el.Width ? el.Width : "-"} x{" "}
                          {el.Height ? el.Height : "-"}
                        </p>
                      </div>
                    )
                    // return (
                    //   <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                    //     <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.ProductID}</h6>
                    //     <hr/>
                    //     <h5><span style={{float:"right"}}>{el.Qty ? el.Qty : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{row.data.is_package == 1? el.ItemName: (el.DisplayName ? el.DisplayName : "-")}</div></h5>
                    //     <p style={{display:(row.data.is_package == 1?"none":"block")}}>{el.DisplayDescription?el.DisplayDescription:"-"}</p>
                    //     <div style={{display:(row.data.is_package == 1?"none":"flex")}}>
                    //       <p style={{width:"50%"}}>${el.AdjustedSitePrice}</p>
                    //       <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>
                    //     </div>
                    //     <p style={{display:(row.data.is_package == 1?"none":"block")}}><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>
                    //   </div>
                    // )
                  } else if (row.data.store_type == "shipstation") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "400px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.orderItemId}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.name ? el.name : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>${el.unitPrice}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              width: "",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {el?.imageUrl && (
                              <a
                                href={el?.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={el?.imageUrl}
                                  alt={el?.name}
                                  style={{
                                    height: "auto",
                                    width: "40px",
                                    marginRight: "10px",
                                  }}
                                />
                              </a>
                            )}
                            {/* <span style={{width: "180px",whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden"}}>{el?.name ? el?.name: "-"}</span> */}
                          </div>
                          <div className="mt-2">
                            {/* <p><span style={{fontWeight:"500"}}><strong>Item Weight:</strong></span> {el.weight?el.weight:"-"}</p> */}
                            {el.imageUrl != null && (
                              <p>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    marginRight: "10px",
                                  }}
                                >
                                  <strong>image:</strong>
                                </span>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={el?.imageUrl}
                                >
                                  {el.imageUrl ? el.imageUrl : "-"}
                                </a>
                              </p>
                            )}
                          </div>
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  } else if (row.data.store_type == "veeqo") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.sellable.id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.sellable.product_title
                              ? el.sellable.product_title
                              : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>${el.sellable.price}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  }
                  else if (row.data.store_type == "shiphero") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.line_item_id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity_allocated ? el.quantity_allocated : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el?.sellable?.product_title
                              ? el?.sellable?.product_title
                              : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          {/* <p style={{ width: "50%" }}>${el.sellable.price}</p> */}
                          <p style={{ width: "50%" }}>${"--"}</p>

                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  }
                  else if (row.data.store_type == "orderdesk") {
                    {console.log(el,'elelelelelelelelel')}
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{width: "300px",height: "fit-content",padding: "10px 10px 0px 10px",margin: "10px",backgroundColor:localStorage.getItem("theme") == "dark"? "#0f0f0f": "white",}}>
                        <h6 className={"bold"} style={{ marginBottom: "-10px" }}>ID: {el?.id ? el?.id: "-"}</h6>
                        <hr />
                        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}} >
                          <div style={{width: "",whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",display:"flex",alignItems:"center"}}>
                            { el?.metadata?.image &&
                            <a href={el?.metadata?.image} target="_blank" rel="noopener noreferrer"  style={{cursor:"pointer"}}>
                              <img src={el?.metadata?.image} alt={el?.name} style={{height:"2rem",marginRight:"10px"}}/>
                            </a>                            }
                            <span style={{width: "180px",whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden"}}>{el?.name ? el?.name: "-"}</span>
                          </div>
                          <span>{el.quantity ? el.quantity: "-"}x</span>
                        </div>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex",marginTop:"10px" }}>
                          {/* <p style={{ width: "50%" }}>${el.total_price}</p> */}
                          <p style={{ width: "50%" }}>${el?.price}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  }
                })
              ) : (
                ""
              )
            ) : (
              <h6 style={{ textAlign: "center" }}>No Item Found</h6>
            )}
          </div>
        </div>
      </>
    )
  }

  //Filter and sortings
  const handleSortName = e => {
    setNameSort(e.target.value)
    handleSort(
      e.target.value,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      filteredData
    )
  }

  const handleSortWeight = e => {
    setWeightSort(e.target.value)
    handleSort(
      nameSort,
      e.target.value,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      filteredData
    )
  }

  const handleOrderDate = e => {
    setOrderDate(e.target.value)

    let fdata = allData
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      e.target.value,
      tagFilter,
      filterShipment,
      picklistFilter,
      fdata
    )
  }

  const handleSortQuantity = e => {
    setQuantitySort(e.target.value)
    handleSort(
      nameSort,
      weightSort,
      e.target.value,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      filteredData
    )
  }

  const handleSortTag = e => {
    setTagFilter(e.target.value)
    let fdata = allData
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      e.target.value,
      filterShipment,
      picklistFilter,
      fdata
    )
  }

  const handleSortPicklist = e => {
    picklistFilter = e.target
    let fdata = allData
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      e.target,
      fdata
    )
  }

  const handleAllocation = e => {
    // setAllocation(e.target.value);
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      filteredData
    )
  }

  const handleStoreSelect = async e => {
    setCurrentStore(e.target.value)
    localStorage.setItem("store", e.target.value)
    post(
      "/user/store-changed",
      { user_id: JSON.parse(localStorage.getItem("authUser")).id },
      { headers }
    )
    // if (e.target.value == 0) {
    //   columns[9].omit = true;
    // }
    // else {
    //   columns[9].omit = false;
    // }
    //
    // if(stores.filter(el => el.value == e.target.value)[0].type == "sellercloud") {
    //   hideShowPicklists = false;
    // }
    // else {
    //   hideShowPicklists = true;
    // }
    //
    // columns[8].omit = hideShowPicklists;

    let xx = []
    setAllData(xx)
    setFilteredData(xx)
    filteredAllData = xx
    sideBarData = []
    clickedSideBarJson = []
    retrievePicklist()
    picklistFilter = []
    if (localStorage.getItem("order-filter") == "Unassigned") {
      if (e.target.value == "0") {
        const activeCarrier = carriers.filter(el => el.is_active == 1)[0].name
        localStorage.setItem("order-filter", activeCarrier)
        setFilterType(localStorage.getItem("order-filter"))
        await retrieveOrderCounts()
        await retrieveOrdersCompleted(localStorage.getItem("order-filter"))
      }
    } else {
      await retrieveOrderCounts()
      await retrieveOrdersCompleted(filterType)
    }
  }

  const handleFilterShipment = async e => {
    setFilterShipment(e.target.value)
    let data1 = allData
    await handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      e.target.value,
      picklistFilter,
      data1
    )
  }

  const handleAssignee = async e => {
    // setAssignee(e.target.value)
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      picklistFilter,
      filteredData
    )
  }

  const handleSort = (
    name_sort,
    weight_sort,
    quantity_sort,
    order_date,
    tag_filter,
    destination,
    picklist,
    filtered_Data
  ) => {
    let data1 = [...filtered_Data]
    let data = []
    if (destination) {
      if (destination == "All") {
        data = data1
      }
      if (destination == "International") {
        data1.map(el => {
          if (el.from_country != el.to_country) {
            data.push(el)
          }
        })
      } else if (destination == "Domestic") {
        data1.map(el => {
          if (el.from_country == el.to_country) {
            data.push(el)
          }
        })
      }
    } else {
      data = data1
    }

    if (name_sort) {
      if (name_sort == "ASC") {
        data.sort((a, b) => a.to_name.localeCompare(b.to_name))
      } else if (name_sort == "DESC") {
        data.sort((a, b) => b.to_name.localeCompare(a.to_name))
      }
    }

    if (weight_sort) {
      if (weight_sort == "ASC") {
        data.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight))
      } else if (weight_sort == "DESC") {
        data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight))
      }
    }

    if (order_date) {
      // if(order_date == "ASC") {
      //   data.sort((a, b) => a.updated_at.localeCompare(b.updated_at));
      // }
      // else if (order_date == "DESC") {
      //   data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
      // }
      if (order_date == "Today") {
        data = data.filter(
          el =>
            moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") ==
            moment(new Date()).tz(moment.tz.guess()).format("YYYY-MM-DD")
        )
      } else if (order_date == "Yesterday") {
        // console.log("moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format(\"YYYY-MM-DD\")",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
        data = data.filter(
          el =>
            moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >=
            moment(new Date())
              .tz(moment.tz.guess())
              .subtract(1, "day")
              .format("YYYY-MM-DD")
        )
      } else if (order_date == "Last Week") {
        // console.log("fff",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
        data = data.filter(
          el =>
            moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >=
            moment(new Date())
              .tz(moment.tz.guess())
              .subtract(1, "week")
              .format("YYYY-MM-DD")
        )
      } else if (order_date == "Last Month") {
        data = data.filter(
          el =>
            moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >=
            moment(new Date())
              .tz(moment.tz.guess())
              .subtract(1, "month")
              .format("YYYY-MM-DD")
        )
      } else if (order_date == "Last Year") {
        data = data.filter(
          el =>
            moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >=
            moment(new Date())
              .tz(moment.tz.guess())
              .subtract(1, "year")
              .format("YYYY-MM-DD")
        )
      }
    }

    if (quantity_sort) {
      if (quantity_sort == "ASC") {
        data.sort((a, b) => {
          if (a.custom_items_data && b.custom_items_data) {
            return (
              JSON.parse(a.custom_items_data).length -
              JSON.parse(b.custom_items_data).length
            )
          }
        })
      } else if (quantity_sort == "DESC") {
        data.sort((a, b) => {
          if (a.custom_items_data && b.custom_items_data) {
            return (
              JSON.parse(b.custom_items_data).length -
              JSON.parse(a.custom_items_data).length
            ) // Compare normal ages
          }
        })
      }
    }

    if (tag_filter) {
      if (tag_filter != "none") {
        // console.log("tag_filter",tag_filter);
        // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
        data = data.filter(
          el => el.tag && el.tag?.toString().includes(tag_filter)
        )
      }
    }

    if (picklist.length > 0) {
      // console.log("tag_filter",tag_filter);
      // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
      let picklistFilteredData = []
      data.map(d => {
        for (let p = 0; p < picklist.length; p++) {
          if (d.picklists && d.picklists?.toString().includes(picklist[p])) {
            picklistFilteredData.push(d)
          }
        }
      })
      data = picklistFilteredData
    }
    // if(picklist) {
    //   if(picklist != "none") {
    //     // console.log("tag_filter",tag_filter);
    //     // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
    //     data = data.filter((el) => el.picklists && el.picklists?.toString().includes(picklist));
    //   }
    // }

    // console.log("data.filter((el) => el.tag != null) 111",data);

    setTableData({ columns, data })
    setFilteredData(data)
    filteredAllData = data
    handleHidePrinted({ target: { checked: hidePrinted } })
    setPending(false)
  }

  const [print_dropdown, setPrintDropdown] = useState(false)
  const [combine_dropdown, setCombineDropdown] = useState(false)
  const [merge_dropdown, setMergeDropdown] = useState(false)
  const [slip_name, setSlipName] = useState("")
  const [pc_to_street1, setPCToStreet1] = useState("")
  const [pc_to_street2, setPCToStreet2] = useState("")
  const [pc_to_city, setPCToCity] = useState("")
  const [pc_to_state, setPCToState] = useState("")
  const [pc_to_zip, setPCToZip] = useState("")
  const [pc_to_country, setPCToCountry] = useState("")
  const [pc_to_name, setPCToName] = useState("")

  const [pc_bill_to_street1, setPCBillToStreet1] = useState("")
  const [pc_bill_to_street2, setPCBillToStreet2] = useState("")
  const [pc_bill_to_city, setPCBillToCity] = useState("")
  const [pc_bill_to_state, setPCBillToState] = useState("")
  const [pc_bill_to_zip, setPCBillToZip] = useState("")
  const [pc_bill_to_country, setPCBillToCountry] = useState("")
  const [pc_bill_to_name, setPCBillToName] = useState("")

  const [pc_from_name, setPCFromName] = useState("")
  const [pc_from_street1, setPCFromStreet1] = useState("")
  const [pc_from_street2, setPCFromStreet2] = useState("")
  const [pc_from_city, setPCFromCity] = useState("")
  const [pc_from_state, setPCFromState] = useState("")
  const [pc_from_zip, setPCFromZip] = useState("")
  const [pc_from_country, setPCFromCountry] = useState("")

  const [pc_reference_number, setPCReferenceNumber] = useState("")
  const [pc_order_number, setPCOrderNumber] = useState("")
  const [pc_order_date, setPCOrderDate] = useState("")
  const [pc_shipment_date, setPCShipmentDate] = useState("")

  const [pc_items, setPcItems] = useState([])
  const [pc_sub_total, setPcSubTotal] = useState("")
  const [pc_extra_row, setPcExtraRow] = useState([])
  const [pc_total, setPcTotal] = useState("")

  const handlePackingSlip = async () => {
    console.log("handle packing slip")

    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${
          clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          await new Promise(resolve => {
            setTimeout(async () => {
              // setSlipName("")
              //
              // setPCToStreet1("")
              // setPCToStreet2("")
              // setPCToCity("")
              // setPCToState("")
              // setPCToZip("")
              // setPCToCountry("")
              // setPCToName("")
              //
              // setPCFromStreet1("")
              // setPCFromStreet2("")
              // setPCFromCity("")
              // setPCFromState("")
              // setPCFromZip("")
              // setPCFromCountry("")
              //
              // setPCOrderNumber("")
              // setPCOrderDate("")
              // setPcItems([]);
              // setPcSubTotal("0.00")
              // setPcTotal("0.00")

              setSlipName(el.order_unique_id)
              // console.log("el",el);
              setPCToStreet1(el.to_street1)
              setPCToStreet2(el.to_street2)
              setPCToCity(el.to_city)
              setPCToState(el.to_state)
              setPCToZip(el.to_zip)
              setPCToCountry(el.to_country)
              setPCToName(el.to_name)

              setPCFromName(el.from_name)
              setPCFromStreet1(el.from_street1)
              setPCFromStreet2(el.from_street2)
              setPCFromCity(el.from_city)
              setPCFromState(el.from_state)
              setPCFromZip(el.from_zip)
              setPCFromCountry(el.from_country)

              setPCOrderNumber(
                el.order_number
                  ? el.order_number +
                      (el.package_number
                        ? "-" +
                          el.package_number +
                          (el.package_index
                            ? " (" + el.package_index + ")"
                            : "")
                        : "")
                  : "-"
              )

              // console.log("is_package",el.is_package)
              let storeOrderData = ""
              if (el.store_type) {
                let StoreOrder = JSON.parse(el.store_order_json)
                if (el.store_type == "shopify") {
                  storeOrderData = moment(StoreOrder.created_at).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "ebay") {
                  storeOrderData = moment(StoreOrder.updated_at).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "walmart") {
                  storeOrderData = moment(StoreOrder.orderDate).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "zenventory") {
                  storeOrderData = moment(StoreOrder.ordereddate).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "sellercloud") {
                  storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "shipstation") {
                  storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                    "YYYY-MM-DD"
                  )
                } else if (el.store_type == "veeqo") {
                  storeOrderData = moment(StoreOrder.created_at).format(
                    "YYYY-MM-DD"
                  )
                }
                else if (el.store_type == "shiphero") {
                  storeOrderData = moment(StoreOrder.order_history[0].created_at).format("YYYY-MM-DD")
                }
              } else {
                storeOrderData = moment(el.order_date)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD")
              }

              // setPCOrderDate(el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.order_date.toString().substring(0,10))
              setPCOrderDate(storeOrderData)

              // setPCShipmentDate(el.created_at.toString().substring(0,10))
              setPCShipmentDate(
                moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD")
              )

              if (
                el.custom_items_data &&
                JSON.parse(
                  el.store_type == "ebay"
                    ? JSON.parse(el.custom_items_data)
                    : el.custom_items_data
                ).length > 0
              ) {
                let items = JSON.parse(
                  el.store_type == "ebay"
                    ? JSON.parse(el.custom_items_data)
                    : el.custom_items_data
                )
                let itemArray = []
                // console.log("items",items);
                await Promise.all(
                  items.map(item => {
                    if (el.store_type == null) {
                      itemArray.push({
                        product_identifier: item.product_identifier,
                        product_sku: "-",
                        description: item.description,
                        quantity: item.quantity,
                        price: parseFloat(item.total_value).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) * parseFloat(item.total_value)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "shopify") {
                      itemArray.push({
                        product_identifier: item.id,
                        product_sku: "-",
                        description: item.name,
                        quantity: item.quantity,
                        price: parseFloat(item.price).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) * parseFloat(item.price)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "ebay") {
                      itemArray.push({
                        product_identifier: item.lineItemId,
                        product_sku: "-",
                        description: item.title,
                        quantity: item.quantity,
                        price: parseFloat(item.lineItemCost.value).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) *
                            parseFloat(item.lineItemCost.value)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "walmart") {
                      itemArray.push({
                        product_identifier: item.id,
                        product_sku: item.item.sku,
                        description: item.item.productName,
                        quantity: item.orderLineQuantity.amount,
                        price: parseFloat(
                          item.charges.charge[0].chargeAmount.amount
                        ).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.orderLineQuantity.amount) *
                          parseFloat(item.charges.charge[0].chargeAmount.amount)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "zenventory") {
                      itemArray.push({
                        product_identifier: item.customerorderitemid,
                        product_sku: "-",
                        description: item.itemmaster.description,
                        quantity: item.quantity,
                        price: parseFloat(item.customerprice).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) *
                            parseFloat(item.customerprice)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "sellercloud") {
                      // itemArray.push({
                      //   'product_identifier': item.ProductID,
                      //   'description': el.is_package==0?item.DisplayName:item.ItemName,
                      //   'quantity': item.Qty,
                      //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
                      //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
                      // });

                      itemArray.push({
                        product_identifier: item.ProductID,
                        product_sku: "-",
                        description: item.DisplayName,
                        quantity: item.Qty,
                        price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.Qty) *
                            parseFloat(item.AdjustedSitePrice)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "shipstation") {
                      itemArray.push({
                        product_identifier: item.orderItemId,
                        product_sku: item.sku ? item.sku : "-",
                        description: item.name,
                        quantity: item.quantity,
                        price: parseFloat(item.unitPrice).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) * parseFloat(item.unitPrice)
                        ).toFixed(2),
                      })
                    } else if (el.store_type == "veeqo") {
                      itemArray.push({
                        product_identifier: item.sellable.id,
                        product_sku: item.sellable.sku_code
                          ? item.sellable.sku_code
                          : "-",
                        description: item.sellable.product_title,
                        quantity: item.quantity,
                        price: parseFloat(item.sellable.price).toFixed(2),
                        total_price: parseFloat(
                          parseInt(item.quantity) *
                            parseFloat(item.sellable.price)
                        ).toFixed(2),
                      })
                    }
                    else if (el.store_type == "shiphero") {
                      itemArray.push({
                        'product_identifier':'-',
                        'product_sku': item.sku ? item.sku : "-",
                        'description': item?.product_title?item?.product_title:"",
                        'quantity': item.quantity_allocated,
                        'price': '-',
                        'total_price':'-'
                      });
                    }
                  })
                )
                // console.log("itemArray",itemArray);
                setPcItems(itemArray)
                setPcSubTotal(
                  parseFloat(
                    itemArray.reduce((accumulator, currentObject) => {
                      return accumulator + parseFloat(currentObject.total_price)
                    }, 0)
                  ).toFixed(2)
                )
                setPcTotal(
                  parseFloat(
                    itemArray.reduce((accumulator, currentObject) => {
                      return accumulator + parseFloat(currentObject.total_price)
                    }, 0)
                  ).toFixed(2)
                )
              } else {
                setPcItems([])
                setPcSubTotal("0.00")
                setPcTotal("0.00")
              }

              setTimeout(() => {
                document
                  .getElementsByClassName("packing-slip-downloader")[0]
                  .click()
                resolve(true)
              }, 1000)

              if (clickedSideBarJson.length == i + 1) {
                toast.remove(loading)
                toast.success(
                  clickedSideBarJson.length +
                    ` packing ${
                      clickedSideBarJson.length > 1 ? "slips" : "slip"
                    } generated`
                )
                clickedSideBarJson = []
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                document.body.style.pointerEvents = "auto"
              }
            }, (i + 1) * 2000)
          })
        })
      )
    } else {
      toast.error("Please select at least one order")
    }
  }

  const tableStyles = StyleSheet.create({
    tableEmptyRow: {
      margin: "auto",
      width: "100%",
      flexDirection: "row",
      height: "20px",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    tableEmptyLastRow: {
      margin: "auto",
      width: "100%",
      flexDirection: "row",
      height: "20px",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0,
    },
  })

  const handlePackingSlipTemplate2 = async () => {
    // console.log("orders", clickedSideBarJson)
    console.log("template2")
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${
          clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          setTimeout(async () => {
            // setSlipName("")
            //
            // setPCToStreet1("")
            // setPCToStreet2("")
            // setPCToCity("")
            // setPCToState("")
            // setPCToZip("")
            // setPCToCountry("")
            // setPCToName("")
            //
            // setPCFromStreet1("")
            // setPCFromStreet2("")
            // setPCFromCity("")
            // setPCFromState("")
            // setPCFromZip("")
            // setPCFromCountry("")
            //
            // setPCOrderNumber("")
            // setPCOrderDate("")
            // setPcItems([]);
            // setPcSubTotal("0.00")
            // setPcTotal("0.00")

            setSlipName(el.order_unique_id)
            // console.log("el",el);
            setPCToStreet1(el.to_street1)
            setPCToStreet2(el.to_street2)
            setPCToCity(el.to_city)
            setPCToState(el.to_state)
            setPCToZip(el.to_zip)
            setPCToCountry(el.to_country)
            setPCToName(el.to_name)

            setPCFromName(el.from_name)
            setPCFromStreet1(el.from_street1)
            setPCFromStreet2(el.from_street2)
            setPCFromCity(el.from_city)
            setPCFromState(el.from_state)
            setPCFromZip(el.from_zip)
            setPCFromCountry(el.from_country)
            // console.log("eeeeee",el.package_number);

            setPCOrderNumber(
              el.order_number
                ? el.order_number +
                    (el.package_number
                      ? "-" +
                        el.package_number +
                        (el.package_index ? " (" + el.package_index + ")" : "")
                      : "")
                : "-"
            )
            setPCReferenceNumber(el.reference_number)
            let storeOrderData = ""
            if (el.store_type) {
              let StoreOrder = JSON.parse(el.store_order_json)
              if (el.store_type == "shopify") {
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "ebay") {
                storeOrderData = moment(StoreOrder.updated_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "walmart") {
                storeOrderData = moment(StoreOrder.orderDate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "zenventory") {
                storeOrderData = moment(StoreOrder.ordereddate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "sellercloud") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "shipstation") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.createDate).format(
                  "YYYY-MM-DD"
                )

                setPCBillToStreet1(StoreOrder.billTo.street1)
                setPCBillToStreet2(StoreOrder.billTo.street2)
                setPCBillToCity(StoreOrder.billTo.city)
                setPCBillToState(StoreOrder.billTo.state)
                setPCBillToZip(StoreOrder.billTo.postalCode)
                setPCBillToCountry(StoreOrder.billTo.country)
                setPCBillToName(StoreOrder.billTo.name)
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "veeqo") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              }
              else if (el.store_type == "shiphero") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.order_history[0].created_at).format("YYYY-MM-DD")
                // console.log("storeOrderData",storeOrderData)
              }
              else if (el.store_type == "oderdesk") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder?.date_added)?.format("YYYY-MM-DD")
                // console.log("storeOrderData",storeOrderData)
              }
            } else {
              storeOrderData = moment(el.order_date)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD")
            }

            // setPCOrderDate(el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.created_at.toString().substring(0,10))
            setPCOrderDate(storeOrderData)

            setPCShipmentDate(
              moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD")
            )

            if (
              el.custom_items_data &&
              JSON.parse(
                el.store_type == "ebay"
                  ? JSON.parse(el.custom_items_data)
                  : el.custom_items_data
              ).length > 0
            ) {
              let items = JSON.parse(
                el.store_type == "ebay"
                  ? JSON.parse(el.custom_items_data)
                  : el.custom_items_data
              )
              let itemArray = []
              // console.log("items",items);
              await Promise.all(
                items.map(item => {
                  if (el.store_type == null) {
                    itemArray.push({
                      product_identifier: item.product_identifier,
                      product_sku: "-",
                      description: item.description,
                      quantity: item.quantity,
                      price: parseFloat(item.total_value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.total_value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shopify") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.price)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "ebay") {
                    itemArray.push({
                      product_identifier: item.lineItemId,
                      product_sku: "-",
                      description: item.title,
                      quantity: item.quantity,
                      price: parseFloat(item.lineItemCost.value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                          parseFloat(item.lineItemCost.value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "walmart") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: item.item.sku,
                      description: item.item.productName,
                      quantity: item.orderLineQuantity.amount,
                      price: parseFloat(
                        item.charges.charge[0].chargeAmount.amount
                      ).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.orderLineQuantity.amount) *
                        parseFloat(item.charges.charge[0].chargeAmount.amount)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "zenventory") {
                    itemArray.push({
                      product_identifier: item.customerorderitemid,
                      product_sku: "-",
                      description: item.itemmaster.description,
                      quantity: item.quantity,
                      price: parseFloat(item.customerprice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.customerprice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "sellercloud") {
                    // itemArray.push({
                    //   'product_identifier': item.ProductID,
                    //   'description': el.is_package==0?item.DisplayName:item.ItemName,
                    //   'quantity': item.Qty,
                    //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
                    //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
                    // });

                    itemArray.push({
                      product_identifier: item.ProductID,
                      product_sku: "-",
                      description: item.DisplayName,
                      quantity: item.Qty,
                      price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shipstation") {
                    itemArray.push({
                      product_identifier: item.orderItemId,
                      product_sku: item.sku ? item.sku : "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.unitPrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.unitPrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "veeqo") {
                    itemArray.push({
                      product_identifier: item.sellable.id,
                      product_sku: item.sellable.sku_code
                        ? item.sellable.sku_code
                        : "-",
                      description: item.sellable.product_title,
                      quantity: item.quantity,
                      price: parseFloat(item.sellable.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                          parseFloat(item.sellable.price)
                      ).toFixed(2),
                    })
                  }
                  else if (el.store_type == "shiphero") {
                    itemArray.push({
                      'product_identifier': '-',
                      'product_sku': item.sku ? item.sku : "-",
                      'description': item?.product_title?item?.product_title:"",
                      'quantity': item.quantity_allocated,
                      // 'total_price': parseFloat(item.total_price).toFixed(2),
                      // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                           'price': '-',
                      'total_price': '-'
                    });
                  }
                  else if (el.store_type == "orderdesk") {
                    itemArray.push({
                      product_identifier: item?.id,
                      product_sku: item.sku ? item.sku : "-",
                      description: item?.name
                        ? item?.name
                        : "",
                      quantity: item.quantity,
                      // 'total_price': parseFloat(item.total_price).toFixed(2),
                      // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                      price: item?.price ? item?.price : "-",
                      total_price: item?.price * item?.quantity,
                    })
                  }
                })
              )

              setPcItems(itemArray)
              setPcExtraRow([])

              for (let i = itemArray.length; i <= 8; i++) {
                if (i < 8) {
                  setPcExtraRow(prevElements => [
                    ...prevElements,
                    <>
                      <View key={i} style={tableStyles.tableEmptyRow}></View>
                    </>,
                  ])
                } else {
                  setPcExtraRow(prevElements => [
                    ...prevElements,
                    <>
                      <View
                        key={i}
                        style={tableStyles.tableEmptyLastRow}
                      ></View>
                    </>,
                  ])
                }
              }

              setPcSubTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
              setPcTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
            } else {
              setPcItems([])
              setPcSubTotal("0.00")
              setPcTotal("0.00")
            }

            setTimeout(() => {
              document
                .getElementsByClassName("pdf-downloader-template-2")[0]
                .click()
            }, 1000)

            if (clickedSideBarJson.length == i + 1) {
              toast.remove(loading)
              toast.success(
                clickedSideBarJson.length +
                  ` packing ${
                    clickedSideBarJson.length > 1 ? "slips" : "slip"
                  } generated`
              )
              clickedSideBarJson = []
              uncheckAll()
              selectedOrders = []
              setSelectedOrdersCount(selectedOrders.length)
              clickedSideBarJson = []
              document.body.style.pointerEvents = "auto"
            }
          }, (i + 1) * 2000)
        })
      )
    } else {
      toast.error("Please select at least one order")
    }
  }

  const downloadMergedPackingSlip = async () => {
    console.log("downloadMergedPackingSlip")
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${
          clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      let dataArray = []

      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          let itemArray = []
          if (
            el.custom_items_data &&
            JSON.parse(
              el.store_type == "ebay"
                ? JSON.parse(el.custom_items_data)
                : el.custom_items_data
            ).length > 0
          ) {
            let items = JSON.parse(
              el.store_type == "ebay"
                ? JSON.parse(el.custom_items_data)
                : el.custom_items_data
            )
            items.map(item => {
              if (el.store_type == null) {
                itemArray.push({
                  product_identifier: item.product_identifier,
                  product_sku: "-",
                  description: item.description,
                  quantity: item.quantity,
                  price: parseFloat(item.total_value).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) * parseFloat(item.total_value)
                  ).toFixed(2),
                })
              } else if (el.store_type == "shopify") {
                itemArray.push({
                  product_identifier: item.id,
                  product_sku: "-",
                  description: item.name,
                  quantity: item.quantity,
                  price: parseFloat(item.price).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) * parseFloat(item.price)
                  ).toFixed(2),
                })
              } else if (el.store_type == "ebay") {
                itemArray.push({
                  product_identifier: item.lineItemId,
                  product_sku: "-",
                  description: item.title,
                  quantity: item.quantity,
                  price: parseFloat(item.lineItemCost.value).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) *
                      parseFloat(item.lineItemCost.value)
                  ).toFixed(2),
                })
              } else if (el.store_type == "walmart") {
                itemArray.push({
                  product_identifier: item.id,
                  product_sku: item.item.sku,
                  description: item.item.productName,
                  quantity: item.orderLineQuantity.amount,
                  price: parseFloat(
                    item.charges.charge[0].chargeAmount.amount
                  ).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.orderLineQuantity.amount) *
                    parseFloat(item.charges.charge[0].chargeAmount.amount)
                  ).toFixed(2),
                })
              } else if (el.store_type == "zenventory") {
                itemArray.push({
                  product_identifier: item.customerorderitemid,
                  product_sku: "-",
                  description: item.itemmaster.description,
                  quantity: item.quantity,
                  price: parseFloat(item.customerprice).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) * parseFloat(item.customerprice)
                  ).toFixed(2),
                })
              } else if (el.store_type == "sellercloud") {
                itemArray.push({
                  product_identifier: item.ProductID,
                  product_sku: "-",
                  description: item.DisplayName,
                  quantity: item.Qty,
                  price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)
                  ).toFixed(2),
                })
              } else if (el.store_type == "shipstation") {
                itemArray.push({
                  product_identifier: item.orderItemId,
                  product_sku: item.sku ? item.sku : "-",
                  description: item.name,
                  quantity: item.quantity,
                  price: parseFloat(item.unitPrice).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) * parseFloat(item.unitPrice)
                  ).toFixed(2),
                })
              } else if (el.store_type == "veeqo") {
                itemArray.push({
                  product_identifier: item.sellable.id,
                  product_sku: item.sellable.sku_code
                    ? item.sellable.sku_code
                    : "-",
                  description: item.sellable.product_title,
                  quantity: item.quantity,
                  price: parseFloat(item.sellable.price).toFixed(2),
                  total_price: parseFloat(
                    parseInt(item.quantity) * parseFloat(item.sellable.price)
                  ).toFixed(2),
                })
              }
              else if (el.store_type == "shiphero") {
                itemArray.push({
                  'product_identifier':'-',
                  'product_sku': item.sku ? item.sku : "-",
                  'description': item?.product_title?item?.product_title:"",
                  'quantity': item.quantity_allocated,
                  // 'price': parseFloat(item.total_price).toFixed(2),
                  // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                  price:'-',
                  total_price:"-"
                });
              }
              else if (el.store_type == "orderdesk") {
                itemArray.push({
                  product_identifier: item?.id,
                  product_sku: item.sku ? item.sku : "-",
                  description: item?.name? item?.name: "",
                  quantity: item.quantity,
                  price: item?.price ? item?.price : "-",
                  total_price: item?.price * item?.quantity,
                })
              }
            })
          }

          console.log("itemArray", itemArray)

          let storeOrderData = ""
          if (el.store_type) {
            let StoreOrder = JSON.parse(el.store_order_json)
            if (el.store_type == "shopify") {
              storeOrderData = moment(StoreOrder.created_at).format(
                "YYYY-MM-DD"
              )
            } else if (el.store_type == "ebay") {
              storeOrderData = moment(StoreOrder.updated_at).format(
                "YYYY-MM-DD"
              )
            } else if (el.store_type == "walmart") {
              storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
            } else if (el.store_type == "zenventory") {
              storeOrderData = moment(StoreOrder.ordereddate).format(
                "YYYY-MM-DD"
              )
            } else if (el.store_type == "sellercloud") {
              storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                "YYYY-MM-DD"
              )
            } else if (el.store_type == "shipstation") {
              storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                "YYYY-MM-DD"
              )
            } else if (el.store_type == "veeqo") {
              // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
              storeOrderData = moment(StoreOrder.created_at).format(
                "YYYY-MM-DD"
              )
              // console.log("storeOrderData",storeOrderData)
            }
            else if (el.store_type == "shiphero"){
              storeOrderData = moment(StoreOrder.order_history.created_at).format("YYYY-MM-DD")
            }
            else if (el.store_type == "orderdesk"){
              storeOrderData = moment(StoreOrder?.date_added)?.format("YYYY-MM-DD")
            }
          } else {
            storeOrderData = moment(el.order_date)
              .tz(moment.tz.guess())
              .format("YYYY-MM-DD")
          }

          dataArray.push({
            to_street1: el.to_street1,
            to_street2: el.to_street2,
            to_city: el.to_city,
            to_state: el.to_state,
            to_zip: el.to_zip,
            to_country: el.to_country,
            to_name: el.to_name,
            from_street1: el.from_street1,
            from_street2: el.from_street2,
            from_city: el.from_city,
            from_state: el.from_state,
            from_zip: el.from_zip,
            from_country: el.from_country,
            from_name: el.from_name,
            reference_number: el.reference_number,
            order_number: el.order_number
              ? el.order_number +
                (el.package_number
                  ? "-" +
                    el.package_number +
                    (el.package_index ? " (" + el.package_index + ")" : "")
                  : "")
              : "-",
            order_date: storeOrderData,
            shipment_date: moment(el.created_at)
              .tz(moment.tz.guess())
              .format("YYYY-MM-DD"),
            items: itemArray,
            sub_total: parseFloat(
              itemArray.reduce((accumulator, currentObject) => {
                return accumulator + parseFloat(currentObject.total_price)
              }, 0)
            ).toFixed(2),
            total: parseFloat(
              itemArray.reduce((accumulator, currentObject) => {
                return accumulator + parseFloat(currentObject.total_price)
              }, 0)
            ).toFixed(2),
          })
        })
      )

      setMergedPackingSlip(dataArray)

      setTimeout(() => {
        document.body.style.pointerEvents = "auto"
        toast.remove(loading)
        toast.success(
          dataArray.length +
            ` packing ${dataArray.length > 1 ? "slips" : "slip"} generated`
        )

        document
          .getElementsByClassName("merged-packing-slip-downloader")[0]
          .click()
        clickedSideBarJson = []
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        setMergedPackingSlip([])
      }, dataArray.length * 200)
    } else {
      toast.error("Please select at least one order")
    }
  }
  const downloadMergedPackingSlipTemplete2 = async () => {
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${
          clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      let dataArray = []

      await clickedSideBarJson.map(async (el, i) => {
        let itemArray = []
        if (
          el.custom_items_data &&
          JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          ).length > 0
        ) {
          let items = JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          )
          items.map(item => {
            if (el.store_type == null) {
              itemArray.push({
                product_identifier: item.product_identifier,
                product_sku: "-",
                description: item.description,
                quantity: item.quantity,
                price: parseFloat(item.total_value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.total_value)
                ).toFixed(2),
              })
            } else if (el.store_type == "shopify") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.price)
                ).toFixed(2),
              })
            } else if (el.store_type == "ebay") {
              itemArray.push({
                product_identifier: item.lineItemId,
                product_sku: "-",
                description: item.title,
                quantity: item.quantity,
                price: parseFloat(item.lineItemCost.value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.lineItemCost.value)
                ).toFixed(2),
              })
            } else if (el.store_type == "walmart") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: item.item.sku,
                description: item.item.productName,
                quantity: item.orderLineQuantity.amount,
                price: parseFloat(
                  item.charges.charge[0].chargeAmount.amount
                ).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.orderLineQuantity.amount) *
                  parseFloat(item.charges.charge[0].chargeAmount.amount)
                ).toFixed(2),
              })
            } else if (el.store_type == "zenventory") {
              itemArray.push({
                product_identifier: item.customerorderitemid,
                product_sku: "-",
                description: item.itemmaster.description,
                quantity: item.quantity,
                price: parseFloat(item.customerprice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.customerprice)
                ).toFixed(2),
              })
            } else if (el.store_type == "sellercloud") {
              // itemArray.push({
              //   'product_identifier': item.ProductID,
              //   'description': el.is_package==0?item.DisplayName:item.ItemName,
              //   'quantity': item.Qty,
              //   'price': parseFloat(el.is_package==0?item.AdjustedSitePrice:0).toFixed(2),
              //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.AdjustedSitePrice:0)).toFixed(2)
              // });

              itemArray.push({
                product_identifier: item.ProductID,
                product_sku: "-",
                description: item.DisplayName,
                quantity: item.Qty,
                price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.Qty) *
                    parseFloat(item.AdjustedSitePrice).toFixed(2)
                ),
              })
            } else if (el.store_type == "shipstation") {
              itemArray.push({
                product_identifier: item.orderItemId,
                product_sku: item.sku ? item.sku : "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.unitPrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.unitPrice)
                ).toFixed(2),
              })
            } else if (el.store_type == "veeqo") {
              itemArray.push({
                product_identifier: item.sellable.id,
                product_sku: item.sellable.sku_code
                  ? item.sellable.sku_code
                  : "-",
                description: item.sellable.product_title,
                quantity: item.quantity,
                price: parseFloat(item.sellable.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.sellable.price)
                ).toFixed(2),
              })
            }
            else if (el.store_type == "shiphero") {
              itemArray.push({
                'product_identifier': '-',
                'product_sku': item.sku ? item.sku : "-",
                'description': item?.product_title?item?.product_title:"",
                'quantity': item.quantity_allocated,
                // 'price': parseFloat(item.total_price).toFixed(2),
                // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                price:'-',
                total_price:'-'
              });
            }
            else if (el.store_type == "orderdesk") {
              itemArray.push({
                product_identifier: item?.id,
                product_sku: item.sku ? item.sku : "-",
                description: item?.name? item?.name: "",
                quantity: item.quantity,
                price: item?.price ? item?.price : "-",
                total_price: item?.price * item?.quantity,
              })
            }
          })
        }

        console.log("itemArray", itemArray)
        let storeOrderData = ""
        let bill_to_street1 = ""
        let bill_to_street2 = ""
        let bill_to_city = ""
        let bill_to_state = ""
        let bill_to_zip = ""
        let bill_to_country = ""
        if (el.store_type) {
          let StoreOrder = JSON.parse(el.store_order_json)
          if (el.store_type == "shopify") {
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
          } else if (el.store_type == "ebay") {
            storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
          } else if (el.store_type == "walmart") {
            storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
          } else if (el.store_type == "zenventory") {
            storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
          } else if (el.store_type == "sellercloud") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "shipstation") {
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")

            bill_to_street1 = StoreOrder.billTo.street1
            bill_to_street2 = StoreOrder.billTo.street2
            bill_to_city = StoreOrder.billTo.city
            bill_to_state = StoreOrder.billTo.state
            bill_to_zip = StoreOrder.billTo.postalCode
            bill_to_country = StoreOrder.billTo.country
          }
          else if (el.store_type == "veeqo") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.created_at).format(
              "YYYY-MM-DD"
            )
            // console.log("storeOrderData",storeOrderData)
          }
          else if (el.store_type == "shiphero"){
            storeOrderData = moment(StoreOrder.order_history.created_at).format("YYYY-MM-DD")
          }
          else if (el.store_type == "orderdesk"){
            storeOrderData = moment(StoreOrder?.date_added)?.format("YYYY-MM-DD")
          }
        } else {
          storeOrderData = moment(el.order_date)
            .tz(moment.tz.guess())
            .format("YYYY-MM-DD")
        }

        let extra_row = []

        for (let i = itemArray.length; i <= 8; i++) {
          if (i < 8) {
            extra_row.push(
              <>
                <View key={i} style={tableStyles.tableEmptyRow}></View>
              </>
            )
          } else {
            extra_row.push(
              <>
                <View key={i} style={tableStyles.tableEmptyLastRow}></View>
              </>
            )
          }
        }

        dataArray.push({
          to_street1: el.to_street1,
          to_street2: el.to_street2,
          to_city: el.to_city,
          to_state: el.to_state,
          to_zip: el.to_zip,
          to_country: el.to_country,
          to_name: el.to_name,
          bill_to_street1: bill_to_street1,
          bill_to_street2: bill_to_street2,
          bill_to_city: bill_to_city,
          bill_to_state: bill_to_state,
          bill_to_zip: bill_to_zip,
          bill_to_country: bill_to_country,
          from_street1: el.from_street1,
          from_street2: el.from_street2,
          from_city: el.from_city,
          from_state: el.from_state,
          from_zip: el.from_zip,
          from_country: el.from_country,
          from_name: el.from_name,
          reference_number: el.reference_number,
          order_number: el.order_number
            ? el.order_number +
              (el.package_number
                ? "-" +
                  el.package_number +
                  (el.package_index ? " (" + el.package_index + ")" : "")
                : "")
            : "-",
          // order_date: el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.order_date.toString().substring(0,10),
          order_date: storeOrderData,
          shipment_date: moment(el.created_at)
            .tz(moment.tz.guess())
            .format("YYYY-MM-DD"),
          items: itemArray,
          extra_row: extra_row,
          sub_total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
          total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
        })
      })

      let x = dataArray
      setMergedPackingSlipTemplete2([...x])
      document.body.click()
      console.log("x", x.length)
      setTimeout(() => {
        document.body.style.pointerEvents = "auto"
        toast.remove(loading)
        toast.success(
          x.length + ` packing ${x.length > 1 ? "slips" : "slip"} generated`
        )

        document
          .getElementsByClassName("merged-packing-slip-template2-downloader")[0]
          .click()
        clickedSideBarJson = []
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        setMergedPackingSlipTemplete2([])
      }, x.length * 200)
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handlePrint = e => {
    const pdfURL = "./ns_sxyYr1696075984-packing-slip.pdf"
    e.preventDefault()
    print(pdfURL)
  }

  async function mergePDFs(pdfBlobs) {
    const mergedPdf = await PDFDocument.create()

    for (const pdfBlob of pdfBlobs) {
      const pdfBytes = await pdfBlob.arrayBuffer()
      const pdf = await PDFDocument.load(pdfBytes)
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
      copiedPages.forEach(page => mergedPdf.addPage(page))
    }

    const mergedPdfBlob = await mergedPdf.save()

    return mergedPdfBlob
  }

  const downloadMergedPDF = async () => {
    if (selectedOrders.length > 0) {
      // console.log("selectedOrders",selectedOrders);
      pdfBlobs = []
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      let notExistingFilesCount = 0

      for (let i = 0; i < selectedOrders.length; i++) {
        // console.log("request to download",selectedOrders[i]);
        await axios
          .get(`${baseUrl()}/user/get-pdf/${selectedOrders[i]}`, {
            headers,
            responseType: "blob",
          })
          .then(async response => {
            // console.log("download",selectedOrders[i]);
            if (
              !selectedOrders[i].toString().includes(".zpl") &&
              !selectedOrders[i].toString().includes(".txt")
            ) {
              if (response.headers["file-name"]) {
                // console.log("response",response);
                const pdfBlob = new Blob([response.data], {
                  type: "application/pdf",
                })
                pdfBlobs.push(pdfBlob)
              } else {
                // console.log(selectedOrders[i],"response",response)
                notExistingFilesCount++
              }
            }
          })
          .catch(err => {
            // console.log("err",err);
          })
      }

      // await Promise.all(selectedOrders.map(async (el) => {
      //   console.log("request to download",el);
      //   await axios.get(`${baseUrl()}/user/get-pdf/${el}`, { headers, responseType: "blob" })
      //     .then(async (response) => {
      //       console.log("download",el);
      //       if(!el.toString().includes(".zpl")) {
      //         if (response && response.data.size > 90) {
      //           // console.log("response",response);
      //           const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      //           pdfBlobs.push(pdfBlob);
      //         }
      //         else {
      //           notExistingFilesCount++;
      //         }
      //       }
      //     }).catch((err) => {
      //     // console.log("err",err);
      //     });
      // })).then(async ()=> {
      // console.log("here we go")
      if (notExistingFilesCount > 0) {
        toast.error(notExistingFilesCount + " pdf file do not exits")
      }

      let order_ids = []
      for (let i = 0; i < selectedOrders.length; i++) {
        order_ids.push(
          filteredAllData.filter(el => el.pdf == selectedOrders[i])[0].order_id
        )
      }
      let payload = {
        order_ids: order_ids.toString(),
      }
      console.log("payload", payload)
      post("/user/mark-pdf-printed", payload, { headers })
        .then(async response => {
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          document.body.style.pointerEvents = "auto"
        })
        .catch(async errr => {
          console.log("error while change is printed status", errr)
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          await retrieveOrderCounts(fetchLastMonth)
          await retrieveOrdersCompleted(filterType, fetchLastMonth)
          document.getElementsByClassName(
            "select-all-checkbox"
          )[0].checked = false
          document.body.style.pointerEvents = "auto"
        })

      // uncheckAll()
      // selectedOrders = []
      // setSelectedOrdersCount(selectedOrders.length);
      // clickedSideBarJson = [];
      // await retrieveOrderCounts(fetchLastMonth);
      // await retrieveOrdersCompleted(filterType, fetchLastMonth)
      // document.getElementsByClassName(
      //   "select-all-checkbox"
      // )[0].checked = false
      // document.body.style.pointerEvents = "auto"
      toast.remove(loading)
      let loading1 = toast.loading("Downloading orders...")
      const mergedBlob = await mergePDFs(pdfBlobs)
      const blob = new Blob([mergedBlob], { type: "application/pdf" })

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob)

      // Create a temporary link to trigger the download
      const a = document.createElement("a")
      a.href = url
      a.download = "merged-labels.pdf"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()

      // Clean up
      window.URL.revokeObjectURL(url)
      toast.remove(loading1)
      // const zip = new JSZip();
      // await zip.file(mergedBlob)
      // zip.generateAsync({ type: "blob" }).then(function (content) {
      //   toast.remove(loading1)
      //   saveAs(content, "orders.zip");
      // });
      // })
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  createTheme(
    "solarized",
    {
      text: {
        primary: "#fffff",
        secondary: "#fffff",
      },
      background: {
        default: "#0f0f0f",
      },
      context: {
        background: "#cb4b16",
        text: "#0f0f0f",
      },
      divider: {
        default: "#282828",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  )

  const handleDirectPrintZPL = async () => {
    if (selectedOrders.length > 0) {
      let zplFiles = selectedOrders.filter(el => el.toString().includes(".zpl"))
      if (zplFiles.length > 0) {
        document.body.style.pointerEvents = "none"
        for (let i = 0; i < selectedOrders.length; i++) {
          // console.log("request to download",selectedOrders[i]);
          await axios
            .get(`${baseUrl()}/user/get-pdf/${selectedOrders[i]}`, {
              headers,
              responseType: "text/plain",
            })
            .then(async response => {
              // console.log("download",selectedOrders[i]);
              if (selectedOrders[i].toString().includes(".zpl")) {
                // const zplBlob = new Blob([response.data], { type: 'text/plain' });
                console.log("zplBlob", response)
                await printZpl(response.data)
              }
            })
            .catch(err => {
              // console.log("err",err);
            })
        }

        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        document.getElementsByClassName(
          "select-all-checkbox"
        )[0].checked = false
        document.body.style.pointerEvents = "auto"
      } else {
        toast.error("Selected order do not contain any ZPL file")
        document.body.style.pointerEvents = "auto"
        document.getElementsByClassName(
          "select-all-checkbox"
        )[0].checked = false
      }
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  const handleHidePrinted = e => {
    const { checked } = e.target
    console.log("checkedchecked", checked)
    setPending(true)
    if (checked) {
      setHidePrinted(true)
      let data = filteredAllData.filter(el => el.is_printed == 0)
      setFilteredData(data)
      setTableData({ columns, data })
    } else {
      setHidePrinted(false)
      let data = filteredAllData
      setFilteredData(data)
      setTableData({ columns, data })
    }

    setPending(false)
  }

  //meta title
  document.title = `Completed Orders | ${config.APP_DETAILS.APP_NAME}`

  return (
    <div className="page-content" style={{ position: "relative" }}>
      <span
        style={{
          fontSize: "12px",
          fontWeight: "normal",
          position: "fixed",
          bottom: "20px",
          boxShadow: "0px 0px 4px 5px rgba(0,0,0,0.05)",
          right: "0px",
          padding: "5px",
          backgroundColor: "white",
          zIndex: "999",
          display: selectedOrdersCount > 0 ? "block" : "none",
        }}
      >
        {selectedOrdersCount} {selectedOrdersCount > 1 ? "Orders" : "Order"}{" "}
        Selected{" "}
      </span>
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Completed Orders" />
        {/* <Table columns={columns} data={data} /> */}
        <Card style={{ marginBottom: windowWidth <= 600 ? "30px" : "20px" }}>
          <CardBody
          // style={{textAlign:"right"}}
          >
            <PDFDownloadLink
              className={"packing-slip-downloader"}
              document={
                <PackingSlip
                  pc_from_name={pc_from_name}
                  pc_from_street1={pc_from_street1}
                  pc_from_street2={pc_from_street2}
                  pc_from_city={pc_from_city}
                  pc_from_state={pc_from_state}
                  pc_from_zip={pc_from_zip}
                  pc_from_country={pc_from_country}
                  pc_to_street1={pc_to_street1}
                  pc_to_street2={pc_to_street2}
                  pc_to_city={pc_to_city}
                  pc_to_state={pc_to_state}
                  pc_to_zip={pc_to_zip}
                  pc_to_country={pc_to_country}
                  pc_order_number={pc_order_number}
                  pc_order_date={pc_order_date}
                  pc_to_name={pc_to_name}
                  pc_items={pc_items}
                  pc_sub_total={pc_sub_total}
                  pc_total={pc_total}
                />
              }
              style={{ display: "none" }}
              fileName={`${slip_name}-packing-slip.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"pdf-downloader-template-2"}
              document={
                <PackingSlipTemplate2
                  pc_to_street1={pc_to_street1}
                  pc_to_street2={pc_to_street2}
                  pc_to_city={pc_to_city}
                  pc_to_state={pc_to_state}
                  pc_to_zip={pc_to_zip}
                  pc_to_country={pc_to_country}
                  pc_order_number={pc_order_number}
                  pc_reference_number={pc_reference_number}
                  pc_order_date={pc_order_date}
                  pc_to_name={pc_to_name}
                  pc_bill_to_street1={pc_bill_to_street1}
                  pc_bill_to_street2={pc_bill_to_street2}
                  pc_bill_to_city={pc_bill_to_city}
                  pc_bill_to_state={pc_bill_to_state}
                  pc_bill_to_zip={pc_bill_to_zip}
                  pc_bill_to_country={pc_bill_to_country}
                  pc_items={pc_items}
                  pc_extra_row={pc_extra_row}
                  pc_from_name={pc_from_name}
                  pc_from_street1={pc_from_street1}
                  pc_from_street2={pc_from_street2}
                  pc_from_city={pc_from_city}
                  pc_from_state={pc_from_state}
                  pc_from_zip={pc_from_zip}
                />
              }
              style={{ display: "none" }}
              fileName={`${slip_name}-packing-slip.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"merged-packing-slip-downloader"}
              document={
                <MergedPackingSlip mergedPackingSlip={mergedPackingSlip} />
              }
              style={{ display: "none" }}
              fileName={`merged-packing-slips.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"merged-packing-slip-template2-downloader"}
              document={
                <MergedPackingSlipTemplate2
                  mergedPackingSlipTemplete2={mergedPackingSlipTemplete2}
                />
              }
              style={{ display: "none" }}
              fileName={`merged-packing-slips.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            {/*<button className={"btn btn-danger"}*/}
            {/*        style={{marginRight:"10px"}} onClick={handlePrint}>Silent Print</button>*/}
            <button
              className={"btn btn-primary"}
              style={{ marginRight: "10px" }}
              disabled={selectedOrdersCount == 0}
              onClick={handleDownload}
            >
              Download Labels
            </button>
            <ButtonDropdown
              isOpen={merge_dropdown}
              toggle={() => {
                setMergeDropdown(!merge_dropdown)
              }}
              disabled={clickedSideBarJson.length == 0}
              style={{
                opacity: clickedSideBarJson.length == 0 ? "0.7" : "1",
                marginRight: "10px",
                marginLeft: "-5px",
              }}
            >
              <DropdownToggle
                className={"btn btn-light waves-effect waves-light m-1"}
                caret
                color="light"
              >
                Merge & Download <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={downloadMergedPDF}>Labels</DropdownItem>
                {JSON.parse(localStorage.getItem("authUser")).id == 572 ? (
                  <DropdownItem onClick={downloadMergedPackingSlipTemplete2}>
                    Packing Slips
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={downloadMergedPackingSlip}>
                    Packing Slips
                  </DropdownItem>
                )}

                <DropdownItem onClick={handleMergedCombineLabelAndPackingSlip}>
                  Label & Packing Slip
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            {/*<button className={"btn btn-light"}*/}
            {/*        disabled={selectedOrdersCount==0}*/}
            {/*        style={{marginLeft:"10px"}} onClick={downloadMergedPDF}>Merge & Download Labels</button>*/}
            {/*<button className={"btn btn-light"}*/}
            {/*        disabled={selectedOrdersCount==0}*/}
            {/*        style={{marginLeft:"10px"}} onClick={downloadMergedPackingSlip}>Merge & Download Packing Slip</button>*/}
            <button
              className={"btn btn-light"}
              style={{ marginRight: "10px" }}
              disabled={selectedOrdersCount == 0}
              onClick={handleBulkCancel}
            >
              Bulk Cancel
            </button>
            {/*<ButtonDropdown*/}
            {/*  isOpen={combine_dropdown}*/}
            {/*  toggle={() => {*/}
            {/*    setCombineDropdown(!combine_dropdown);*/}
            {/*  }}*/}
            {/*  disabled={clickedSideBarJson.length==0}*/}
            {/*  style={{opacity:(clickedSideBarJson.length==0?'0.7':'1'),marginRight:"10px",marginLeft:"-5px"}}*/}
            {/*>*/}
            {/*  <DropdownToggle className={"btn btn-light waves-effect waves-light m-1"} caret color="light">*/}
            {/*    Combined Download <i className="bx bx-chevron-down"></i>*/}
            {/*  </DropdownToggle>*/}
            {/*  <DropdownMenu>*/}
            {/*    <DropdownItem onClick={handleCombineLabelAndPackingSlip}>Label & Packing Slip</DropdownItem>*/}
            {/*    <DropdownItem onClick={handleMergedCombineLabelAndPackingSlip}>Merged Label & Packing Slip</DropdownItem>*/}
            {/*  </DropdownMenu>*/}
            {/*</ButtonDropdown>*/}
            <ButtonDropdown
              isOpen={print_dropdown}
              toggle={() => {
                setPrintDropdown(!print_dropdown)
              }}
              disabled={clickedSideBarJson.length == 0}
              style={{
                opacity: clickedSideBarJson.length == 0 ? "0.7" : "1",
                marginRight: "10px",
                marginLeft: "-5px",
              }}
            >
              <DropdownToggle
                className={"btn btn-light waves-effect waves-light m-1"}
                caret
                color="light"
              >
                Print <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {JSON.parse(localStorage.getItem("authUser")).id == 572 ? (
                  <DropdownItem onClick={handlePackingSlipTemplate2}>
                    Packing Slip
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={handlePackingSlip}>
                    Packing Slip
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
            {/*<button className={"btn btn-light"} onClick={handleDirectPrintZPL}>Direct Print ZPL</button>*/}
          </CardBody>
        </Card>
        <div
          style={{
            width: "fit-content",
            background:
              localStorage.getItem("theme") == "dark" ? "#222224" : "#E1E5E8",
          }}
          className="rounded-top"
        >
          {/* <button*/}
          {/*  className={*/}
          {/*    "btn btn-md rounded-top " +*/}
          {/*    (filterType === "FedEx" ? " text-dark" : " text-secondary")*/}
          {/*  }*/}
          {/*  // disabled={loadingOrders}*/}
          {/*  style={{*/}
          {/*    marginLeft: "0px",*/}
          {/*    marginRight: "0px",*/}
          {/*    borderRadius: "4px 4px 0px 0px",*/}
          {/*    borderColor: "rgba(0,0,0,0)",*/}
          {/*    backgroundColor:*/}
          {/*      filterType == "FedEx"*/}
          {/*        ? localStorage.getItem("theme") == "dark"*/}
          {/*          ? "#0f0f0f"*/}
          {/*          : "white"*/}
          {/*        : localStorage.getItem("theme") == "dark"*/}
          {/*        ? "#222224"*/}
          {/*        : "#E1E5E8",*/}
          {/*  }}*/}
          {/*  onClick={handleFedExOrders}*/}
          {/*  disabled={pending}*/}
          {/*>*/}
          {/*  FedEx ({fedexOrderCount})*/}
          {/*</button>*/}
          {/* <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "UPS" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "4px 4px 0px 0px",
              borderColor: "rgba(0,0,0,0)",
              backgroundColor:
                filterType == "UPS"
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                  ? "#222224"
                  : "#E1E5E8",
            }}
            onClick={handleUPSOrders}
            disabled={pending}
          >
            UPS ({upsOrderCount})
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "UPSv2" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "4px 4px 0px 0px",
              borderColor: "rgba(0,0,0,0)",
              backgroundColor:
                filterType == "UPSv2"
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                  ? "#222224"
                  : "#E1E5E8",
            }}
            onClick={handleUPSv2Orders}
            disabled={pending}
          >
            UPSv2 ({upsv2OrderCount})
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "DHL" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "4px 4px 0px 0px",
              borderColor: "rgba(0,0,0,0)",
              backgroundColor:
                filterType == "DHL"
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                  ? "#222224"
                  : "#E1E5E8",
            }}
            onClick={handleDHLOrders}
            disabled={pending}
          >
            DHL ({dhlOrderCount})
          </button> */}
          <button
            className={
              "btn btn-md  " +
              (filterType === "USPS" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "4px 4px 0px 0px",
              borderColor: "rgba(0,0,0,0)",
              backgroundColor:
                filterType == "USPS"
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                  ? "#222224"
                  : "#E1E5E8",
            }}
            onClick={handleUSPSOrders}
            disabled={pending}
          >
            USPS ({uspsOrderCount})
          </button>
          {/* <button className={"btn btn-md  " +(filterType === "UPSv5" ? " text-dark" : " text-secondary")} style={{marginLeft: "0px",marginRight: "0px",borderRadius: "4px 4px 0px 0px",borderColor: "rgba(0,0,0,0)", backgroundColor:filterType == "UPSv5"? localStorage.getItem("theme") == "dark"? "#0f0f0f": "white": localStorage.getItem("theme") == "dark"? "#222224": "#E1E5E8",}} onClick={handleUPSv5Orders} disabled={pending}>UPSv5 ({upsv5OrderCount})</button> */}
        </div>
        <div style={{ position: "relative" }}>
          <label
            htmlFor={"showsidebar"}
            style={{
              position: "absolute",
              width: "fit-content",
              right: "0px",
              top:
                windowWidth <= 800
                  ? windowWidth <= 600
                    ? windowWidth <= 400
                      ? "-102px"
                      : "-68px"
                    : "-54px"
                  : "-20px",
              fontWeight: "500",
              fontSize: "12px",
              zIndex: "99",
            }}
          >
            <input
              type={"checkbox"}
              id={"showsidebar"}
              defaultChecked={fetchLastMonth}
              onClick={async e => {
                let { checked } = e.target
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                if (checked) {
                  setFetchLastMonth(true)
                  await retrieveOrderCounts(true)
                  await retrieveOrdersCompleted(filterType, true)
                } else {
                  setFetchLastMonth(false)
                  await retrieveOrderCounts(false)
                  await retrieveOrdersCompleted(filterType, false)
                }
              }}
            />{" "}
            <span>Fetch Last Month Records</span>
          </label>
          <label
            htmlFor={"show"}
            style={{
              position: "absolute",
              width: "fit-content",
              right: "180px",
              top:
                windowWidth <= 800
                  ? windowWidth <= 600
                    ? windowWidth <= 400
                      ? "-102px"
                      : "-68px"
                    : "-54px"
                  : "-20px",
              fontWeight: "500",
              fontSize: "12px",
              zIndex: "99",
            }}
          >
            <input type={"checkbox"} onClick={handleHidePrinted} id={"show"} />{" "}
            Hide Printed (
            {filteredAllData.filter(el => el.is_printed == 1).length})
          </label>
        </div>
        <Card style={{ borderRadius: "0px 3px 3px 3px" }}>
          <CardBody>
            {/*<h5 style={{ marginBottom: "-50px" }}>{filterType} Orders<br/>*/}
            {/*  <span style={{fontSize:"12px",fontWeight:"normal",marginTop:"-10px !important"}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>*/}
            {/*</h5>*/}
            <PDFDownloadLink
              className={"pdf-downloader"}
              document={<MyDocument />}
              style={{ display: "none" }}
              fileName={`${
                invoiceData ? invoiceData.order_unique_id : "order"
              }.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>

            <div
              className={"row"}
              style={{
                marginBottom: windowWidth > 1500 ? "-46px" : "10px",
                display: "flex",
              }}
            >
              <div
                className={"custom-filter"}
                style={{
                  fontSize: "14px",
                  color:
                    localStorage.getItem("theme") == "dark"
                      ? "#a6b0c4"
                      : "black",
                }}
              >
                Filter By:
              </div>
              {stores.length > 0 ? (
                <CustomFilters
                  title={"Store"}
                  options={stores}
                  change={handleStoreSelect}
                />
              ) : null}
              <CustomFilters
                title={"Destination"}
                options={[
                  { name: "All", value: "All" },
                  { name: "International", value: "International" },
                  { name: "Domestic", value: "Domestic" },
                ]}
                change={handleFilterShipment}
              />
              <CustomFilters
                title={"Assignee"}
                options={[
                  { name: "Remove Filter", value: "none" },
                  { name: "Unassigned", value: "Unassigned" },
                ]}
                change={handleAssignee}
              />
              {tagsForFilter.length > 1 ? (
                <CustomFilters
                  title={"Tag"}
                  options={tagsForFilter}
                  change={handleSortTag}
                />
              ) : null}
              {picklists.length > 1 ? (
                <CustomMultiselectFilters
                  title={"Picklist"}
                  options={picklists}
                  change={handleSortPicklist}
                />
              ) : null}
              <CustomFilters
                title={"Allocation"}
                options={[
                  { name: "Remove Filter", value: "none" },
                  { name: "Unallocated", value: "Unallocated" },
                ]}
                change={handleAllocation}
              />
              <CustomFilters
                title={"Order Date"}
                options={[
                  { name: "Remove Filter", value: "none" },
                  { name: "Today", value: "Today" },
                  { name: "Yesterday", value: "Yesterday" },
                  { name: "Last Week", value: "Last Week" },
                  { name: "Last Month", value: "Last Month" },
                  { name: "Last Year", value: "Last Year" },
                ]}
                change={handleOrderDate}
                // selectedValue={orderDate}
              />
              {/*<CustomFilters*/}
              {/*  title={"Ship To Name"}*/}
              {/*  options={[{"name":"None","value":null},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
              {/*  change={handleSortName}*/}
              {/*/>*/}
              {/*<CustomFilters*/}
              {/*  title={"Weight"}*/}
              {/*  options={[{"name":"Remove Filter","value":"none"},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
              {/*  change={handleSortWeight}*/}
              {/*/>*/}
              <CustomFilters
                title={"Other"}
                options={[
                  { name: "Remove Filter", value: "none" },
                  { name: "Other", value: "Other" },
                ]}
                change={handleAllocation}
              />
              <CustomFilters
                title={"Save Filter"}
                options={[
                  { name: "Remove Filter", value: "none" },
                  { name: "Save Filter", value: "Save Filter" },
                ]}
                change={handleAllocation}
              />

              {/*<CustomFilters*/}
              {/*  title={"Item Quantity"}*/}
              {/*  options={[{"name":"None","value":"none"},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
              {/*  change={handleSortQuantity}*/}
              {/*/>*/}

              {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
              {/*  <label>*/}
              {/*    Destination*/}
              {/*  </label>*/}
              {/*  <select className={"form-select"} value={filterShipment} onChange={handleFilterShipment}>*/}
              {/*    /!*<option disabled={true} selected={true}>Select Filter</option>*!/*/}
              {/*    <option value="All">All</option>*/}
              {/*    <option value="International">International</option>*/}
              {/*    <option value="Domestic">Domestic</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
              {/*  <label>*/}
              {/*    Ship To Name*/}
              {/*  </label>*/}
              {/*  <select className={"form-select"} value={nameSort} onChange={handleSortName}>*/}
              {/*    <option selected={true} value={""}>None</option>*/}
              {/*    <option value="ASC">Ascending</option>*/}
              {/*    <option value="DESC">Descending</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
              {/*  <label>*/}
              {/*    Shipment Weight*/}
              {/*  </label>*/}
              {/*  <select className={"form-select"} value={weightSort} onChange={handleSortWeight}>*/}
              {/*    <option selected={true} value={""}>None</option>*/}
              {/*    <option value="ASC">Ascending</option>*/}
              {/*    <option value="DESC">Descending</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
              {/*  <label>*/}
              {/*    Items Quantity*/}
              {/*  </label>*/}
              {/*  <select className={"form-select"} value={quantitySort} onChange={handleSortQuantity}>*/}
              {/*    <option selected={true} value={""}>None</option>*/}
              {/*    <option value="ASC">Ascending</option>*/}
              {/*    <option value="DESC">Descending</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
            </div>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Completed Orders...`}
            >
              <DataTable
                columns={columns}
                data={filteredData}
                pagination={50}
                progressPending={pending}
                progressComponent={
                  <h4 className={"mt-5 mb-5 text-center"}>
                    Loading data, this may take a few moments...
                  </h4>
                }
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
                // onChangePage={handleOnPageChange}
                onRowClicked={handleRowClick}
                expandableRows={true}
                expandableRowsComponent={expandableRows}
                expandableRowDisabled={row =>
                  row.custom_items_data &&
                  JSON.parse(row.custom_items_data).length > 0
                    ? false
                    : true
                }
                className={"order-table"}
                theme={
                  localStorage.getItem("theme") == "dark" ? "solarized" : null
                }
                customStyles={
                  localStorage.getItem("theme") == "dark"
                    ? customDarkStyles
                    : customStyles
                }
              />
            </DataTableExtensions>
            {/*{!dataCompleted && !pending && !loading && parseFloat(filteredData.length) != parseFloat(totalCount)?<div className={"col-md-12 text-center mt-2"}><button onClick={handleLoadMore} className={"btn btn-dark"}>Load More</button></div>:null}*/}
            {/*{loading?<h4 className={"text-center"}><img src={defaultLoader} className={"mt-3 mb-3"} width={"40px"} /></h4>:null}*/}
            {/*<p style={{textAlign:"right",marginTop:"10px"}}>Showing <b>{filteredData.length}</b> out of <b>{totalCount}</b></p>*/}
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

        {/*{invoiceData?*/}
        <div className={"invoice"} id={"invoice"}>
          <div className={"invoice_header"}>
            <div className={"header_info"}>
              <h2>{config.APP_DETAILS.APP_NAME} LLC</h2>
              <h5>
                2093 PHILADELPHIA PIKE, Suite 3944
                <br />
                CLAYMONT, DE 19703
                <br />
                UNITED STATES
              </h5>
              <h5>{config.APP_DETAILS.SUPPORT_EMAIL}</h5>
            </div>
            <div className={"header_image"}>
              <img src={logo} />
            </div>
          </div>
          <hr />
          <h1 className={"text-center"}>RECEIPT</h1>
          <br />
          <table className={"info_table"}>
            <tr>
              <th>
                <h5>Account:</h5>
              </th>
              <td>
                <h5>{invoiceData ? invoiceData.email : "email"}</h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Name:</h5>
              </th>
              <td>
                <h5>
                  {invoiceData ? invoiceData.first_name : "-"}{" "}
                  {invoiceData ? invoiceData.last_name : "-"}
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Date:</h5>
              </th>
              <td>
                <h5>
                  {moment(invoiceData ? invoiceData.date : new Date()).format(
                    "MMM DD, YYYY"
                  )}
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Service Fee:</h5>
              </th>
              <td>
                <h5>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                  )}{" "}
                  USD
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Order ID:</h5>
              </th>
              <td>
                <h5>{invoiceData ? invoiceData.order_unique_id : "-"}</h5>
              </td>
            </tr>
          </table>
          <br />
          <table
            className={
              "info_table table_description table table-bordered color-dark"
            }
          >
            <thead>
              <tr className={"table-dark"}>
                <th>
                  <h5>Description</h5>
                </th>
                <th>
                  <h5>Amount</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h6>
                    {invoiceData ? invoiceData.carrier_name : "-"} -{" "}
                    {invoiceData ? invoiceData.service_name : "-"}
                  </h6>
                </td>
                <td>
                  <h6>
                    $
                    {amountFormat(
                      parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                    )}{" "}
                    USD
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*:null}*/}

        {/*Item name - product ids modal*/}
        <Modal
          isOpen={orderItemInfoModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderItemInfoModal(!orderItemInfoModal)
            setOrderItemType("")
            setOrderItemData("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderItemInfoModal(!orderItemInfoModal)
                setOrderItemType("")
                setOrderItemData("")
              }}
            >
              {orderItemType}
            </ModalHeader>
            <ModalBody>{orderItemData}</ModalBody>
          </div>
        </Modal>
        {/*Item name - product ids modal*/}

        {/*Duplicate order modal*/}
        <Modal
          isOpen={duplicateOrderModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setDuplicateOrderModal(!duplicateOrderModal)
            setDuplicateOrderId("")
            setDuplicateOrderUniqueId("")
            setDuplicateOrderCount("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setDuplicateOrderModal(!duplicateOrderModal)
                setDuplicateOrderId("")
                setDuplicateOrderUniqueId("")
                setDuplicateOrderCount("")
              }}
            >
              Duplicate Order {duplicateOrderUniqueId}
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmitDuplicateOrder}>
                <Label className="form-label">Duplication Count</Label>
                <Input
                  name="duplication_count"
                  // value={name}
                  className="form-control"
                  onWheel={e => e.target.blur()}
                  placeholder="Enter Duplication Count"
                  type="number"
                  min="1"
                  max="15"
                  required={true}
                  onChange={e => setDuplicateOrderCount(e.target.value)}
                  value={duplicateOrderCount}
                />
                <br />
                <button
                  type={"submit"}
                  className={"btn btn-success btn-sm"}
                  style={{ float: "right" }}
                >
                  Save
                </button>
                <br />
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Duplicate order modal*/}
      </div>
    </div>
  )
}
OrdersCompleted.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default OrdersCompleted
