import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Avatar from '../../assets/images/Avatar.png';
import Rating from '../../assets/images/Rating.png';

const TestimonialCompVideo = ({ url }) => {
  const isYouTube = url.includes("youtube.com") || url.includes("youtu.be");

  return (
    <div className="position-relative  rounded-3 my-2 min-w-100 h-100 overflow-hidden" style={{ height: '400px', borderRadius: "20px", backgroundColor: "transparent" }}>
      {/* Background Video or YouTube Embed */}
      {isYouTube ? (
        <iframe
          src={`https://www.youtube.com/embed/${url.split('v=')[1]}?rel=0&showinfo=0`}
          loading="lazy"
          allowFullScreen
          className="position-absolute top-0 start-0 w-100 h-100  "
          style={{ objectFit: 'cover', borderRadius: "20px" }}

        />
      ) : (
        <video
          src={url}
          loading="lazy"
          controls
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ objectFit: 'cover' }}
        />
      )}

      {/* Content */}
      {/* <CardBody className="d-flex flex-column p-4 gap-3 position-relative z-10">
        <Row className="align-items-center">
          <Col xs="auto" className="pe-2">
            <div className="rounded-circle bg-light d-flex align-items-center justify-content-center overflow-hidden" style={{ width: '48px', height: '48px' }}>
              <img
                src={Avatar}
                alt="Avatar"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column text-capitalize">
              <span>Melissa</span>
              <span className="text-muted small">Jan 11, 2024</span>
            </div>
            <div className="d-flex align-items-center">
              <img
                src={Rating}
                alt="Rating"
                style={{ width: '70px', height: '14px' }}
                className="object-fit-cover"
              />
            </div>
          </Col>
        </Row>
      </CardBody> */}
    </div>
  );
};

export default TestimonialCompVideo;

