const base64ToFile = (base64String, fileName) => {
    // Split the base64 string into parts
    const [header, base64Data] = base64String.split(',');
    const mimeType = header.match(/:(.*?);/)[1]; // Extract the MIME type

    // Convert base64 to raw binary data
    const byteString = atob(base64Data);
    const arrayBuffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        arrayBuffer[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Create and return a File from the Blob
    return new File([blob], fileName, { type: mimeType });
};
const cleanFileName = (fileName) => {
    return fileName
        .replace(/[^a-zA-Z0-9.]/g, '_') // Replace invalid characters with underscores
        .replace(/__+/g, '_')           // Replace multiple underscores with a single underscore
        .replace(/^_|_$/g, '')          // Remove leading and trailing underscores
        .trim();                        // Trim whitespace
};


export { base64ToFile, cleanFileName }