import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import ShippingAvatar from '../../assets/images/shipping-avatar.svg'
import StatsImage from '../../assets/images/stats.png'
import DollarImage from '../../assets/images/dollar.png'
import ConnectedStoresImage from '../../assets/images/connected-stores.png'
import TicketsImage from '../../assets/images/tickets.png'
import officeBag from '../../assets/images/office-bag.png'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { get, post } from "../../helpers/api_helper"
import { Line } from "react-chartjs-2"
import moment from "moment-timezone"
import fedex from "../../assets/images/fedex.png"
import ups from "../../assets/images/ups-logo.png"
import usps from "../../assets/images/usps-logo.png"
import dhl from "../../assets/images/dhl-logo.png"
import loader from "../../assets/images/loader.gif"
import { amountFormat } from "../../assets/js/numberFormatter"
import config from "../../config"

const Dashboard = props => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const [reports, setReport] = useState([])
  const [numberOfDays, setNumberOfDays] = useState(1)

  const [mainLoading, setMainLoading] = useState(true)

  const [selectedView, setSelectedView] = useState("day")
  const [selectedCategory, setSelectedCategory] = useState("label")
  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState("all")

  const [dataLoading, setDataLoading] = useState(true)
  // let reports = [];

  const [lastThirtyDays, setLastThirtyDays] = useState([])
  const [orders, setOrders] = useState(0)

  const [loadGraph, setLoadGraph] = useState(false);

  const handleChart = (numberOfDays, carrier) => {
    setDataLoading(true)
    setNumberOfDays(numberOfDays)
    // console.log("carrier",carrier);
    if (JSON.parse(localStorage.getItem("authUser")).role_id === 1) {
      // let arr = [];
      retrieveOrders()
        .then(apiResponse => {
          let arr = []
          if (apiResponse.length > 0) {
            let response = []
            if (carrier !== "all") {
              response = apiResponse.filter(
                el => el.carrier_name == carrier
              )
            } else {
              response = apiResponse
            }

            if (numberOfDays === "day") {
              let currentDate = new Date()
              // let startOfYear = new Date(currentDate.getFullYear(), 0, 1);
              let startOfYear = moment(currentDate).tz(moment.tz.guess())
                .subtract(1, "month")
                .toDate()
              let diff = currentDate - startOfYear
              let diffInDays = diff / (1000 * 60 * 60 * 24)

              for (let i = 0; i <= diffInDays; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "day").format("DD-MMM"),
                  value: 0,
                  price: 0,
                })
              }

              // console.log("diffInDays", Math.round(diffInDays))
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date === moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
                // else {
                //   arr.push({"date": moment(response[i].created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY"), "value": 1});
                // }
              }
            }
            else if (numberOfDays === "month") {
              let currentDate = new Date()
              let startOfYear = new Date(currentDate.getFullYear(), 0, 1)
              let currentMonth = parseInt(moment(new Date()).tz(moment.tz.guess()).format("MM"))
              for (let i = 0; i < currentMonth; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "month").format("MMM-YYYY"),
                  value: 0,
                  price: 0,
                })
              }
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date ===
                    moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
              }
            }

            // console.log("arr", arr)
            // console.log("response", response)
          }
          return arr
        })
        .then(arr => {
          // console.log("setLastThirtyDays", arr)
          setDataLoading(false)
          setLastThirtyDays(arr)
        })
    } else if (JSON.parse(localStorage.getItem("authUser")).role_id === 2) {
      setLoadGraph(true)
      retrieveOrdersCompleted(numberOfDays)
        .then(apiResponse => {
          let arr = []
          if (apiResponse.length > 0) {
            let response = []
            if (carrier !== "all") {
              response = apiResponse.filter(
                el => el.carrier_name == carrier
              )
            } else {
              response = apiResponse
            }

            response.sort(function (a, b) {
              return new Date(a.created_at) - new Date(b.created_at)
            })

            if (numberOfDays === "day") {
              let currentDate = new Date()
              // let startOfYear = new Date(currentDate.getFullYear(), 0, 1);
              let startOfYear = moment(currentDate).tz(moment.tz.guess())
                .subtract(1, "month")
                .toDate()
              let diff = currentDate - startOfYear
              let diffInDays = diff / (1000 * 60 * 60 * 24)

              for (let i = 0; i <= diffInDays; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "day").format("DD-MMM"),
                  value: 0,
                  price: 0,
                })
              }

              // console.log("diffInDays", Math.round(diffInDays))
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date === moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
                // else {
                //   arr.push({"date": moment(response[i].created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY"), "value": 1});
                // }
              }
            } else if (numberOfDays === "month") {
              let currentDate = new Date()
              let startOfYear = new Date(currentDate.getFullYear(), 0, 1)
              let currentMonth = parseInt(moment(new Date()).tz(moment.tz.guess()).format("MM"))
              for (let i = 0; i < currentMonth; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "month").format("MMM-YYYY"),
                  value: 0,
                  price: 0,
                })
              }
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date ===
                    moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
              }
            }

            // console.log("arr", arr)
            // console.log("response", response)
          }
          return arr
        })
        .then(arr => {
          setDataLoading(false)
          setLastThirtyDays(arr)
        })

      // let arr = [];
      // retrieveOrdersCompleted().then((response) => {
      //   // console.log("responseresponse", response);
      //

      //   // console.log("sorted responseresponse", response);
      //   for (let i = 0; i <= numberOfDays; i++) {
      //     let date = moment(new Date()).tz(moment.tz.guess()).subtract(i, 'day').format("DD-MMM-YYYY");
      //     let filtered = response.filter(el => moment(el.created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY") === date).length;
      //     arr.push({ "date": date, "value": filtered });
      //   }
      // }).then(() => {
      //   // console.log("setLastThirtyDays", arr);
      //   setLastThirtyDays(arr);
      // });
    }
  }


  useEffect(() => {
    setMainLoading(true)
    retrieveCarriers()
    if (JSON.parse(localStorage.getItem("authUser")).role_id === 1) {
      let arr = []
      get("/admin/get-dashboard-data", { headers }).then(response => {
        if (response.status === 200) {
          // setUsers(response.users);
          // setCarrierIds(response.carrierIds);
          // setTickets(response.tickets);
          // setServices(response.services);

          setReport([
            {
              title: "Total Users",
              iconClass: "bxs-group",
              description: response.users,
            },
            {
              title: "Total Services",
              iconClass: "bxs-envelope",
              description: response.services,
            },
            {
              title: "Active Carrier Ids",
              iconClass: "bxs-truck",
              description: response.carrierIds.length,
            },
            {
              title: "Open Tickets",
              iconClass: "bxs-bookmarks",
              description: response.tickets,
            },
            {
              title: "Today's Completed Orders",
              iconClass: "bx-check-circle",
              description: response.today_completed_order_count,
            },
            {
              title: "Pending Cancelled Orders",
              iconClass: "bx-x",
              description: response.pending_cancelled_order_count,
            },
            {
              title: "Connected Stores",
              iconClass: "bx-store",
              description: response.connected_stores_count,
            },
            {
              title: "Running Scheduler",
              iconClass: "bx-stopwatch",
              description: response.active_cron_count,
            },
          ])
        }
      })
    }
    else if (JSON.parse(localStorage.getItem("authUser")).role_id === 2) {
      // if(config.site.MODE == "production") {
      //   initGA();
      // }
      // console.log(
      //   "'/user/get-dashboard-data",
      //   "/user/get-dashboard-data/" +
      //     JSON.parse(localStorage.getItem("authUser")).id
      // )
      get("/user/get-dashboard-data/" + JSON.parse(localStorage.getItem("authUser")).id, { headers }).then(response => {
        // console.log("user/get-dashboard-data", response)
        if (response.status === 200) {
          // setUser(response.user);
          // setOrders(response.orders);
          // setUserTickets(response.tickets);
          setReport([
            {
              title: "Total Balance",
              iconClass: "bx-dollar",
              description: "$" + amountFormat(response.user.balance),
            },
            {
              title: "Total Awaiting Shipment",
              iconClass: "bxs-box",
              description: response.orders.orders_count,
            },
            {
              title: "Total Completed Orders",
              iconClass: "bxs-box",
              description: response.completedOrders.orders_count,
            },
            {
              title: "Connected Stores",
              iconClass: "bxs-store",
              description: response.stores.store_count,
            },
            {
              title: "Open Tickets",
              iconClass: "bxs-bookmarks",
              description: response.tickets.ticket_count,
            },
            // {
            //   title: "Completed Orders",
            //   iconClass: "bxs-store",
            //   description: response.completed.completed_count,
            // },
          ])
        }
      })
      handleChart("day", selectedCarrier)
    }


    setTimeout(() => {
      // setSubscribemodal(true);
    }, 2000)
  }, [])

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carrier-names", { headers }).then(response => {
        setCarriers(response.data)
        setTimeout(() => {
          setMainLoading(false)
        }, 1500)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrders = async () => {
    try {
      let res = []
      await get("/admin/get-user-completed-orders", { headers }).then(
        response => {
          // // console.log("response", response);
          // setOrders(response.data);
          res = response.data
        }
      )
      return res
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrdersCompleted = async (numberOfDays) => {
    try {
      let res = []
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        type: numberOfDays
      }
      await post("/user/get-orders-completed-for-dashboard", body, { headers }).then(
        response => {
          // console.log("response", response)
          res = response.data
        }
      )
      return res
    } catch (e) {
      // console.log(e)
    }
  }

  const data = {
    labels: lastThirtyDays.map(el => el.date),  // Dates for the X-axis
    datasets: [
      {
        label: selectedCategory === "label" ? "Label Generated" : "Money Income",
        data: lastThirtyDays.map(el => selectedCategory === "label" ? el.value : el.price),
        borderColor: "#5F1ED9",
        backgroundColor: "transparent",
        // pointBackgroundColor: "#ffffff",
        // pointBorderColor: "#5F1ED9",
        backgroundColor: "transparent",
        // pointBorderWidth: 2,
        // pointHoverRadius: 6,
        // pointHoverBackgroundColor: "#ffffff",
        // pointHoverBorderColor: "#5F1ED9",
        // pointRadius: 5,
        tension: 0.5,
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: data?.datasets?.length < 0 ? false : true,
    },
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            zeroLineColor: localStorage.getItem("theme") === "dark" ? "#a6b0c4" : "black",
            borderDash: [10]
          },
          ticks: {
            fontColor: localStorage.getItem("theme") === "dark" ? "white" : "black",
            suggestedMin: 0,
            beginAtZero: true,
            userCallback: function (label, index, labels) {
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false, // Hide x-axis grid lines
            zeroLineColor: localStorage.getItem("theme") === "dark" ? "#a6b0c4" : "black",
          },
          ticks: {
            fontColor: localStorage.getItem("theme") === "dark" ? "white" : "black",
          },
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
        borderColor: "#3587ec",
        fill: true,
        backgroundColor: "rgba(53, 135, 236, 0.15)",
      },
      point: {
        radius: 0,
        // backgroundColor: "#3587ec",
      },
    },
  };






  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
  }

  const handleLoadGraph = () => {
    setLoadGraph(true)
    handleChart("day", selectedCarrier)
  }

  const generateLast30Days = () => {
    const dates = [];
    const today = new Date();

    for (let i = 30; i >= 0; i--) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${date.toLocaleString('default', { month: 'short' })}`;
      dates.push(formattedDate);
    }
    return dates;
  };

  const defaultData = {
    labels: generateLast30Days(),
    datasets: [
      {
        label: "No Data",
        data: [0, 0, 0, 0, 0], // Multiple zero values to show empty bars
        backgroundColor: "#2a3042",
        borderColor: "#2a3042",
        borderWidth: 1,
      },
    ],
  };

  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }


  const [walletData, setwalletData] = useState([])

  const retrievePlaidAccounts = async () => {
    const body = { id: JSON.parse(localStorage.getItem("authUser")).id, }
    post("/user/get-wallet-history", body, { headers }).then(response => {
      let sorted = []
      if (response.status == 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].batch_id) {
            let found = sorted.some(el => el.batch_id == response.data[i].batch_id)
            if (!found) {
              if (response.data[i].type == "credit") {
                sorted.push({
                  payment_mode: response.data[i].payment_mode,
                  batch_id: response.data[i].batch_id,
                  debit_amount: 0,
                  credit_amount: response.data[i].total_amount,
                  created_at: response.data[i].created_at,
                  success_orders: 0,
                  failed_orders: response.data[i].payment_mode != "Order Refund" ? response.data[i].order_ids ? response.data[i].order_ids.split(",").length : 0 : "-",
                })
              }
              else if (response.data[i].type == "debit") {
                sorted.push({
                  payment_mode: response.data[i].payment_mode,
                  batch_id: response.data[i].batch_id,
                  debit_amount: response.data[i].total_amount,
                  credit_amount: 0,
                  created_at: response.data[i].created_at,
                  success_orders: response.data[i].order_ids ? response.data[i].order_ids.split(",").length : 0,
                  failed_orders: 0,
                })
              }
            }
            else {
              let index = sorted.findIndex(el => el.batch_id == response.data[i].batch_id)
              if (response.data[i].type == "credit") {
                console.log("response.data[i].created_at", response.data[i].created_at)
                console.log("response.data[i].order_ids", response.data[i].order_ids)
                sorted[index].credit_amount = response.data[i].total_amount
                sorted[index].debit_amount = parseFloat(sorted[index].debit_amount) - parseFloat(response.data[i].total_amount)
                sorted[index].failed_orders = parseInt(sorted[index].success_orders) - (response.data[i].order_ids ? response.data[i].order_ids.split(",").length : 0)
              }
              if (response.data[i].type == "debit") {
                sorted[index].debit_amount = parseFloat(response.data[i].total_amount)
                sorted[index].success_orders = response.data[i].order_ids.split(",").length - parseInt(sorted[index].failed_orders)
              }
            }
          }
        }
      }
      // console.log("sorted", sorted);
      let data = sorted
      setwalletData(data)

      // setPending(false)
    })
  }



  useEffect(() => {
    retrievePlaidAccounts()
  }, [])



  const filteredReports = reports.filter((report) => report.title === "Total Completed Orders");
  //meta title
  document.title = `Dashboard | ${config.APP_DETAILS.APP_NAME}`

  const getBalance = () => {
    const report = reports.find((report) => report.title === "Total Balance");
    return report ? report.description : null;
  };

  function formatDateToReadable(created_at) {
    const date = new Date(created_at);
    const options = { year: 'numeric', month: 'long', day: 'numeric', };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("NullShip")} breadcrumbItem={props.t("Insights")} />

          <Row style={{ position: "relative" }}>

            {
              JSON.parse(localStorage.getItem("authUser")).role_id !== 3 && (
                <div id="loader" className={"align-items-center justify-content-center"} style={{ display: mainLoading ? "flex" : "none", alignItems: "center", margin: "auto", position: "absolute", width: "100%", height: "100%", backgroundColor: (localStorage.getItem("theme") ? (localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white") : "white"), zIndex: "99999", top: "0", left: "0", borderRadius: "5px" }}>
                  <img style={{ width: "10%" }} src={loader} />
                </div>
              )
            }

            {
              JSON.parse(localStorage.getItem("authUser")).role_id == 1 ? (
                <Col xl="12">
                  <Row>
                    {/* Reports Render */}
                    {reports.map((report, key) => (
                      <Col md={JSON.parse(localStorage.getItem("authUser")).role_id === 1 ? "3" : "6"} key={"_col_" + key}>
                        <Card className="mini-stats-wid slide">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-secondary fw-medium">{report.title}</p>
                                <h4 className="mb-0">{report.description} {report.title == "Total Awaiting Shipments" ? <span className={"text-secondary"} style={{ fontSize: "10px" }}>(All Stores)</span> : null}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-custom align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-custom">
                                  <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )
                :
                <Col style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "1rem", flexWrap: "wrap" }}>

                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: localStorage.getItem("theme") == "dark" ? "black" : "#EBF3FE", height: "300px", borderRadius: "12px", width: "754px", marginLeft: "0.1rem", marginRight: "0rem", padding: "2rem", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                    <div>
                      <h3 style={{}}>Welcome Back {JSON.parse(localStorage.getItem('authUser')).first_name} {JSON.parse(localStorage.getItem('authUser')).last_name}.</h3>
                      {filteredReports.map((report, key) => (
                        <p key={key} style={{ color: '#7C8FAC', maxHeight: "1rem", marginTop: "1rem", fontSize: "0.9rem" }}> You have <span style={{ color: '#ff8c00' }}>{report.description}</span> completed orders. Visit the Tracking Page to view order details and track your shipments.</p>
                      ))}

                      <div style={{ display: "flex", alignItems: "center", marginTop: "3rem" }}>
                        {filteredReports.map((report, key) => (
                          <h3 key={key} style={{ color: localStorage.getItem("theme") == "dark" ? "white" : "#2A3547", maxHeight: "1rem" }}>{report.description}</h3>
                        ))}
                        <button style={{ backgroundColor: "white", color: "#7C8FAC", borderRadius: "5px", padding: "5px", border: "none", marginLeft: "1rem" }}>Year {getCurrentYear()}</button>
                      </div>

                      <button onClick={() => props.history.push("/orders/tracking")} style={{ backgroundColor: "#5D87FF", color: "#fff", borderRadius: "5px", width: "76px", height: "36px", border: "none", marginTop: "2rem" }}>Track</button>


                    </div>
                    <img src={ShippingAvatar} style={{}} />
                  </div>


                  <div style={{ backgroundColor: "none", borderRadius: "12px", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: "1rem" }}>

                    {reports.map((report, key) => {
                      if (report.title !== "Total Completed Orders") {
                        return (
                          <div key={key} style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: localStorage.getItem("theme") == "dark" ? "black" : "#FFFFFF", minHeight: "135px", minWidth: "47%", borderRadius: "12px", marginBottom: "1rem", marginLeft: "10px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", paddingRight: "1rem", paddingLeft: "1rem" }}>
                            <div style={{ width: "100%" }} className="d-flex">
                              <div className="flex-grow-1">
                                <p style={{ color: "#7C8FAC" }} className="text-secondary fw-medium">{report.title}</p>
                                <h4 className="mb-0">{report.description} {report.title == "Total Awaiting Shipments" ? <span className={"text-secondary"} style={{ fontSize: "10px" }}>(All Stores)</span> : null}</h4>
                              </div>
                              <img src={report.title == "Total Awaiting Shipment" ? StatsImage : report.title == "Connected Stores" ? ConnectedStoresImage : report.title == "Open Tickets" ? TicketsImage : DollarImage} style={{ width: "5rem" }} />
                            </div>
                          </div>
                        )
                      }
                    })}

                  </div>

                </Col>
            }



            {JSON.parse(localStorage.getItem("authUser")).role_id !== 3 && (
              <div style={{ display: "flex", width: "100%",flexWrap:"wrap" }}>
                <Card style={{ flex: 1,minWidth: "30rem" }}>
                  <CardBody>
                    <div className={"row"} style={{ justifyContent: "center", marginBottom: "-20px", }}>
                      <Col md={2} onClick={() => { setSelectedCarrier("all"); handleChart(selectedView, "all") }} style={{ display: "none" }}>
                        <Card className={"mini-stats-wid"} style={{ cursor: "pointer" }}>
                          <CardBody className={"border p-3 rounded " + (selectedCarrier == "all" ? "carrier-selected" : "")}>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <h5 className="mb-0">All</h5>
                                {selectedCarrier == "all" ? (
                                  <p className="text-muted fw-medium text-md-start">
                                    {dataLoading ? (
                                      "Loading..."
                                    ) : (
                                      <>
                                        {selectedCategory == "label" ? (
                                          <i className={"bx bx-bookmark"}></i>
                                        ) : (
                                          "$"
                                        )}
                                        {lastThirtyDays.length > 0
                                          ? amountFormat(
                                            lastThirtyDays
                                              .map(item =>
                                                selectedCategory == "label"
                                                  ? item.value
                                                  : item.price
                                              )
                                              .reduce(
                                                (prev, next) => prev + next
                                              )
                                          )
                                          : ""}
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p className="text-muted fw-medium"></p>
                                )}
                              </div>
                              <div
                                style={{ width: "40px", height: "40px" }}
                                className={
                                  "avatar-xm p-2 rounded-circle bg-white align-self-center mini-stat-icon " +
                                  (selectedCarrier == "all"
                                    ? "border-selected"
                                    : "border-default")
                                }
                              >
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-white"
                                  }
                                >
                                  <i
                                    style={{ color: "#333" }}
                                    className={"bx bxs-box font-size-20"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      {carriers.map((part, id) => {
                        return (
                          <Col
                            md={2}
                            key={id}
                            onClick={() => {
                              setSelectedCarrier(part.name)
                              handleChart(selectedView, part.name)
                            }}
                            style={{ cursor: "pointer", display: "none" }}
                          >
                            <Card className={"mini-stats-wid "}>
                              <CardBody
                                className={
                                  "border p-3 rounded " +
                                  (selectedCarrier == part.name
                                    ? "carrier-selected"
                                    : "")
                                }
                              >
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <h5 className="mb-0">{part.name}</h5>
                                    {selectedCarrier == part.name ? (
                                      <p className="text-muted fw-medium text-md-start">
                                        {dataLoading ? (
                                          "Loading..."
                                        ) : (
                                          <>
                                            {selectedCategory == "label" ? (
                                              <i
                                                className={"bx bx-bookmark"}
                                              ></i>
                                            ) : (
                                              "$"
                                            )}
                                            {lastThirtyDays.length > 0
                                              ? amountFormat(
                                                lastThirtyDays
                                                  .map(item =>
                                                    selectedCategory ==
                                                      "label"
                                                      ? item.value
                                                      : item.price
                                                  )
                                                  .reduce(
                                                    (prev, next) =>
                                                      prev + next
                                                  )
                                              )
                                              : ""}
                                          </>
                                        )}
                                      </p>
                                    ) : (
                                      <p className="text-muted fw-medium"></p>
                                    )}
                                  </div>
                                  <div
                                    style={{ width: "40px", height: "40px" }}
                                    className={
                                      "avatar-xm p-2 rounded-circle bg-white align-self-center mini-stat-icon " +
                                      (selectedCarrier == part.name
                                        ? "border-selected"
                                        : "border-default")
                                    }
                                  >
                                    <span
                                      className={
                                        "avatar-title rounded-circle bg-white"
                                      }
                                    >
                                      <img
                                        src={
                                          part.name == "FedEx"
                                            ? fedex
                                            : part.name == "UPS" || part.name == "UPSv2"
                                              ? ups
                                              : part.name == "USPS"
                                                ? usps
                                                : dhl
                                        }
                                        height={"15px"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </div>
                    <br />
                    {/*<h4 className="card-title mb-4">Last {numberOfDays} Days Label Generation Report</h4>*/}
                    {loadGraph ?
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                          <h3>Statistics</h3>

                          <div style={{ display: "flex", alignItems: "center", padding: "5px", backgroundColor: localStorage.getItem("theme") !== "dark" ? "#F4F4F4" : "black", borderRadius: "4px", width: "237px", height: "33px" }}>
                            <div onClick={() => { setSelectedCategory("label"); handleChart(selectedView, selectedCarrier) }} style={{ flex: 1, height: "25px", backgroundColor: selectedCategory === "label" && "white", cursor: "pointer", borderRadius: "4px", marginRight: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>Label</div>
                            <div onClick={() => { setSelectedCategory("price"); handleChart(selectedView, selectedCarrier) }} style={{ flex: 1, height: "25px", backgroundColor: selectedCategory === "price" && "white", cursor: "pointer", borderRadius: "4px", marginRight: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>Cost</div>
                            <div style={{ width: "1px", height: "1rem", marginRight: "6px", backgroundColor: "#848484" }}></div>
                            <div onClick={() => { setSelectedView("day"); handleChart("day", selectedCarrier) }} style={{ flex: 1, height: "25px", backgroundColor: selectedView === "day" && "white", cursor: "pointer", borderRadius: "4px", marginRight: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>Day</div>
                            <div onClick={() => { setSelectedView("month"); handleChart("month", selectedCarrier) }} style={{ flex: 1, height: "25px", backgroundColor: selectedView === "month" && "white", cursor: "pointer", borderRadius: "4px", marginRight: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>Month</div>
                          </div>



                        </div>

                        <div style={{ height: "300px" }}>
                          {dataLoading ? (
                            <div
                              id="loader"
                              className={
                                "align-items-center justify-content-center"
                              }
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                backgroundColor: (localStorage.getItem("theme") ? (localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white") : "white"),
                              }}
                            >
                              <img style={{ width: "5%" }} src={loader} />
                            </div>
                          ) : (
                            <Line data={data?.datasets?.length > 0 ? data : defaultData} options={options} />
                          )}
                        </div>
                      </> : ""}
                    {!loadGraph && JSON.parse(localStorage.getItem("authUser")).role_id == 1 ? <button className={"btn btn-dark"} onClick={handleLoadGraph}>Load Graph</button> : ""}
                  </CardBody>

                </Card>
                <div style={{ backgroundColor: localStorage.getItem("theme") == "dark" ? "black" : "white", padding: "2rem", height: "23.5rem", width: "30rem", marginLeft: "2rem", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", borderRadius: "12px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h4 style={{ marginRight: "10px" }}>Transactions</h4>
                      <div style={{ width: "1px", height: "1rem", backgroundColor: "#848484", marginRight: "10px" }}></div>
                      <div style={{ color: "#848484" }}>Wallet History</div>
                    </div>
                    <div onClick={() => props.history.push("/wallet-history")} style={{ color: "#5D87FF", cursor: "pointer" }}>View All</div>
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    {
                      walletData?.map((item, index) => {
                        if (index < 4) {
                          return (

                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", borderBottom: "0.1px solid #EAEAEA", paddingBottom: "10px" }}>

                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#B2FFEE", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
                                  <img src={officeBag} />
                                </div>

                                <div>
                                  <div style={{ color: localStorage.getItem("theme") == "dark" && "white" }}>{item?.credit_amount > 0 ? "Credit" : "Debit"}</div>
                                  <div style={{ marginTop: "2px", color: localStorage.getItem("theme") == "dark" && "white" }}>{formatDateToReadable(item?.created_at)}</div>
                                </div>

                              </div>

                              <div style={{ color: item?.credit_amount > 0 ? "#00B046" : "#D70000" }}>{item?.credit_amount > 0 ? `+ $${item?.credit_amount}` : `- $${item?.debit_amount}`}</div>



                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </div>

              </div>
            )
            }
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
