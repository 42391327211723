// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"
import config from "./../../../config"


function BlogCategories() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [description, setDescription] = useState(null);
  const [serviceImage, setServiceImage] = useState("");

  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setServiceImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  const columns = [
    {
      name: '',
      // selector: 'image',
      //  sortable: true,
      width: "70px",
      selector: row => <img src={`data:image/jpeg;base64,${row.image}`} height={"20px"} />
    },
    {
      name: 'Title',
      selector: row => row.title,
      //  sortable: true,
    },
    {
      name: 'Slug',
      selector: row => row.slug,
      //  sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      //  sortable: true,
    },
    {
      name: 'Created By',
      selector: row => row.email,
      //  sortable: true,
    },
    {
      name: 'Status',
      // selector: 'status',
      sortable: true,
      width: "120px",
      selector: row => row.status === 1 ? <span className={"badge badge-success"}>Active</span> : <span className={"badge badge-danger"}>Inactive</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "160px",
      selector: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleEdit(row.id)}>Edit</button>
          <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{ marginLeft: "10px" }}>Delete</button>
        </>
    },
  ];

  const handleEdit = async (id) => {
    const body = {
      id: id
    };
    post('/admin/get-blog-category-by-id', body, { headers }).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setId(data.id);
        setTitle(data.title);
        setSlug(data.slug);
        setDescription(data.description);
        setModal(true);
      }
      else {
        toast.success(response.message);
      }
    })
  }

  const handleDelete = async (id) => {
    let confrm = confirm("Are you sure you want to delete this category?");
    if (confrm) {
      const body = {
        id: id
      };
      post('/admin/delete-blog-category', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveBlogCategories();
        } else {
          toast.success(response.message);
        }
      })
    }
  }

  // const tableData = ;
  const retrieveBlogCategories = async () => {
    try {
      get('/admin/get-blog-categories', { headers }).then((response) => {
        // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: title || '',
      description: description || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      handleEditService(values)
    }
  });


  const handleEditBlogCategory = async () => {
    try {

      if (title && slug && description) {
        const body = {
          id: id,
          title: title,
          slug: slug,
          description: description,
          image: serviceImage
        };

        // console.log("body",body);
        post('/admin/update-blog-category', body, { headers }).then((response) => {
          // console.log("resss",response);
          if (response.status === 200) {
            toast.success(response.message);
            retrieveBlogCategories();
            setModal(!modal);
          }
          else {
            toast.error(response.message);
          }
        })
      }
      else {
        toast.error("Please fill all required fields");
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // console.log("here");
    retrieveBlogCategories();
  }, []);

  //meta title
  document.title = `Blog Categories | ${config.APP_DETAILS.APP_NAME}`;

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')  // Remove non-word characters
      .replace(/--+/g, '-')     // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Trim hyphens from the beginning and end of the slug
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={`${config.APP_DETAILS.APP_NAME}`} breadcrumbItem="Blog Categories" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <Link to={"/blog/category/add"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add New Blog Category</button></Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Blog Category...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Edit Blog Category
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditBlogCategory();
                  return false;
                }}
              >
                <div className="mb-3">
                  <label>Category Image</label>
                  <input type={"file"} multiple={false} ref={fileInput} onChange={handleImage} className={"form-control"} accept={".png"} />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Title</Label>
                  <Input
                    name="title"
                    className="form-control"
                    placeholder="Enter Title"
                    type="text"
                    onChange={(e) => {
                      let value = e.target.value;
                      // let slug = value.toString().toLowerCase().replace("-"," ").replace(" ","-");
                      setTitle(e.target.value)
                      setSlug(slugify(value));
                    }}
                    value={title}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Slug</Label>
                  <Input
                    name="slug"
                    className="form-control"
                    placeholder="Slug"
                    type="text"
                    readOnly={true}
                    // onChange={(e) => setSlug(e.target.value)}
                    value={slug}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Description</Label>
                  <Input
                    name="description"
                    className="form-control non-arrow-number"
                    placeholder="Enter Description"
                    type="textarea"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
BlogCategories.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default BlogCategories;