// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import JSZip from "jszip"
import OrderViewModal from "../../../components/OrderViewModal"
import config from "../../../config"
import axios from "axios"
import customStyles from "../../../assets/css/customTableStyle"

let selectedOrders = [];
function USPS() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const [viewModal, setViewModal] = useState(false);
  const [service_image, setServiceImage] = useState(null);
  const [id, setId] = useState(null);
  const [from_name, setFromName] = useState(null);
  const [from_company, setFromCompany] = useState(null);
  const [from_phone, setFromPhone] = useState(null);
  const [from_street1, setFromStreet1] = useState(null);
  const [from_street2, setFromStreet2] = useState(null);
  const [from_city, setFromCity] = useState(null);
  const [from_zip, setFromZip] = useState(null);
  const [from_state, setFromState] = useState(null);
  const [to_name, setToName] = useState(null);
  const [to_company, setToCompany] = useState(null);
  const [to_phone, setToPhone] = useState(null);
  const [to_street1, setToStreet1] = useState(null);
  const [to_street2, setToStreet2] = useState(null);
  const [to_city, setToCity] = useState(null);
  const [to_zip, setToZip] = useState(null);
  const [to_state, setToState] = useState(null);
  const [length, setLength] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [weight, setWeight] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [aboveFiveLbsPrice, setAboveFiveLbsPrice] = useState(null);
  const [discount_type, setDiscountType] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [description, setDescription] = useState(null);
  const [created_at, setCreatedAt] = useState(null);
  const [is_custom_form, setIsCustomForm] = useState(0);
  const [content_type, setContentType] = useState(null);
  const [restriction_type, setRestrictionType] = useState(null);
  const [content_explanation, setContentExplanation] = useState(null);
  const [restriction_comments, setRestrictionComments] = useState(null);
  const [non_delivery_action, setNonDeliveryAction] = useState(null);
  const [eel_or_ppc, setEelOrPpc] = useState(null);
  const [custom_form_items, setCustomFormItems] = useState([]);

  const [completing_order_id, setCompletingOrderId] = useState(null);
  const [order_shipment_id, setOrderShipmentID] = useState(null);
  const [order_tracking_id, setOrderTrackingID] = useState(null);
  const [viewModalCompleteOrder, setViewModalCompleteOrder] = useState(false);

  const [error_order_id, setErrorOrderId] = useState(null);
  const [viewModalErrorOrder, setViewModalErrorOrder] = useState(false);
  const [order_error, setOrderError] = useState("");
  const [rowId, setRowId] = useState();

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const retrieveUSPSOrders = async () => {
    try {
      get('/admin/get-usps-orders', {headers}).then((response) => {
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };

  const columns = [
    {
      name: <input type={"checkbox"} title={"Select All"} className={"select-all-checkbox"} id={"select-all-checkbox"} onChange={checkAll} />,
      // selector: ['id','name'],
      // sortable: true,
      width: "60px",
      selector: row => <input type={"checkbox"} className={"order-checkbox"} value={row.id} id={`order-${row.id}`} name="order_id" disabled={row.name?false:true} onChange={handleOrderCheck}/>
    },
    {
      name: 'User Email',
      selector: row => row.email,
      // sortable: true,
    },
    {
      name: 'Service',
      // selector: 'name',
    //  sortable: true,
      selector: row => row.name ? row.name : <span style={{color:"red"}}>Not Assigned</span>
    },
    {
      name: 'From Name',
      // selector: 'from_name',
    //  sortable: true,
      selector: row => row.from_name ? row.from_name : "-"
    },
    {
      name: 'To Name',
      // selector: 'to_name',
    //  sortable: true,
      selector: row => row.to_name ? row.to_name : "-"
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      // selector: 'status',
     sortable: true,
      selector: (row) => row.status === 0 ? <span className={"badge badge-danger"}>Incompleted</span>:<span className={"badge badge-success"}>Completed</span>
    },
    {
      name: 'Error',
      // selector: 'error_message',
    //  sortable: true,
      width:"160px",
      // cell: (row) => row.error_message?<span className={"badge badge-danger"}>{row.error_message}</span>:"-"
      selector: (row) => row.error_message ? <span className={"text-danger fw-medium"}>{row.error_message}</span> : "-"
    },
    {
      name:'Actions',
      // selector: 'id',
      width:"130px",
      selector: (row) =>
        <>
          <button className={"btn btn-success btn-sm"} onClick={() => {
            setRowId(row.id)
            setViewModal(true)
          }} style={{ float: "right", marginRight: "10px" }}>View Order</button>
          {/*<button className={"btn btn-warning btn-sm"} onClick={() => {handleCompleteOrder(row.id)}} style={{float:"right",marginLeft:"10px"}}>Complete Order</button>*/}
          {/*<button className={"btn btn-danger btn-sm"} onClick={() => {handleErrorOrder(row.id)}} style={{float:"right",marginLeft:"10px"}}>Update Error</button>*/}
        </>
    }
  ];

  const handleOrderCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if(!selectedOrders.includes(value)) {
        selectedOrders.push(value);
      }
    }
    else {
      let index = selectedOrders.findIndex(el => el == value);
      selectedOrders.splice(index, 1);
      // selectedOrders.filter((el) => el !== value);
    }

    setSelectedOrdersCount(selectedOrders.length);
    // // console.log("selectedOrders", selectedOrders);
  }

  function checkAll(e) {
    const { checked } = e.target;
    let checkboxes = document.getElementsByClassName('order-checkbox');
    selectedOrders = [];

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false;
        selectedOrders = [];
      }
      else {
        checkboxes[i].checked = true;
        if(!selectedOrders.includes(checkboxes[i].value)) {
          selectedOrders.push(checkboxes[i].value);
        }
      }
    }

    setSelectedOrdersCount(selectedOrders.length);
    // // console.log("selected", selectedOrders)
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName('order-checkbox');
    let selectAllCheckbox = document.getElementsByClassName('select-all-checkbox');
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false;
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  const retrieveOrder = async (oid) => {
    try {
      let body = {
        id: oid
      };
      // // console.log("body",props.match.params.id);
      post('/user/get-order', body, {headers}).then((response) => {
        // console.log("get order", response.data[0]);
        let data = response.data[0];
        // console.log("JSON.parse(data.custom_items_data)",JSON.parse(data.custom_items_data));
        setId(data.id);
        setServiceImage(data.service_image);
        setFromName(data.from_name);
        setFromCompany(data.from_company);
        setFromPhone(data.from_phone);
        setFromStreet1(data.from_street1);
        setFromStreet2(data.from_street2);
        setFromCity(data.from_city);
        setFromZip(data.from_zip);
        setFromState(data.from_state);
        setToName(data.to_name);
        setToCompany(data.to_company);
        setToPhone(data.to_phone);
        setToStreet1(data.to_street1);
        setToStreet2(data.to_street2);
        setToCity(data.to_city);
        setToZip(data.to_zip);
        setToState(data.to_state);
        setLength(data.length);
        setHeight(data.height);
        setWidth(data.width);
        setWeight(data.weight);
        setServiceId(data.service_id);
        setName(data.name);
        setPrice(data.price);
        setAboveFiveLbsPrice(data.above_five_lbs_price);
        setDescription(data.description);
        setDiscount(data.discount);
        setDiscountType(data.discount_type);
        setCreatedAt(data.created_at);
        setIsCustomForm(parseInt(data.is_custom_form));
        setContentType(data.content_type);
        setContentExplanation(data.content_explanation);
        setRestrictionType(data.restriction_type);
        setRestrictionComments(data.restriction_comments);
        setNonDeliveryAction(data.non_delivery_action);
        setEelOrPpc(data.eel_or_ppc);
        setCustomFormItems(data.custom_items_data!==null?JSON.parse(data.custom_items_data):null);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleView = (id) => {
    // e.preventDefault();
    // const id = e.target.getAttribute("data-id");
    // // console.log("handleView",e.target)
    retrieveOrder(id).then(() => {
      setViewModal(!viewModal);
    });
  }

  const handleCompleteOrder = (order_id) => {
    setCompletingOrderId(order_id);
    setViewModalCompleteOrder(!viewModalCompleteOrder);
  }

  const handleErrorOrder = (order_id) => {
    setErrorOrderId(order_id);
    setOrderError("");
    setOrderShipmentID("");
    setOrderTrackingID("");
    setViewModalErrorOrder(!viewModalErrorOrder);
  }

  const handleCompleteOrderSubmit = (e) => {
    e.preventDefault();
    if(selectedOrders.length > 0) {
      const formData = new FormData();
      formData.append('file', fileInput.current.files[0]);
      formData.append('order_id', completing_order_id);
      formData.append('order_shipment_id', order_shipment_id);
      formData.append('order_tracking_id', order_tracking_id);

      postFile("/user/complete-usps-order", formData).then((response) => {
        // console.log("response",response);
        if (response.status === 200) {
          retrieveUSPSOrders().then(() => {
            toast.success(response.message);
            // setViewModalCompleteOrder(!viewModalCompleteOrder);
          });
        }
        else {
          toast.error(response.message);
        }
      })
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleErrorOrderSubmit = (e) => {
    e.preventDefault();
    const body = {
      order_id: error_order_id,
      message: order_error
    }

    post("/admin/error-usps-order", body, {headers}).then((response) => {
      // console.log("response",response);
      if (response.status === 200) {
        retrieveDHLOrders().then(() => {
          toast.success(response.message);
          setViewModalErrorOrder(!viewModalErrorOrder);
        });
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handlePrint = async () => {
    if(selectedOrders.length > 0) {
      document.body.style.pointerEvents = "none";
      let loading = toast.loading("Processing Request...");
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: selectedOrders.toString()
      };
      post('/user/print-orders-usps', body, { headers }).then(async (response) => {
        // // console.log("response", response);
        // setTimeout(async () => {
          toast.remove(loading);
          // if (response.pdf && response.pdf.length > 0) {
          //   const pdfUrls = response.pdf;
          //   const zip = new JSZip();
          //
          //   let loadingComp = toast.loading("Creating compressed file...");
          //   new Promise(async function(resolve, reject) {
          //     for (let i = 0; i < pdfUrls.length; i++) {
          //       await get('user/get-pdf/' + pdfUrls[i], { headers })
          //         .then(async response => {
          //           await zip.file(pdfUrls[i], response);
          //           if(i === (pdfUrls.length - 1)) {
          //             resolve(zip);
          //           }
          //         });
          //     }
          //   }).then(async () => {
          //     await zip.generateAsync({ type: "blob" })
          //       .then(function (content) {
          //         toast.remove(loadingComp);
          //         saveAs(content, "orders.zip");
          //       });
          //   })
          // }
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          await retrieveUSPSOrders();
          uncheckAll();
          document.body.style.pointerEvents = "auto";
          if (response.status === 200) {
            toast.remove(loading);
            setResponseSuccess(true);
            setResponseMessage(response.message);
          }
          else {
            toast.remove(loading);
            setResponseSuccess(false);
            setResponseMessage(response.message);
          }

          if(response.success_orders.length > 0 ) {
            let body = {
              data: response.success_orders
            };
            // console.log("generate tracking body", body);
            await post('/user/generate-tracking', body, {headers});
          }

      });
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  useEffect(() => {
    retrieveUSPSOrders();
  }, []);

  const handleOnPageChange = () => {
    document.getElementById("select-all-checkbox").checked = false;
    if(selectedOrders.length > 0) {
      for (let i=0;i<selectedOrders.length;i++) {
        if(document.getElementById(`order-${selectedOrders[i]}`)) {
          document.getElementById(`order-${selectedOrders[i]}`).checked = true;
        }
      }
    }
  }

  //meta title
  document.title = `USPS Orders In Process | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="In Process" breadcrumbItem="USPS Orders" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            {responseSuccess === false ? (
              <Alert color="danger">
                {responseMessage}
              </Alert>
            ) : null}
            {responseSuccess === true ? (
              <Alert color="success">
                {responseMessage}
              </Alert>
            ) : null}
            <span style={{fontSize:"12px",fontWeight:"normal", float: "right"}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected</span><br/>
            <button className={"btn btn-warning"} style={{float:"left",marginRight:"10px",marginTop:"10px"}} onClick={handlePrint}>Proceed</button>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter USPS Orders...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}
                         onChangePage={handleOnPageChange}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />

        {/*Modal*/}
        <Modal
          isOpen={viewModalCompleteOrder}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setViewModalCompleteOrder(!viewModalCompleteOrder);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setViewModalCompleteOrder(!viewModalCompleteOrder);
              }}
            >
              Complete Order
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={handleCompleteOrderSubmit}
              >
                <div className="mb-3">
                  <label>Shipment ID</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Shipment ID"
                    type="text"
                    onChange={(e) => setOrderShipmentID(e.target.value)}
                    value={order_shipment_id}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label>Tracking ID</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Tracking ID"
                    type="text"
                    onChange={(e) => setOrderTrackingID(e.target.value)}
                    value={order_tracking_id}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label>Order Pdf</label>
                  <input type={"file"} multiple={false} required={true} ref={fileInput} onChange={handleChange} className={"form-control"} accept={".pdf"}/>
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={viewModalErrorOrder}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setViewModalErrorOrder(!viewModalErrorOrder);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setViewModalErrorOrder(!viewModalErrorOrder);
              }}
            >
              Order Error
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={handleErrorOrderSubmit}
              >
                <div className="mb-3">
                  <label>Error</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Error"
                    type="text"
                    onChange={(e) => setOrderError(e.target.value)}
                    value={order_error}
                    required={true}
                  />
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Update Error
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
USPS.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default USPS;