import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import TestimonialComp from './TestimonialComp'; // Adjust the import based on your file structure
import TestimonialCompVideo from './TestimonialCompVideo'; // Adjust the import based on your file structure
import rating from "./../../assets/images/Rating.png"
import rating1 from "./../../assets/images/Rating1.png"
import rating2 from "./../../assets/images/Rating2.png"
export const testimonialsData = [
  {
      name: "Lauren",
      title: "Moderate Shipping Rates",
      feedback: "Shipment arrived on time, well-packaged, and in perfect condition. Very satisfied!",
      date: "Jan 11, 2024",
      avatar: "https://randomuser.me/api/portraits/women/1.jpg",
      rate: rating
  },
  {
      name: "Mike",
      title: "Fast Delivery",
      feedback: "Excellent service! The package arrived faster than expected and was in great shape.",
      date: "Feb 2, 2024",
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
      rate: rating1

  },
  {
      name: "Sophia",
      title: "Reliable Tracking",
      feedback: "I appreciate the tracking updates. My shipment arrived on the promised date without any issues.",
      date: "Mar 15, 2024",
      avatar: "https://randomuser.me/api/portraits/women/2.jpg",
      rate: rating

  },
  {
      name: "James",
      title: "Smooth Return Process",
      feedback: "Great experience! The shipping was smooth, and I loved the easy return process.",
      date: "Apr 5, 2024",
      avatar: "https://randomuser.me/api/portraits/men/2.jpg",
      rate: rating2

  },
  {
      name: "Emma",
      title: "Secure Packaging",
      feedback: "The packaging was secure, and everything arrived intact. Highly recommend!",
      date: "May 20, 2024",
      avatar: "https://randomuser.me/api/portraits/women/3.jpg",
      rate: rating


  },
  {
      name: "Oliver",
      title: "On-Time Delivery",
      feedback: "Very happy with the delivery service. Everything was on time and in perfect condition.",
      date: "Jun 12, 2024",
      avatar: "https://randomuser.me/api/portraits/men/3.jpg",
      rate: rating1

  },
  {
      name: "Isabella",
      title: "Fantastic Customer Service",
      feedback: "Customer service was fantastic! They answered all my questions promptly.",
      date: "Jul 18, 2024",
      avatar: "https://randomuser.me/api/portraits/women/4.jpg",
      rate: rating2

  },
  {
      name: "Liam",
      title: "Great Communication",
      feedback: "Fast shipping and great communication throughout the process.",
      date: "Aug 24, 2024",
      avatar: "https://randomuser.me/api/portraits/men/4.jpg",
      rate: rating1

  },
  {
      name: "Ava",
      title: "Helpful Tracking Feature",
      feedback: "The online tracking feature was very helpful. I knew exactly when to expect my package.",
      date: "Sep 30, 2024",
      avatar: "https://randomuser.me/api/portraits/women/5.jpg",
      rate: rating2

  },
  {
      name: "Noah",
      title: "No Damage on Arrival",
      feedback: "Received my order without any damage. Will definitely use this service again!",
      date: "Oct 22, 2024",
      avatar: "https://randomuser.me/api/portraits/men/5.jpg",
      rate: rating

  },
  {
      name: "Mia",
      title: "Eco-Friendly Packaging",
      feedback: "I love the eco-friendly packaging. It shows they care about the environment.",
      date: "Nov 14, 2024",
      avatar: "https://randomuser.me/api/portraits/women/6.jpg",
      rate: rating2

  },
  {
      name: "Elijah",
      title: "Satisfaction Guaranteed",
      feedback: "My experience was smooth from start to finish. Highly satisfied with the service!",
      date: "Dec 10, 2024",
      avatar: "https://randomuser.me/api/portraits/men/6.jpg",
      rate: rating

  }
];

const Testimonials = () => {
  return (
    <Container className="h-100 w-100 p-0 m-0">    
      <Row className=" m-0 p-0 h-100 w-100">
        <Col sm="4" className="d-flex flex-column m-0 p-1 mt-2 ">
          <TestimonialCompVideo  url={"https://www.youtube.com/watch?v=OgCbmOLTBn0"} />
          {testimonialsData.slice(1, 3).map((item) => (
            <TestimonialComp key={item.heading} data={item} />
          ))}
        </Col>
        <Col sm="4" className="d-flex flex-column m-0 p-1 mt-2">
          {testimonialsData.slice(4, 8).map((item) => (
            <TestimonialComp key={item.heading} data={item} />
          ))}
        </Col>
        <Col sm="4" className="d-flex flex-column m-0 p-1 mt-2">
          {testimonialsData.slice(10, 12).map((item) => (
            <TestimonialComp key={item.heading} data={item} />
          ))}
          <TestimonialCompVideo url={"https://www.youtube.com/watch?v=dfN7cFqazgs"} />
        </Col>
      </Row>
    </Container>
  );
};

export default Testimonials;
