



import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

const TestimonialComp = ({ data }) => {
    return (
        <Card style={{borderRadius: "20px"}} className="border border-5   shadow-lg bg-white  p-0 my-1">
            <CardBody className="d-flex flex-column  rounded px-2 py-4">
                <div className="d-flex align-items-center rounded">
                    <div  className="px-1">
                        <div className="rounded-circle bg-light d-flex align-items-center justify-content-center overflow-hidden" style={{ width: '48px', height: '48px' }}>
                            <img
                                src={data.avatar}
                                alt="Avatar"
                                className="w-100 h-100 object-fit-cover"
                            />
                        </div>
                    </div>
                    <div  className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex flex-column text-capitalize">
                            <span>{data.name}</span>
                            <span className="text-muted small">{data.date}</span>
                        </div>
                        <div className="d-flex">
                            <img
                                src={data.rate}
                                alt="Rating"
                                style={{ width: '50px', height: '10px' }}
                                // className="object-fit-cover"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column text-start text-capitalize mt-2">
                    <span className="fw-medium">{data.title}</span>
                    <span className="small pt-2">{data.feedback}</span>
                </div>
            </CardBody>
        </Card>


    );
};

export default TestimonialComp;












// import React from 'react';
// import { Card, CardBody, Row, Col } from 'reactstrap';

// const TestimonialComp = ({ data }) => {
//   return (
//     <Card className="border-8 border-[#FBF2FF] rounded-2xl cursor-pointer capitalize bg-white drop-shadow-xl min-w-72">
//       <CardBody className="flex flex-col p-5 gap-3">
//         <Row className="align-items-center">
//           <Col xs="auto" className="pr-2">
//             <div className="rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
//               <img
//                 src={data.avatar}
//                 width={48}
//                 height={48}
//                 alt="Avatar"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </Col>
//           <Col className="d-flex justify-between">
//             <div className="flex flex-column capitalize gap-0">
//               <span>{data.name}</span>
//               <span className="p-0 m-0 text-[#99A0AE] text-xs">{data.date}</span>
//             </div>
//             <div className="flex items-center justify-center">
//               <div className="flex items-center justify-center overflow-hidden">
//                 <img
//                   src={data.rate}
//                   width={70}
//                   height={40}
//                   alt="Rating"
//                   className="object-cover"
//                 />
//               </div>
//             </div>
//           </Col>
//         </Row>

//         <div className="flex flex-nowrap flex-col items-baseline capitalize">
//           <span className="font-medium"><p>{data.title}</p></span>
//           <span className="text-xs pt-2"><p>{data.feedback}</p></span>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default TestimonialComp;
