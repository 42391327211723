import React, { useState, useEffect } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable, { createTheme } from "react-data-table-component"
import { post, get } from "../../../helpers/api_helper"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Select from 'react-select';
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Button,
   Label,
   Input,
   FormFeedback,
   Form,
   Modal,
   ModalHeader,
   ModalBody,
   CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from "reactstrap"
 import moment from "moment-timezone"
 import config from "./../../../config"

const ReferralAdmin = () => {
   
   // Page Title
   document.title = `Referral | ${config.APP_DETAILS.APP_NAME}`;

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };
   const [pending, setPending] = useState(true)
   const [filterType, setFilterType] = useState(
      localStorage.getItem("referral-filter")
        ? localStorage.getItem("referral-filter")
        : "referralData"
   )
   const [emailList, setEmailList] = useState([]);
   const [userEmails, setUserEmails] = useState(null)
   const [filterData, setFilterData] = useState([])
   const [tableData, setTableData] = useState([])
   const [paymentData, setPaymentData] = useState([])
   // const [selectedCheck, setSelectedCheck] = useState('');
   const [paymentGroupData, setPaymentGroupData] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [from, setFrom] = useState('');
   const [to, setTo] = useState('');
   const [filterChecks, setFilterChecks] = useState("")

   // const [filterApiData, setFilterApiData] = useState([])
   const buttonTypes = [
      {value:'Referral Data', key:'referralData'},
      {value:'Payment History', key:'paymentHistory'}
   ]

   // Get User Email from API
   const retrieveUser = () => {
      try {
      get('/admin/get-referrer-emails', {headers}).then((response) => {
         console.log("Get Referrer Emails", response);
         let arr = [];
         for(let i=0; i<response.data.length; i++){
            arr.push({value: response.data[i].refer_by_user_id, label:response.data[i].refer_by_email})
         }
         setEmailList(arr)
      }).catch(e => console.log("ee",e))
      }
      catch (e) {
      // // console.log(e)
      }
   }

   // Group By Reference Email Filter
   const groupedData = paymentData.reduce((groups, item) => {
      const { user_email } = item;
      if (!groups[user_email]) {
         groups[user_email] = [];
      }
      groups[user_email].push(item);
      return groups;
   }, {})

   // Get Filter Data From API
   const retrieveFilteredData = (search, from, to, user_id, group_by_referrer) => {
      const body = {
        search: search,
        from: from,
        to: to,
        refer_by: user_id,
        group_by_referrer: group_by_referrer
      }
      console.log("filtered values Body", body)
  
      post('/admin/get-filtered-referrers', body, {headers}).then((response) => {
      //   console.log("filtered values", response)
        if (response.status === 200) {
            // console.log("Success!")
            if (group_by_referrer) {
               setPaymentGroupData(groupedData)
               console.log("Payment Grouped Data", groupedData)
            }
            setFilterData(response.data)
        } else {
         // console.log("Failed")
         // setTableData(tableData)
        }
      })
   }

   // Get Referred Data from API
   const retrieveUsersData = () => {
      try {
         get('/admin/get-referrer', {headers}).then((response) => {
            // console.log("Get Users Data", response);
            setTableData(response.data)
            // setPending(false);
         }).catch(e => console.log("ee",e))
      }
      catch (e) {
      console.log(e)
      }
   }

   // Get Payment Data from API
   const retrievePaymentData = () => {
      try {
         get('/admin/get-referrer-payments', {headers}).then((response) => {
            // console.log("Get Payment Data", response.data);
            setPaymentData(response.data)
            // setPending(false);
         }).catch(e => console.log("ee",e))
      }
      catch (e) {
      console.log(e)
      }
   }
   
   // Resuable handle for filter state update
   const handleToggleData = (type) => {
      // setPending(true)
      setFilterType(type)
      setFilterChecks(false)
      setFrom("");
      setTo("");
      setSearchTerm("");
      setUserEmails(null);
      localStorage.setItem("referral-filter", type)
   }
    
   const dataToDisplay = filterData ? filterData : tableData;

   useEffect(() => {
      retrieveUser();
      retrieveFilteredData();
      retrieveUsersData();
      retrievePaymentData();
      setPending(false)
   }, [filterType])

   // console.log("selected checked value", selectedCheck)

   // Referral Data Columns
   const referral_data_columns = [
      // {
      //    name: "User Id",
      //    selector: "refer_by_user_id",
      //    width: "250px",
      // },
      {
         name: "Email Referred By",
         selector: row => row.refer_by_email,
         //   width: "300px",
      },
      {
         name: "Referred To",
         selector: row => row.refer_to_email,
         //   width: "300px", 
      },
      {
         name: "Total Labels",
         selector: row => row.completed_order_count,
           width: "250px", 
      },
      {
         name: "Labels Refunded",
         selector: row => row.refunded_order_count,
           width: "250px", 
      },
      {
         name: "Created At",
         // selector: "created_at",
         width: "250px",
         selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
      }
   ]

   
   // Payment History Columns
   const payment_history_columns = [
      {
         name: "Email Referred By",
         selector: row => row.user_email,
      },
      {
         name: "Payment Date Time",
         // selector: "created_at",
         selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
      },
      {
         name: "Payment Mode",
         selector: row => row.payment_mode,
      },
      {
         name: "Amount (USD)",
         selector: row => row.amount,
           width: "250px",
      },
      {
         name: "Total Labels",
         selector: row => row.order_count,
           width: "250px",
      }
   ]

   createTheme('solarized', {
      text: {
         primary: '#fffff',
         secondary: '#fffff',
      },
      background: {
         default: '#0f0f0f',
      },
      context: {
         background: '#cb4b16',
         text: '#0f0f0f',
      },
      divider: {
         default: '#282828',
      },
      action: {
         button: 'rgba(0,0,0,.54)',
         hover: 'rgba(0,0,0,.08)',
         disabled: 'rgba(0,0,0,.12)',
      },
   }, 'dark');

   return (
      <div className="page-content" style={{position:"relative"}}>
         <Breadcrumbs
          title={config.APP_DETAILS.APP_NAME}
         //  sourceItem="Orders"
          breadcrumbItem="Referral"
         //  reloadPage={handleReloadPage}
         //  isOrder={true}
        />

        {/* Tabs Section */}
        <div
          style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8" }}
          className="rounded-top"
        >
         {buttonTypes.map((button, index) => (
            <button
            key={index}
            className={
              "btn btn-md rounded-top " +
              (filterType === "Reports" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == button?.key ? (localStorage.getItem("theme") == "dark"?"#2a3042":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={() => handleToggleData(button?.key)}
            disabled={pending}
          >
            {button?.value}
          </button>
         ))}
        </div>
        {/* Filter and Data Content Section */}
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
            <CardBody>
               <Form  className="form-horizontal">
                  <Row>
                     {filterType === 'referralData' && <>
                        <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">Filter</Label>
                              <Input
                              type="text"
                              className="form-control"
                              placeholder={"Filter Data..."}
                              value={searchTerm}
                              onChange={async (e) => {
                                 setSearchTerm(e.target.value)
                                 await retrieveFilteredData(e.target.value, from, to, userEmails?.value, filterChecks);
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">From</Label>
                              <Input
                              name="from"
                              className="form-control"
                              type="date"
                              max={to}
                              value={from}
                              onChange={async (e) => {
                                 setFrom(e.target.value)
                                 await retrieveFilteredData(searchTerm, e.target.value, to, userEmails?.value, filterChecks)
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">To</Label>
                              <Input
                              name="to"
                              className="form-control"
                              type="date"
                              disabled={from == "" ? true : false}
                              title={from == "" ? "Please select from date" : ""}
                              min={from}
                              value={from == "" ? "" : to}
                              onChange={async (e) => {
                                 setTo(e.target.value)
                                 await retrieveFilteredData(searchTerm, from, e.target.value, userEmails?.value, filterChecks)
                              }}
                              />
                           </div>
                        </div>
                     {(JSON.parse(localStorage.getItem('authUser')).role_id == 1) && 
                        (
                           <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                              <div className="mb-3">
                                 <Label htmlFor="referredby" className="form-label">Referred By</Label>
                                 <Select
                                    options={emailList}
                                    placeholder="Select Email"
                                    value={userEmails}
                                    // onChange={value => handleEmailFilter(value)}
                                    onChange={async (value) => {
                                       setUserEmails(value)
                                       await retrieveFilteredData(searchTerm, from, to, (value? value.value : ""), filterChecks)
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                 />
                              </div>
                           </div>)}
                  </>}
                  {filterType === 'paymentHistory' && 
                     <div style={{position:"relative"}}>
                           <label htmlFor={"group-reference-email"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99", marginRight: "30px"}} >
                               <input 
                                 type={"checkbox"} 
                                 id={"group-reference-email"} 
                                 name="reference" 
                                 value={filterChecks}
                                 onChange={async (e) => {
                                    setFilterChecks(e.target.checked)
                                    await retrieveFilteredData(searchTerm, from, to, userEmails?.value, e.target.checked)
                                 }}
                                 /> 
                                 <span style={{marginLeft: "10px"}}>Group By Reference Email</span>
                           </label>
                            {/* <label htmlFor={"group-payment-date"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99"}} onClick={(e) => {handleCheckbox('payment')}}>
                               <input type={"checkbox"} id={"group-payment-dates"} name="payment" checked={selectedCheck === 'payment' ? true : ""}/> <span>Group By Payment Date</span>
                            </label> */}
                        </div>
                     }
                  </Row>
               </Form>
               
               {filterType == 'paymentHistory' && filterChecks == true ? (Object.entries(paymentGroupData).map(([user_email, items], i) => {
                  return (
                     <div style={{ paddingTop: "10px" }} key={i}>
                        {/* <DataTableExtensions
                      {...{payment_history_columns, items}}
                      // exportHeaders={true}
                      filterPlaceholder={`Filter...`}
                    > */}
                        <h5>{user_email}</h5>
                        <DataTable
                           columns={payment_history_columns}
                           data={items}
                           // data={filteredData}
                           pagination={50}
                           // searchable
                           // progressPending={pending}
                           progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                           paginationPerPage={50}
                           paginationRowsPerPageOptions={[50, 100, 200, 500]}
                           highlightOnHover={true}
                           // onChangePage={handleOnPageChange}
                           // onRowClicked={handleRowClick}
                           // expandableRows={true} expandableRowsComponent={expandableRows}
                           // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                           className={"order-table"}
                           theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                        />
                     {/* </DataTableExtensions> */}
                     </div>
                  )})) : (<DataTable
                  columns={filterType == 'paymentHistory' ? payment_history_columns :  referral_data_columns}
                  data={ filterType == 'paymentHistory' ? paymentData : dataToDisplay}
                  // data={filteredData}
                  pagination={50}
                  // searchable
                  // progressPending={pending}
                  progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  highlightOnHover={true}
                  // onChangePage={handleOnPageChange}
                  // onRowClicked={handleRowClick}
                  // expandableRows={true} expandableRowsComponent={expandableRows}
                  // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                  className={"order-table"}
                  theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
               />)}
            </CardBody>
          </Card>
         
      </div>
   )
};

export default ReferralAdmin