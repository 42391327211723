// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import {

  Card,
  CardBody,
  Label,
  Input,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import config from '../../../config';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import { Country, State } from "country-state-city"

function ShopifyStores(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalSetting, setModalSetting] = useState(false);
  const [storeName, setStoreName] = useState("");

  const [store_id, setStoreId] = useState(null);

  const [numberOfDays, setNumberOfDays] = useState(null);

  const [modalUpdateDetails, setModalUpdateDetails] = useState(false);
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");

  function capitalizeWords(str) {
    // split the string into an array of words
    let words = str.split(' ');

    // capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    // join the words back together into a string and return it
    return words.join(' ');
  }

  const columns = [
    // {
    //   name: 'User Email',
    //   selector: 'email',
    //   sortable: true,
    // },
    {
      name: 'Store Name',
      selector: 'name',
      // width:"200px",
      sortable: true,
    },
    // {
    //   name: 'Access Token',
    //   selector: 'access_token',
    //   width:"250px",
    //   sortable: true,
    // },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      // width: "150px",
      cell: row => row.status == 1 ? <span className={"badge badge-success"}>Connected</span>:<span className={"badge badge-danger"}>Inactive</span>
      // cell: row => row.charge_status == "charge.created" ? <span className={"badge badge-danger"}>Pending</span>:<span className={"badge badge-success"}>Charged</span>
    },
    {
      name: 'Updated At',
      selector: 'updated_at',
      sortable: true,
      // width:"170px",
      cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      selector: ['id'],
      width: "300px",
      cell: (row) => <>
          <button className={"btn btn-success btn-sm waves-effect waves-light"} title="Sync Store Store" style={{marginRight:"10px"}} onClick={() => handleRefresh(row.id)}><i className="mdi mdi-sync" /></button>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} title="Update Store Details" style={{marginRight:"10px"}} onClick={() => handleUpdateStoreDetails(row.id)}><i className={"bx bx-edit"}/></button>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} title="Update Store Settings" style={{marginRight:"10px"}} onClick={() => handleSettings(row.id)}>Settings</button>
          <button className={"btn btn-danger btn-sm waves-effect waves-light"} title="Disconnect Store" onClick={() => handleDisconnect(row.id)}>Disconnect</button>
        </>
    },
  ];

  const handleUpdateStoreDetails = async (id) => {
    get('user/get-store-details/'+id, {headers}).then((response) => {
    // console.log("reee",response);
      setStoreId(id);
      setStreet1(response.data.address1);
      setStreet2(response.data.address2);
      setCity(response.data.city);
      setState(response.data.state);
      setZip(response.data.zip);
      setCountry(response.data.country);
      setPhone(response.data.phone);
      setModalUpdateDetails(true);
    });
  }

  const handleSettings = async (id) => {
    setStoreId(id);
    setModalSetting(true);
    get(`/shopify/store-settings/${id}`, {headers}).then((response) => {
      // console.log("response",response);
      if(response.status === 200) {
        setNumberOfDays(response.data.number_of_days);
      }
    })
  }

  const handleRefresh = async (id) => {
    const conf = confirm("Store details will overwrite, Are you sure you want to refresh the store?");
    if(conf) {
      let body = {
        store_id: id,
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post(`/shopify/refresh-store`, body, { headers }).then(async (response) => {
        // console.log("response",response);
        if (response.status === 200) {
          toast.success(response.message);
          await retrieveStores();
        } else {
          toast.error(response.message);
        }
      })
    }
  }

  const handleDisconnect = async (id) => {
    const confir = confirm("Are you sure you want to disconnect this store from NullShip?");
    if(confir) {
      const loading = toast.loading("Disconnecting store...");
      try {
        let body = {
          id: id
        };
        post('/shopify/disconnect', body, {headers}).then((response) => {
          // console.log("response", response);
          toast.remove(loading);
          if (response.status === 200) {
            toast.success(response.message);
            retrieveStores();
            setTimeout(() => {
              location.reload();
            });
            //
          }
          else {
            toast.error(response.message);
          }
        })
      }
      catch (e) {
        // console.log(e)
      }
    }
  }

  // const tableData = ;
  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/shopify/getStores', body, {headers}).then((response) => {
        // console.log("response", response);
        setData(response.data);
        let data = response.data;
        setTableData({ columns, data });
      })
    }
    catch (e) {
      // console.log(e)
    }
  }



  useEffect(() => {
    // // console.log("here");
    retrieveStores();
  }, []);

  const handleConnect = async () => {
    try {
      // console.log("url",`/shopify/auth?shop=${storeName}`);
      if(storeName.includes('.myshopify.com')) {
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id
        }
        post(`/shopify/auth/internal?shop=${storeName}`, body, {headers}).then((response) => {
          // console.log(response);
          if (response.status === 200) {
            location.href = response.url;
            // window.open(response.url, '_blank');
          }
          else {
            toast.error(response.message);
          }
        });
      }
      else {
        toast.error("Incomplete shop name")
      }
    }
    catch (e) {
      // console.log("e",e);
    }
  }

  const expandableRows = (row) => {
    return (
      <>
        <div className={"col-md-10 offset-md-1 p-2 row"}>
          <h5>Store Details</h5>
          <div className={"col-md-6"}>
            <table className={"table"}>
              <tr>
                <th>Phone</th>
                <td>{row.data.phone?row.data.phone:"-"}</td>
              </tr>
              <tr>
                <th>Address 1</th>
                <td>{row.data.address1?row.data.address1:"-"}</td>
              </tr>
              <tr>
                <th>Address 2</th>
                <td>{row.data.address2?row.data.address2:"-"}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{row.data.city?row.data.city:"-"}</td>
              </tr>
            </table>
          </div>
          <div className={"col-md-6"}>
            <table className={"table"}>
              <tr>
                <th>State</th>
                <td>{row.data.state?row.data.state:"-"}</td>
              </tr>
              <tr>
                <th>Zip</th>
                <td>{row.data.zip?row.data.zip:"-"}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{row.data.country?row.data.country:"-"}</td>
              </tr>
              <tr>
                <th>Store URL</th>
                <td><a href={"https://" + row.data.store_name} rel={"noreferrer"} target={"_blank"}>https://{row.data.store_name}</a></td>
              </tr>
            </table>
          </div>
        </div>
      </>
    )
  }

  const handleUpdateSettings = async () => {
    if(numberOfDays && numberOfDays > 0) {
      let body = {
        numberOfDays,
        store_id
      }
      post('/shopify/update-store-settings', body, {headers}).then((response) => {
        setStoreId("");
        if(response.status === 200) {
          toast.success(response.message);
          setModalSetting(false);
          setNumberOfDays(null);
        }
        else{
          toast.error(response.message);
        }
      })
    }
  }

  const handleCountry = async (e) => {
    const { value } = e.target;
    setCountry(value);
  }

  const handleAddressStreet1 = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      // console.log("results", results);
      const addressComponents = results[0].address_components;
    // console.log("addressComponents", addressComponents);
      let street1 = "";
      let street2 = "";
      let country = "";
      let city = "";
      let state = "";
      let zip = "";
      addressComponents.map((el) => {
      // console.log("type", el.types[0], "long name", el.long_name);
        if(el.types[0] === "street_number") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "route") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "premise") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "subpremise") {
          street2 += el.long_name + " ";
        }
        else if(el.types[0] === "locality") {
          city += el.long_name;
        }
        else if(el.types[0] === "administrative_area_level_1") {
          state += el.short_name;
        }
        else if(el.types[0] === "country") {
          country += el.short_name;
        }
        else if(el.types[0] === "postal_code") {
          zip += el.long_name;
        }
      })
      if(street1 !== "") {
        street1 = street1.substring(0, (street1.length - 1));
      }

      if(street2 !== "") {
        street2 = street2.substring(0, (street2.length - 1));
      }
      setStreet1(street1);
      setStreet2(street2);
      setCity(city);
      setCountry(country);
      setState(state);
      setZip(zip);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleStoreDetailsSubmit = async (e) => {
    e.preventDefault();

    if(phone.toString().length < 10) {
      toast.error("Phone number must contain 10 digit");
      return false;
    }

    const body = {
      store_id: store_id,
      street1: street1,
      street2: street2,
      country: country,
      state: state,
      zip: zip,
      city: city,
      phone: phone
    }

    post('user/update-store-details', body, {headers}).then((response) => {
      if(response.status == 200) {
        toast.success(response.message);
        setStoreId("");
        setStreet1("");
        setStreet2("");
        setCity("");
        setState("");
        setZip("");
        setCountry("");
        setPhone("");
        setModalUpdateDetails(false);
        retrieveStores();
      }
      else{
        setStoreId("");
        setStreet1("");
        setStreet2("");
        setCity("");
        setState("");
        setZip("");
        setCountry("");
        setPhone("");
        setModalUpdateDetails(false);
        toast.error(response.message);
      }
    })
  }

  //meta title
  document.title = `Shopify Stores | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Shopify" breadcrumbItem="Stores" />
        {/*<Card>*/}
        {/*  <CardBody>*/}
        {/*    <button onClick={()=>setModal(!modal)} className={"btn btn-success btn-sm"}>Connect Shopify Store</button>*/}
        {/*  </CardBody>*/}
        {/*</Card>*/}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Shopify Store...`}
            >
              <DataTable columns={columns} data={data} pagination={30} paginationPerPage={30}
                         highlightOnHover={true} expandableRows={true} expandableRowsComponent={expandableRows}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>

      {/*Modal*/}
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModal(!modal);
            }}
          >
            Connect Shopify Store
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <Label className="form-label">Store Name <span style={{ color: "lightgray" }}>(i.e. my-store.myshopify.com)</span></Label>
              <Input
                name="from_name"
                // value={name}
                className="form-control"
                placeholder="Enter Shopify Store Name"
                type="text"
                required={true}
                onChange={(e) => setStoreName(e.target.value)}
                value={storeName}
              />
            </div>
            <br/>
            <button onClick={handleConnect} className={"btn btn-success btn-sm"} style={{float:"right"}}>Connect</button>
            <br/>
            <br/>

          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}

      {/*Modal*/}
      <Modal
        isOpen={modalSetting}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModalSetting(!modalSetting);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalSetting(!modalSetting);
            }}
          >
            Store Settings
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              e.preventDefault();
              handleUpdateSettings();
            }}>
              <div className="form-group">
                <Label className="form-label">Fetch Record Of Last</Label>
                <Input
                  name="from_name"
                  // value={name}
                  className="form-control non-arrow-number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter Number Of Days"
                  type="number"
                  min="1"
                  required={true}
                  onChange={(e) => setNumberOfDays(e.target.value)}
                  value={numberOfDays}
                />
              </div>
              {/*<br/>*/}
              {/*<div className="form-group">*/}
              {/*  <Label className="form-label">Show Orders</Label>*/}
              {/*  <div className="form-check form-check-end">*/}
              {/*    <input*/}
              {/*      className="form-check-input"*/}
              {/*      type="checkbox"*/}
              {/*      value={paidCheckbox}*/}
              {/*      onChange={handlePaidCheckbox}*/}
              {/*    />*/}
              {/*    <label*/}
              {/*      className="form-check-label"*/}
              {/*      htmlFor="customCheckbox"*/}
              {/*    >*/}
              {/*      Paid*/}
              {/*    </label>*/}
              {/*  </div>*/}
              {/*  <div className="form-check form-check-end">*/}
              {/*    <input*/}
              {/*      className="form-check-input"*/}
              {/*      type="checkbox"*/}
              {/*      value={partiallyPaidCheckbox}*/}
              {/*      onChange={handlePartiallyPaidCheckbox}*/}
              {/*    />*/}
              {/*    <label*/}
              {/*      className="form-check-label"*/}
              {/*      htmlFor="customCheckbox"*/}
              {/*    >*/}
              {/*      Partially Paid*/}
              {/*    </label>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <br/>
              <button type={"submit"} className={"btn btn-success btn-sm"} style={{float:"right"}}>Save</button>
              <br/>
              <br/>
            </Form>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}
      {/*Modal*/}
      <Modal
        isOpen={modalUpdateDetails}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          setModalUpdateDetails(!modalUpdateDetails);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalUpdateDetails(!modalUpdateDetails);
            }}
          >
            Update Store Details
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleStoreDetailsSubmit}>
              <div className={"row"}>
                <div className={"col-md-4"}>
                  <div className="form-group">
                    <Label className="form-label">Phone <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      name="phone"
                      // value={name}
                      className="form-control non-arrow-number"
                      placeholder="Enter Phone"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"col-md-4"}>
                  <PlacesAutocomplete
                    value={street1}
                    onChange={setStreet1}
                    onSelect={handleAddressStreet1}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{position:"relative"}}>
                        <div className="form-group">
                          <Label className="form-label">Address Street 1 <span style={{ color: "red" }}>*</span></Label>
                          <Input
                            name="from_street1"
                            // value={name}
                            className="form-control"
                            {...getInputProps({ placeholder: "Enter Ship To Street 1" })}
                            type="text"
                            // onChange={(e) => setFromStreet1(e.target.value)}
                            required={true}
                          />
                        </div>
                        <div style={{position:"absolute",top:"65px",left:"0px",backgroundColor:"white",zIndex:"99",width:"100%"}}>
                          {loading ? <div style={{backgroundColor: localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1"}}>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? localStorage.getItem("theme") == "dark"? "#333":"#ff6600" : localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1",
                              padding: "5px 10px",border:"1px solid #efefef",
                              cursor: "pointer"
                            };
                            return (
                              <div
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className={"col-md-4"}>
                  <div className="form-group">
                    <Label className="form-label">Address Street 2</Label>
                    <Input
                      name="address2"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Address Street 2"
                      type="text"
                      onChange={(e) => setStreet2(e.target.value)}
                      value={street2}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-12 mt-2"}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Country <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"country"}
                        className="form-select"
                        onChange={handleCountry}
                        value={country}
                        required={true}
                      >
                        <option disabled={true} selected={country?false:true} value={""}>Select a country</option>
                        {Country.getAllCountries().map((part, id) => {
                          return (<option key={id} value={part.isoCode}>{part.name}</option>);
                        })};
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">City <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="to_city"
                        // value={name}
                        className="form-control"
                        placeholder="Enter City"
                        type="text"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Zip <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="to_zip"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Zip"
                        type="text"
                        onChange={(e) => setZip(e.target.value)}
                        value={zip}
                        required={true}
                        // minLength={5}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">State <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"to_state"}
                        className="form-select"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                        required={State.getAllStates().filter(el => el.countryCode === country).length>0?true:false}
                      >
                        <option disabled={true} value={""}>Select a state</option>
                        {State.getAllStates().sort((a, b) => a.name.localeCompare(b.name)).filter(el => el.countryCode === country).map((part, id) => {
                          return (<option key={id} value={part.isoCode}>{part.name}</option>);
                        })};
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <button type={"submit"} className={"btn btn-success btn-sm"} style={{float:"right"}}>Update</button>
              <br/>
              <br/>
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}
    </div>
  );
}
ShopifyStores.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default ShopifyStores;