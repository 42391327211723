// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import axios from "axios"
import JSZip from "jszip"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import config from "./../../../config"

function DownloadPdf(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };


  const [orderUniqueId, setOrderUniqueId] = useState("");


  // const tableData = ;
  const handleDownload = async () => {
    try {
      if(orderUniqueId) {
        let loading = toast.loading("Downloading pdf...")
        try {
          const zip = new JSZip();
          await axios.get( `${baseUrl()}/user/download-pdf/${orderUniqueId}`, { headers, responseType: "blob" })
            .then(async (response) => {
              // console.log("response.data.status",response);
              if(response && response.data.size > 45) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                await zip.file(`${response.headers["file-name"]}`, blob)
              }
              else {
                toast.error("File not found");
              }
            }).catch(() => {
              // console.log("Something went wrong, Please try again")
              toast.remove(loading)
              toast.error("Something went wrong");
            });
          // console.log("zip",Object.keys(zip.files).length)
          if(Object.keys(zip.files).length > 0 ){
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, "orders.zip")
              toast.remove(loading)
              toast.success("Pdf downloaded successfully");
              setOrderUniqueId("");
            });
          }
          else {
            toast.remove(loading);
            setOrderUniqueId("");
          }
        }
        catch (e) {
          toast.remove(loading)
          toast.error("Something went wrong");
        }
      }
      else {
        toast.error("Please enter order unique id");
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  //meta title
  document.title = `Download Pdf | ${config.APP_DETAILS.APP_NAME}`;


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Download Pdf" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Order Information</CardTitle>
                <div className="">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleDownload()
                      return false;
                    }}
                  >

                    <div className="mb-3">
                      <Label className="form-label">Order Unique Id <span className={"text-secondary"}>(i.e. ns_xxxxxxxxxxx)</span></Label>
                      <Input
                        name="orderUniqueId"
                        className="form-control non-arrow-number"
                        placeholder="Enter Order Unique Id"
                        type="text"
                        value={orderUniqueId}
                        onChange={(e) => {setOrderUniqueId(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/dashboard') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Download
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
DownloadPdf.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default DownloadPdf;