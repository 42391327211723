import PropTypes from 'prop-types';
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom"
import { connect } from "react-redux";

// Import Routes all
import { authProtectedAdminRoutes, authProtectedUserRoutes, authProtectedBloggerRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { get } from "./helpers/api_helper"
import { Toaster } from "react-hot-toast"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  useEffect(() => {
  }, [])

  const checkUserRole = (allowedRoles) => {
    // console.log("localStorage.getItem('authUser')",JSON.parse(localStorage.getItem('authUser')).role_id);
    const userRole = localStorage.getItem('authUser')?
    (JSON.parse(localStorage.getItem('authUser')).role_id == 1?"admin":(JSON.parse(localStorage.getItem('authUser')).role_id == 2 || JSON.parse(localStorage.getItem('authUser')).role_id == 4?"user":"blogger")):"";
    if (!allowedRoles.includes(userRole)) {
      return false;
    }
    return true;
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName={"zin"}
      />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          { checkUserRole(['admin']) ? authProtectedAdminRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          )): (checkUserRole(['user']) ? authProtectedUserRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          )): (checkUserRole(['blogger']) ? authProtectedBloggerRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            )):
            <Redirect to={{pathname: '/login'}}/>
          ))
          }

        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
