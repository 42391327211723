import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import reloadIcon from '../../assets/images/layouts/refreshleftsquare.png';
import config from "./../../config";

const Breadcrumb = props => {
  const handleReload = () => {
    props.reloadPage();
  }

  return (
    <Row>
      <Col className="col-12 brdcrm" style={{marginTop:props.isOrder?'-21px':"0px"}}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {props.breadcrumbItem!="Documentation"?
            <h4 className="mb-sm-0 font-size-18">
            {props.breadcrumbItem}
             {props.breadcrumbItem=="Awaiting Shipment"?
             <span style={{fontSize:"12px",
              paddingTop:"2px",color:"#7bb2f2",textTransform:"none",fontWeight:"500",marginLeft:"20px",cursor:"pointer",verticalAlign:"middle"}} onClick={handleReload}>
              <img src={reloadIcon} width={"13px"} style={{marginTop:"-2px"}}/> Reload</span>:""} 
              {props.sourceItem == "Logs" ? props.sourceItem : ""}</h4>:
              <h4 className="mb-sm-0 font-size-18"></h4>}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                {/* <Link to="/dashboard">{props.title}</Link> */}
                <Link to="/dashboard">{config.APP_DETAILS.APP_NAME}</Link>
              </BreadcrumbItem>
              {props.sourceItem?
                <BreadcrumbItem>
                  <Link to="#">{props.sourceItem}</Link>
                </BreadcrumbItem>:""}
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
