// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.xlsx"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import defaultLoader from '../../../assets/images/default_loader.gif'
import { initGA } from "../../../assets/js/gtag"
import customStyles from "../../../assets/css/customTableStyle"
let selectedOrders = [];
function OrderCancellation(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pending, setPending] = useState(true)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [viewModal, setViewModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)

  const [bulkRefundModal, setBulkRefundModal] = useState(false)
  const [bulkRefundData, setBulkRefundData] = useState([])

  const [totalRefundAmount, setTotalRefundAmount] = useState("")

  const [rowId, setRowId] = useState();

  const [scrolling, setScrolling] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [filterType, setFilterType] = useState(
    localStorage.getItem("order-filter")
      ? localStorage.getItem("order-filter")
      : "FedEx"
  )

  const [invoiceData, setInvoiceData] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)

  const [refundCompletedOrderId, setRefundCompletedOrderId] = useState("");
  const [refundOrderId, setRefundOrderId] = useState("");
  const [refundOrderUniqueId, setRefundOrderUniqueId] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundUserId, setRefundUserId] = useState("");
  const [refundUserEmail, setRefundUserEmail] = useState("");

  const [bulkRefundAmount, setBulkRefundAmount] = useState("");

  const columns = [ {
      name: <input type={"checkbox"} title={"Select All"} className={"select-all-checkbox"}
                   id={"select-all-checkbox"} onChange={checkAll}/>
    ,
      // selector: ["id", "name"],
      // sortable: true,
      width: "48px",
      selector: row => <input type={"checkbox"} className={"order-checkbox"} id={`order-${row.order_id}`} value={row.order_id}
                          name="order_id" onChange={handleOrderCheck}/>
    },
    {
      name: 'User',
      // selector: 'email',
      sortable: true,
      width: "250px",
      selector: row => row.email !== null ? row.email : "-"
    },
    {
      name: "Service Name",
      selector: row => row.service_name,
      //  sortable: true,
      width: "180px",
    },
    {
      name: "Price",
      selector: row => row.discounted_price,
      //  sortable: true,
      width: "150px",
    },
    {
      name: "To Name",
      // selector: row => row.to_name,
      //  sortable: true,
      // width: "160px",
      selector: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: "Tracking Code",
      // selector: ["tracking_code"],
      sortable: false,
      width: "260px",
      selector: row =>
        row.tracking_code ? (
          <>
            {/*<a*/}
            {/*  target={"_blank"}*/}
            {/*  rel={"noreferrer"}*/}
            {/*  href={`/track/${row.tracking_code}`}*/}
            {/*>*/}
              {row.tracking_code}
            {/*</a>*/}
            <span style={{ color: "#e3e3e3", cursor: "pointer",marginLeft:"5px" }} className={"bx bx-clipboard"} title={"Copy Tracking Number"} onClick={() => {navigator.clipboard.writeText(row.tracking_code);toast.success("Copied!")}}></span>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   name: "Cancellation Status",
    //   selector: ["cancel_status"],
    //   sortable: false,
    //   width: "180px",
    //   cell: row =>
    //     row.cancel_status ? (
    //       <span className={"badge badge-success"}>{row.cancel_status}</span>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      name: "Cancelled At",
      // selector: "cancel_at",
      sortable: true,
      width: "180px",
      selector: row => moment(row.cancel_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
    },
    {
      name: "Actions",
      // selector: ["id", "order_id", "discounted_price", "order_unique_id", "user_id", "email"],
      //  sortable: true,
      width: "150px",
      button: true,
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      selector: row => (
        <>
          <button title={"View Order Details"} style={{ marginRight: "10px" }}
                  className={"btn btn-warning btn-sm"}
                  onClick={() => {
                    setRowId(row.order_id)
                    setViewModal(true)
                  }}
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
          <button title={"Refund Order"} style={{ marginRight: "10px" }}
                  className={"btn btn-success btn-sm"}
                  onClick={() => handleRefundInitiate(row.id, row.order_unique_id, row.discounted_price, row.user_id, row.email, row.order_id)}
          >
            <i className={"bx bx-check"}></i>
          </button>
          <button title={"Reject Cancel Request"} style={{ marginRight: "10px" }}
                  className={"btn btn-danger btn-sm"}
                  onClick={() => handleReject(row.id, row.order_unique_id, row.user_id)}
          >
            <i className={"bx bx-x"}></i>
          </button>
        </>
      ),
    },
    {
      name: '',
      selector: row => row.order_unique_id,
      width: "0px"
    },
  ];

  function checkAll(e) {
    const { checked } = e.target;
    let checkboxes = document.getElementsByClassName('order-checkbox');
    selectedOrders = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false;
        selectedOrders = [];
      }
      else {
        checkboxes[i].checked = true;
        if(!selectedOrders.includes(checkboxes[i].value)) {
          selectedOrders.push(checkboxes[i].value);
        }
      }
    }
    setSelectedOrdersCount(selectedOrders.length);
  }

  const handleOrderCheck = async e => {
    const { value, checked } = e.target;
    if (checked) {
      if(!selectedOrders.includes(value)) {
        selectedOrders.push(value);
      }
    } else {
      let index = selectedOrders.findIndex(el => el == value);
      selectedOrders.splice(index, 1);
    }
    setSelectedOrdersCount(selectedOrders.length);

    document.getElementsByClassName("right-bar-enabled")[0].click();
  }


  const handleRefundInitiate = (completed_order_id, order_unique_id, discounted_price, user_id, email, order_id) => {
    setRefundCompletedOrderId(completed_order_id);
    setRefundOrderId(order_id);
    setRefundOrderUniqueId(order_unique_id);
    setRefundAmount(discounted_price);
    setRefundUserId(user_id);
    setRefundUserEmail(email);
    setRefundModal(true);
  }

  function uncheckAll() {
    let selectAllCheckbox = document.getElementsByClassName(
      "select-all-checkbox"
    )
    let checkboxes = document.getElementsByClassName("order-checkbox")
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  const retrieveOrdersCancelled = async (type) => {
    localStorage.setItem("order-filter", type)
    let data = [];
    setFilteredData(data);
    setTableData({columns, data});
    setPending(true);
    setLoadingOrders(true);
    try {
        let body = {
          carrier: type
        }
        post("/admin/get-all-orders-cancelled-by-carrier", body, { headers }).then(
          response => {
            setLoadingOrders(false);
            if(response.status === 200) {
              let data = response.data;
              setFilteredData(data);
              setTableData({columns, data});
            }
            setPending(false);
          }
        )
    } catch (e) {
    }
  }

  useEffect(() => {
    retrieveOrdersCancelled(filterType);
  }, [])

  const handleFedExOrders = async () => {
    if(!loadingOrders) {
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length)
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("FedEx")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("FedEx")
    }
  }

  const handleUPSOrders = async () => {
    if(!loadingOrders) {
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length)
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("UPS")
    }
  }

  const handleUPSv2Orders = async () => {
    if(!loadingOrders) {
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length)
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPSv2")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("UPSv2")
    }
  }

  const handleDHLOrders = async () => {
    if(!loadingOrders) {
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length)
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("DHL")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("DHL")
    }
  }

  const handleUSPSOrders = async () => {
    if(!loadingOrders) {
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length)
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("USPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("USPS")
    }
  }

  const handleOnPageChange = () => {
    document.getElementById("select-all-checkbox").checked = false;
    if(selectedOrders.length > 0) {
      for (let i=0;i<selectedOrders.length;i++) {
        if(document.getElementById(`order-${selectedOrders[i]}`)) {
          document.getElementById(`order-${selectedOrders[i]}`).checked = true;
        }
      }
    }
  }

  // function uncheckAll() {
  //   let checkboxes = document.getElementsByClassName("order-checkbox");
  //   selectedOrders = [];
  //   let selectAllCheckbox = document.getElementsByClassName(
  //     "select-all-checkbox"
  //   )
  //   // console.log("checkboxes",checkboxes);
  //   if (checkboxes.length > 0) {
  //     selectAllCheckbox[0].checked = false
  //     for (let i = 0; i < checkboxes.length; i++) {
  //       checkboxes[i].checked = false
  //     }
  //   }
  // }


  const handleRowClick = row => {
    document.getElementById(`order-${row.order_id}`).click();
  }

  const handleRefund = async () => {
    try {
      let body = {
        completed_order_id: refundCompletedOrderId,
        refunding_amount: refundAmount,
        user_id: refundUserId,
        email: refundUserEmail,
        order_id: refundOrderId,
        order_unique_id: refundOrderUniqueId
      }
    // console.log("body", body);

      post('/admin/order-refund', body, {headers}).then((response) => {
        if(response.status == 200) {
          toast.success(response.message);
          setRefundCompletedOrderId("")
          setRefundOrderId("")
          setRefundAmount("")
          setRefundUserId("")
          setRefundUserEmail("")
          retrieveOrdersCancelled(localStorage.getItem("order-filter"));
          setRefundModal(false);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {

    }
  }

  const handleBulkRefund = async () => {
    try {
      document.body.style.pointerEvents = "none";
      let loading = toast.loading("Loading...");
      if (selectedOrders.length > 0 && bulkRefundData.length > 0) {
        let body = {
          bulkRefundData: JSON.stringify(bulkRefundData)
        }

        post('/admin/bulk-order-refund', body, {headers}).then((response) => {
          toast.remove(loading);
          document.body.style.pointerEvents = "auto";
          if(response.status === 200) {
            toast.success(response.message);
            retrieveOrdersCancelled(localStorage.getItem("order-filter"));
            setBulkRefundModal(false);
            setBulkRefundData([]);
            setBulkRefundAmount("");
          }
          else {
            toast.error(response.message);
          }
        })
      }
      else {
        console.log("selected order or bulk refund data is null")
      }
    }
    catch (e) {

    }
  }

  const handleReject = async (id, order_unique_id, user_id) => {
    try {
      if(confirm('Are you sure! you want to reject the order?')) {
        let body = {
          completed_order_id: id,
          order_unique_id: order_unique_id,
          user_id: user_id
        }
      // console.log("body", body);

        post('/admin/order-reject', body, {headers}).then((response) => {
          if(response.status == 200) {
            toast.success(response.message);
            retrieveOrdersCancelled(localStorage.getItem("order-filter"));
          }
          else {
            toast.error(response.message);
          }
        })
      }
    }
    catch (e) {

    }
  }

  const expandableRows = (row) => {
  // console.log("row",row);
    return (
      <>
        <div className={"col-md-12 mt-2 mb-2 row p-2"} style={{backgroundColor:"#f7f5f5"}}>
          <h5>Item Details</h5>
          <div style={{display:"flex", justifyContent:"start", alignItems:"center", flexWrap:"wrap"}}>
            {row.data.is_custom_form === 1 ?
              (row.data.custom_items_data?.length > 0 ?
                JSON.parse(row.data.store_type=="ebay"?JSON.parse(row.data.custom_items_data):row.data.custom_items_data).map((el, id) => {
                  if(row.data.store_type == null) {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:"white"}}>
                        <h5>{el.description ? el.description : "-"} <span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span></h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'shopify') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:"white"}}>
                        <h5>{el.name ? el.name : "-"} <span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span></h5>
                        <p>{el.price ? el.price : "-"} {el.price_set.shop_money.currency_code}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'ebay') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:"white"}}>
                        <h5>{el.title ? el.title : "-"} <span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span></h5>
                        <p>{el.lineItemCost.value} {el.lineItemCost.currency}</p>
                      </div>
                    )
                  }
                })
                : "")
              : <h6 style={{textAlign:"center"}}>No Item Found</h6>}
          </div>
        </div>
      </>
    )
  }

  const handleBulKCancel = () => {
    if(selectedOrders.length > 0) {
      document.body.style.pointerEvents = "none";
      let loading = toast.loading("Loading...");
      const body = {
        order_ids: selectedOrders.toString()
      }
      post('/admin/get-bulk-cancellation-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        if(response.status === 200) {
          setBulkRefundData(response.data);
          if(response.data.length > 0) {
            const sum = response.data.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.amount;
            }, 0);
            setTotalRefundAmount(sum);
          }
          setBulkRefundModal(true);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    else {
      toast.error("Please select at least 1 order")
    }
  }

  //meta title
  document.title = `Cancellation Requests | ${config.APP_DETAILS.APP_NAME}`

  return (
    <div className="page-content" style={{position:"relative"}}>
      <span style={{fontSize:"12px",fontWeight:"normal",position:"fixed", bottom:"75px",boxShadow:"0px 0px 4px 5px rgba(0,0,0,0.05)", right:"0px",padding:"5px", backgroundColor:(localStorage.getItem("theme") == "dark"?"#333":"white"),zIndex:"999", display: (selectedOrdersCount>0?"block":"none")}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>
      {/*<span style={{fontSize:"12px",fontWeight:"normal",position:"fixed", bottom:"80px", right:"0px",padding:"5px", backgroundColor:"white",zIndex:"999", display: (selectedOrdersCount>0?"block":"none")}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>*/}
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Cancellation Requests" />
        <Card>
          <CardBody>
            <button
              className={"btn btn-success waves-effect waves-light m-1"}
              onClick={handleBulKCancel}
              disabled={selectedOrders.length === 0}
            >
              Bulk Refund
            </button>
          </CardBody>
        </Card>
        {/* <Table columns={columns} data={data} /> */}
        <div
          style={{ width: "fit-content", background: "#E1E5E8" }}
          className="rounded-top"
        >
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "FedEx" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleFedExOrders}
            disabled={pending}
          >
            FedEx
          </button>
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "UPS" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUPSOrders}
            disabled={pending}
          >
            UPS
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "UPSv2" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUPSv2Orders}
            disabled={pending}
          >
            UPSv2
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "DHL" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleDHLOrders}
            disabled={pending}
          >
            DHL
          </button>
          <button
            className={
              "btn btn-md  " +
              (filterType === "USPS" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUSPSOrders}
            disabled={pending}
          >
            USPS
          </button>
        </div>
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
          <CardBody>
            {/*<h1>page {page}</h1>*/}
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Cancellation Requests...`}
            >
              <DataTable
                columns={columns}
                data={filteredData} customStyles={customStyles}
                pagination={50}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
                // onChangePage={handleOnPageChange}
                onRowClicked={handleRowClick}
                // expandableRows={true} expandableRowsComponent={expandableRows}
                // expandableRowDisabled={row => row.is_custom_form==0?true:false}
                className={"order-table"}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

        {/*Modal*/}
        <Modal
          isOpen={refundModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setRefundModal(!refundModal)
          }}
          onClosed={() => {
            setRefundCompletedOrderId("")
            setRefundOrderId("")
            setRefundAmount("")
            setRefundUserId("")
            setRefundUserEmail("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setRefundModal(!refundModal)
              }}
            >
              Refund Order ({refundOrderUniqueId})
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleRefund();
                }}
              >
                <label>Refunding Amount</label>
                <input type={"number"} step="any" onWheel={(e) => e.target.blur()} value={refundAmount} required={true} placeholder={"Enter Refunding Amount"} onChange={(e) => setRefundAmount(e.target.value)} className={"form-control non-arrow-number"}/>
                <br />
                <button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Refund
                </button><br/><br/>
                <p className={"text-danger"}>Refunding {refundAmount?"$"+refundAmount:""} to {refundUserEmail}</p>
              </Form>
              {/*<br />*/}
              {/*<br />*/}
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Bulk Modal*/}
        <Modal
          isOpen={bulkRefundModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setBulkRefundModal(!bulkRefundModal)
          }}
          onClosed={() => {
            uncheckAll();
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            setBulkRefundData([]);
            setBulkRefundAmount("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setBulkRefundModal(!bulkRefundModal)
              }}
            >
              Bulk Refund Orders
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleBulkRefund();
                }}
              >
                <h4>Refunding Amount</h4>
                {
                  bulkRefundData.length>0?
                    bulkRefundData.map((el, index) => (
                      <div style={{border:"2px solid lightgray",borderRadius:"5px",padding:"10px",marginTop:"10px"}} key={index}>
                        <p style={{marginBottom:"0px"}}><b>Email:</b> {el.email}</p>
                        <p style={{marginBottom:"0px"}}><b>Amount:</b> ${el.amount}</p>
                      </div>
                    )):null
                }
                <br/>
                <p className={"text-danger"}>Total Refunding Amount {totalRefundAmount?"$"+totalRefundAmount:""}</p>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Refund
                    </button>
                  </Col>
                </Row>
              </Form>
              {/*<br />*/}
              {/*<br />*/}
            </ModalBody>
          </div>
        </Modal>
        {/*Bulk Modal*/}
      </div>
    </div>
  )
}
OrderCancellation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default OrderCancellation
