import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  InputGroup,
  InputGroupText,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/nullship-icon.png"
import { post } from "../../helpers/api_helper"
import namelogo from "assets/images/nullship-logo.png"
// import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png"
import img12 from "assets/images/img12.png"
// import header background img
import bgimg from "assets/images/bgimg.png"
import LoginFooter from "components/LoginFooter"
import anim_logo from "../../assets/images/ainimationlogo.gif"
import config from "../../config"
import { Toaster } from "react-hot-toast"
import { toast } from "react-hot-toast"
import AuthImg from "../../assets/images/AuthImg.svg"
import ResetPasswordIcon from "../../assets/images/ResetPassword.svg"
import AuthHeader from "components/Common/AuthHeader"
import AuthFooter from "components/Common/AuthFooter"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import LockIcon from "../../assets/images/Lock.svg"

const ResetPassword = props => {
  //meta title
  document.title = `Reset Password | ${config.APP_DETAILS.APP_NAME}`

  const dispatch = useDispatch()
  const {
    match: { params },
  } = props

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")
  const [email, setEmail] = useState(params.email)
  const [token, setToken] = useState(params.token)

  const [PWFocus, setPWFocus] = useState(false)
  const [passValid, setPassValid] = useState(null)
  const [confirmValid, setConfirmValid] = useState(true)
  const [firstCondition, setFirstCondition] = useState(false)
  const [secondCondition, setSecondCondition] = useState(false)
  const [thirdCondition, setThirdCondition] = useState(false)
  const [forthCondition, setForthCondition] = useState(false)

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    const body = {
      email: email,
      token: token,
    }
    post("/user/check-reset-password", body).then(async res => {
      // // console.log(res.data);
      if (res.status !== 200) {
        toast.error(res.message)
        props.history.push("/")
      }
    })
  }, [])

  const handleResetPassword = async values => {
    try {
      if (password == confirmPassword) {
        const body = {
          email: email,
          password: password,
        }
        // console.log("body",body);
        await post("/user/reset-password", body).then(response => {
          // console.log("response", response);
          if (response.status === 200) {
            setResponseSuccess(true)
            setResponseMessage(response.message)
            props.history.push("/")
          } else {
            setResponseSuccess(false)
            setResponseMessage(response.message)
          }
        })
      } else {
        toast.error("Password and confirm password do not match")
      }
    } catch (e) {
      // console.log(e);
    }
  }

  function hasLowerCase(str) {
    return /[a-z]/.test(str)
  }

  function hasUpperCase(str) {
    return /[A-Z]/.test(str)
  }

  function hasNumber(str) {
    return /[0-9]/.test(str)
  }

  function hasSpecial(str) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return format.test(str)
  }

  const handleFocus = () => {
    // console.log("setPWFocus true")
    setPWFocus(true)
  }

  const handleFocusOut = () => {
    // console.log("setPWFocus false")
    setPWFocus(false)
  }

  const handlePassword = e => {
    setPassword(e.target.value)
    //// console.log("e.target.value.length",e.target.value.length);
    if (e.target.value.length >= 8) {
      setFirstCondition(true)
      // //// console.log(firstCondition)
    } else {
      setFirstCondition(false)
    }

    if (hasUpperCase(e.target.value) && hasLowerCase(e.target.value)) {
      setSecondCondition(true)
    } else {
      setSecondCondition(false)
    }

    if (hasNumber(e.target.value)) {
      setThirdCondition(true)
    } else {
      setThirdCondition(false)
    }

    if (hasSpecial(e.target.value)) {
      setForthCondition(true)
    } else {
      setForthCondition(false)
    }

    if (
      e.target.value.length >= 7 &&
      hasUpperCase(e.target.value) &&
      hasLowerCase(e.target.value) &&
      hasNumber(e.target.value) &&
      hasSpecial(e.target.value)
    ) {
      setPassValid(true)
    } else {
      setPassValid(false)
    }

    if (confirmPassword === e.target.value) {
      setConfirmValid(true)
    } else {
      setConfirmValid(false)
    }
  }

  const handleConfirmPassword = e => {
    setConfirmPassword(e.target.value)
    if (password === e.target.value) {
      setConfirmValid(true)
    } else {
      setConfirmValid(false)
    }
  }
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <Toaster position="top-center" reverseOrder={false} />
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5"
                                                // height={"120px"}
          width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      {/* className={"col-md-12 p-2"} */}
      <div>
        {/* className=" mx-auto loginpage" */}
        <div
          className="col-xl-11"
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: "10px",
              margin: 0,
            }}
          >
            <CardBody
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Row>
                <Col xl={6} className="segap p-0 ps-3 pe-3 ps-md-5 pe-md-5">
                  <div className="account-pages  pt-sm-3">
                    {/* <div className="bg-soft">
                      <Row>
                        <Col xs={7}>
                          <div className="text-white p-4">
                            <h5 className="text-white">Reset Password</h5>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    <Container>
                      <Row className="justify-content-center">
                        <Col
                          md={12}
                          lg={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "98vh",
                            marginBottom: "20px",
                          }}
                        >
                          <div className="overflow-hidden d-flex flex-column align-items-center justify-content-center">
                            <AuthHeader
                              label="Changed your mind?"
                              link="/login"
                              btnLabel="Go Back"
                            />
                            <div className="d-flex flex-column align-items-center justify-content-center mt-3 width80">
                              <img
                                src={ResetPasswordIcon}
                                alt="ResetPassword"
                              />
                              <h3 className="mt-2">Reset Password</h3>
                              <p className="mb-3">
                                Enter email address to reset your password
                              </p>
                              <div
                                style={{
                                  border: "1px solid #e1e4ea",
                                  width: "100%",
                                  height: "1px",
                                  marginTop: "20px",
                                }}
                              ></div>
                            </div>
                            <CardBody className="pt-0 width80">
                              {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                              <div className="p-2">
                                {responseSuccess && !responseSuccess ? (
                                  <Alert
                                    color="danger"
                                    style={{ marginTop: "13px" }}
                                  >
                                    {responseMessage}
                                  </Alert>
                                ) : null}
                                {responseSuccess && responseSuccess ? (
                                  <Alert
                                    color="success"
                                    style={{ marginTop: "13px" }}
                                  >
                                    {responseMessage}
                                  </Alert>
                                ) : null}
                                <Form
                                  className="form-horizontal"
                                  onSubmit={async e => {
                                    e.preventDefault()
                                    await handleResetPassword()
                                    return false
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{ position: "relative" }}
                                  >
                                    <Label className="form-label">
                                      Password{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                        }}
                                      >
                                        *
                                      </span>
                                    </Label>
                                    <InputGroup
                                      style={{
                                        border: "1px solid #ced4da",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <InputGroupText
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                        }}
                                      >
                                        <img src={LockIcon} alt="LockIcon" />
                                      </InputGroupText>
                                      <Input
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        value={password}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$"
                                        onChange={handlePassword}
                                        onFocus={handleFocus}
                                        onBlur={handleFocusOut}
                                        required={true}
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        style={{
                                          border: "none",
                                        }}
                                      />
                                      <InputGroupText
                                        onClick={toggleShowPassword}
                                        style={{
                                          cursor: "pointer",
                                          border: "none",
                                          background: "white",
                                        }}
                                      >
                                        {showPassword ? (
                                          <FaEyeSlash />
                                        ) : (
                                          <FaEye />
                                        )}
                                      </InputGroupText>
                                    </InputGroup>

                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {passValid == null || passValid == true
                                        ? ""
                                        : "Please fulfill the password requirements"}
                                    </span>
                                    <div
                                      className={"bg-white"}
                                      style={{
                                        display: PWFocus ? "block" : "none",
                                        position: "absolute",
                                        bottom: "-130px",
                                        backgroundColor: "red",
                                        right: "0",
                                        paddingRight: "30px",
                                        paddingLeft: "30px",
                                        borderRadius: "10px",
                                        border: "1px solid #333",
                                        zIndex: "99999",
                                      }}
                                    >
                                      <p style={{ fontWeight: "600" }}>
                                        Password Requirements
                                      </p>
                                      <ul
                                        id="ul"
                                        style={{
                                          fontSize: "12px",
                                          listStyleType: "none",
                                          marginLeft: "-50px",
                                        }}
                                      >
                                        <li
                                          className={
                                            firstCondition
                                              ? "conditionPass"
                                              : "conditionFail"
                                          }
                                        >
                                          At least 8 characters.
                                        </li>
                                        <li
                                          className={
                                            secondCondition
                                              ? "conditionPass"
                                              : "conditionFail"
                                          }
                                        >
                                          Contains uppercase and lowercase
                                          letters.
                                        </li>
                                        <li
                                          className={
                                            thirdCondition
                                              ? "conditionPass"
                                              : "conditionFail"
                                          }
                                        >
                                          Contains numbers.
                                        </li>
                                        <li
                                          className={
                                            forthCondition
                                              ? "conditionPass"
                                              : "conditionFail"
                                          }
                                        >
                                          Contains at least one special
                                          character, <br />
                                          e.g. _ ! @ # ? ]
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Confirm Password{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                        }}
                                      >
                                        *
                                      </span>
                                    </Label>
                                    <InputGroup
                                      style={{
                                        border: "1px solid #ced4da",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <InputGroupText
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                        }}
                                      >
                                        <img src={LockIcon} alt="LockIcon" />
                                      </InputGroupText>
                                      <Input
                                        name="confirmPassword"
                                        className="form-control"
                                        placeholder="Enter Confirm Password"
                                        // type="password"
                                        onChange={handleConfirmPassword}
                                        value={confirmPassword}
                                        // onClick={() => {setConfirmValid(false)}}
                                        required={true}
                                        disabled={password ? false : true}
                                        type={
                                          showConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        style={{
                                          border: "none",
                                        }}
                                      />
                                      <InputGroupText
                                        onClick={toggleShowConfirmPassword}
                                        style={{
                                          cursor: "pointer",
                                          border: "none",
                                          background: "white",
                                        }}
                                      >
                                        {showConfirmPassword ? (
                                          <FaEyeSlash />
                                        ) : (
                                          <FaEye />
                                        )}
                                      </InputGroupText>
                                    </InputGroup>

                                    <span
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {confirmValid
                                        ? ""
                                        : "Password do not match"}
                                    </span>
                                  </div>
                                  <div className="text-center">
                                    <button
                                      className="btn text-white m-auto btn-block ntb w-100"
                                      type="submit"
                                      disabled={
                                        confirmValid
                                          ? passValid
                                            ? false
                                            : true
                                          : true
                                      }
                                      style={{
                                        backgroundColor: "#835af4",
                                        borderRadius: "12px",
                                      }}
                                      onMouseEnter={e =>
                                        (e.target.style.backgroundColor =
                                          "#6999e0")
                                      }
                                      onMouseLeave={e =>
                                        (e.target.style.backgroundColor =
                                          "#835af4")
                                      }
                                    >
                                      Reset Password
                                    </button>
                                  </div>
                                </Form>
                              </div>
                            </CardBody>
                          </div>
                          <AuthFooter />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  {/* <hr style={{ width: "90%", margin: "auto" }}></hr> */}
                  {/* <div className="mt-5 text-center"> */}
                  {/*<p>*/}
                  {/*  Go back to{" "}*/}
                  {/*  <Link to="login" className="font-weight-medium text-primary">*/}
                  {/*    Login*/}
                  {/*  </Link>{" "}*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*  © {new Date().getFullYear()} Skote. Crafted with{" "}*/}
                  {/*  <i className="mdi mdi-heart text-danger" /> by Themesbrand*/}
                  {/*</p>*/}
                  {/* </div> */}
                </Col>
                <Col
                  xl={6}
                  style={{
                    background: "#fcf7fa",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={AuthImg}
                      alt="AuthImg"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
