import React, { useState, useEffect } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTableExtensions from "react-data-table-component-extensions"
import DataTable, { createTheme } from "react-data-table-component"
import { post, get } from "../../../helpers/api_helper"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Button,
   Label,
   Input,
   FormFeedback,
   Form,
   Modal,
   ModalHeader,
   ModalBody,
   CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from "reactstrap"
 import moment from "moment-timezone"
import { amountFormat } from "../../../assets/js/numberFormatter"
import config from "./../../../config"

const Referral = () => {
   
   // Page Title
   document.title = `Referral | ${config.APP_DETAILS.APP_NAME}`;

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };
   const [pending, setPending] = useState(true)
   const [filterType, setFilterType] = useState(
      localStorage.getItem("referral-filter")
        ? localStorage.getItem("referral-filter")
        : "referralData"
   )

   const [referrerData, setReferrerData] = useState([])
   const [referrerPaymentData, setReferrerPaymentData] = useState([])

   const [searchTerm, setSearchTerm] = useState("")
   const [from, setFrom] = useState("")
   const [to, setTo] = useState("")
   const [filterChecks, setFilterChecks] = useState("")
   const [filterData, setFilterData] = useState(null)

   const buttonTypes = [
      {value:'Referral', key:'referralData'},
      {value:'Payment History', key:'paymentHistory'}
   ]
   
   // Get Referral Data from API
   const retrieveReferrerData = () => {
      try {
         const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id
         }
         post('/user/get-user-referrer', body, {headers}).then((response) => {
            // console.log("Referrer Data", response)
            setReferrerData(response.data)
            console.log("Referrer Data", referrerData)
         })
      } 
      catch(e) {
         console.log()
      }
   }

   // Get Referrer Payment Data from API
   const retrieveReferrerPaymentData = () => {
      try {
         const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id
         }
         post('/user/get-user-payment-history', body, {headers}).then((response) => {
            // console.log("Referrer Payment Data", response)
            setReferrerPaymentData(response.data)
            console.log("Referrer Payment Data", referrerPaymentData)
         })
      }
      catch(e) {

      }
   }

   // Resuable handle for filter state update
   const handleToggleData = (type) => {
      // setPending(true); -> Uncomment this line
      setFilterType(type)
      setSearchTerm("")
      setFrom("")
      setTo("")
      localStorage.setItem("referral-filter", type)
   }
   
   // Get Filter Data From API
   const retrieveFilteredData = (search, from, to) => {
         const body = {
            search: search,
            from: from,
            to: to,
            user_id: JSON.parse(localStorage.getItem("authUser")).id,
         }
         console.log("Referrer Filter Body", body)
         post('/user/get-filtered-user-referrers', body, {headers}).then((response) => {
            console.log("get filter data response", response)

            if (response.status === 200) {
               // console.log("Success!")
               // if (group_by_referrer) {
               //    setPaymentGroupData(groupedData)
               //    console.log("Payment Grouped Data", groupedData)
               // }
               setFilterData(response.data)
           } else {
            // console.log("Failed")
            // setTableData(tableData)
           }
         })
   }


   const dataToDisplay = filterData ? filterData : referrerData;

   const handleCheckbox = (checked) => {
      setSelectedCheck(checked)
   }

   useEffect(() => {
      retrieveReferrerData();
      retrieveReferrerPaymentData();
      retrieveFilteredData();
      setPending(false)
   }, [filterType])

   // Referral Data Columns
   const referrer_data_columns = [
      // {
      //    name: "User Id",
      //    selector: "refer_user_id",
      //    width: "250px",
      // },
      {
         name: "Referred To",
         selector: row => row.refer_to_email,
      },
      {
         name: "Total Labels",
         selector: row => row.completed_order_count,
           width: "250px", 
      },
      {
         name: "Labels Refunded",
         selector: row => row.refunded_order_count,
           width: "250px", 
      },
      {
         name: "Created At",
         width: "250px",
         selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
      }
   ]

   // Payment History Columns
   const payment_history_columns = [
      {
         name: "Payment Mode",
         selector: row => row.payment_mode,
      },
      {
         name: "Amount (USD)",
         selector: row => '$' + amountFormat(row.amount),
         right: true,
           width: "250px", 
      },
      {
         name: "Total Labels",
         selector: row => row.order_count,
           width: "250px", 
      },
      {
         name: "Payment Date Time",
         // selector: "created_at",
         width: "300px",
         selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
      }
   ]

   createTheme('solarized', {
      text: {
         primary: '#fffff',
         secondary: '#fffff',
      },
      background: {
         default: '#0f0f0f',
      },
      context: {
         background: '#cb4b16',
         text: '#0f0f0f',
      },
      divider: {
         default: '#282828',
      },
      action: {
         button: 'rgba(0,0,0,.54)',
         hover: 'rgba(0,0,0,.08)',
         disabled: 'rgba(0,0,0,.12)',
      },
   }, 'dark');

   return (
      <div className="page-content" style={{position:"relative"}}>
         <Breadcrumbs
          title={config.APP_DETAILS.APP_NAME}
         //  sourceItem="Orders"
          breadcrumbItem="Referral"
         //  reloadPage={handleReloadPage}
         //  isOrder={true}
        />

        {/* Tabs Section */}
        <div
          style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8" }}
          className="rounded-top"
          >
         {buttonTypes.map((button, index) => (
            <button
            key={index}
            className={
              "btn btn-md rounded-top " +
              (filterType === "Reports" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == button?.key ? (localStorage.getItem("theme") == "dark"?"#2a3042":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={() => handleToggleData(button?.key)}
            disabled={pending}
          >
            {button?.value}
          </button>
         ))}
        </div>
        {/* Filter and Data Content Section */}
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
            <CardBody>
               <Form  className="form-horizontal">
                  <Row>
                     {filterType === 'referralData' && <>
                     <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">Filter</Label>
                              <input
                              type={"text"}
                              className={"form-control"}
                              placeholder={"Filter Referrals..."}
                              value={searchTerm}
                              onChange={async (e) => {
                                 setSearchTerm(e.target.value)
                                 await retrieveFilteredData(e.target.value, from, to);
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">From</Label>
                              <Input
                              name="from"
                              className="form-control"
                              type="date"
                              max={to}
                              value={from}
                              onChange={async (e) => {
                                 setFrom(e.target.value)
                                 await retrieveFilteredData(searchTerm, e.target.value, to);
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">To</Label>
                              <Input
                              name="to"
                              className="form-control"
                              type="date"
                              disabled={from == "" ? true : false}
                              title={from == "" ? "Please select from date" : ""}
                              min={from}
                              value={to}
                              onChange={async (e) => {
                                 setTo(e.target.value)
                                 await retrieveFilteredData(searchTerm, from, e.target.value);
                              }}
                              />
                           </div>
                        </div>
                  </>}
                  {filterType === 'paymentHistory' && 
                     <div style={{position:"relative"}}>
                           {/* <label htmlFor={"group-reference-email"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99", marginRight: "30px"}} onClick={(e) => { handleCheckbox('reference')}}>
                              <input type={"checkbox"} id={"group-reference-email"} name="reference" checked={selectedCheck === 'reference' ? true : false} /> <span>Group By Reference Email</span>
                           </label> */}
                           {/* <label htmlFor={"group-payment-date"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99"}}>
                              <input 
                                 type={"checkbox"} 
                                 id={"group-payment-date"} 
                                 name="payment-date" 
                                 value={filterChecks}
                                 // checked={selectedCheck === 'reference' ? true : ""} 
                                 onChange={async (e) => {
                                    setFilterChecks(e.target.checked)
                                    await retrieveFilteredData(searchTerm, from, to, e.target.checked)
                                 }}
                                 /> 
                              <span style={{ marginLeft: "10px" }}>Group By Payment Date</span>
                           </label> */}
                        </div>
                     }
                  </Row>
               </Form>

               <DataTable
                  columns={filterType === 'paymentHistory' ? payment_history_columns :  referrer_data_columns}
                  data={ filterType === 'paymentHistory' ? referrerPaymentData : dataToDisplay}
                  pagination={50}
                  // progressPending={pending}
                  progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  highlightOnHover={true}
                  // onChangePage={handleOnPageChange}
                  // onRowClicked={handleRowClick}
                  // expandableRows={true} expandableRowsComponent={expandableRows}
                  // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                  className={"order-table"}
                  theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
               />
            </CardBody>
          </Card>
         
      </div>
   )
};

export default Referral