// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

import config from "./../../../config"

function OrdersReporting(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);
  const [from, setFrom] = useState(moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [excerpt, setExcerpt] = useState(null);
  const [description, setDescription] = useState(null);
  const [category_id, setCategoryID] = useState(null);
  const [blogImage, setblogImage] = useState("");


  const columns = [
    {
      name: 'Date',
      // selector: 'date',
     sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.date ==  "Total",
          style: {
            fontWeight: "bold"
          },
        },
      ],
      selector: (row) => row.date == "Total"?"Total":moment(row.date).format('MMM DD, YYYY')
    },
    {
      name: 'Completed Orders',
      selector: row => row.count,
      sortable: true,
    },
    {
      name: 'Credit Balance',
      // selector: 'credit',
      sortable: true,
      right: true,
      selector: row => row.credit ? '$' + amountFormat(row.credit) : "$0"
    },
    {
      name: 'Debit Balance',
      // selector: 'debit',
      sortable: true,
      right: true,
      selector: row => row.debit ? '$' + amountFormat(row.debit) : "$0"
    },
  ];



  // const tableData = ;
  const retrieveOrdersReporting = async (from_date, to_date) => {
    try {
      setPending(true);
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        from_date: from_date,
        to_date: to_date,
        role_id: JSON.parse(localStorage.getItem("authUser")).role_id
      }
      post('/user/get-orders-reporting', body, {headers}).then((response) => {
        console.log("response", response);
        setFilteredData(response.data);
        setPending(false);
        response.data.push({
          "date": "Total",
          "count": response.data.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.count;
          }, 0),
          // "cancelled_orders": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.cancelled_orders;
          // }, 0),
          "sum_discounted_price": response.data.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.sum_discounted_price;
          }, 0),
          "credit": response.data.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.credit;
          }, 0),
          "debit": response.data.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.debit;
          }, 0),
          // "difference": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.difference;
          // }, 0),
          // "fedex_count": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.fedex_count;
          // }, 0),
          // "ups_count": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.ups_count;
          // }, 0),
          // "upsv2_count": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.upsv2_count;
          // }, 0),
          // "usps_count": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.usps_count;
          // }, 0),
          // "dhl_count": response.data.reduce((accumulator, currentItem) => {
          //   return accumulator + currentItem.dhl_count;
          // }, 0)
        })
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    // // console.log("here");
    retrieveOrdersReporting(from, to);
  }, []);

  const handleFrom = async (e) => {
    setFrom(e.target.value);
    await retrieveOrdersReporting(e.target.value, to);
  }

  const handleTo = async (e) => {
    setTo(e.target.value);
    await retrieveOrdersReporting(from, e.target.value);
  }

  //meta title
  document.title = `Balance Report | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} breadcrumbItem="Balance Report" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className="row">
              <div className={"col-md-2"}>
                <label>From</label>
                <input className={"form-control"} type="date" value={from} max={to} onChange={handleFrom}/>
              </div>
              <div className={"col-md-2"}>
                <label>To</label>
                <input className={"form-control"} type="date" value={to} min={from} onChange={handleTo}/>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Balance Report...`}
            >
              <DataTable columns={columns} data={filteredData} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
OrdersReporting.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default OrdersReporting;