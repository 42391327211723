// src/components/filter.
import React, { useEffect, useMemo, useState,useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { saveAs } from 'file-saver';
import customStyles from "../../../assets/css/customTableStyle"
import config from "./../../../config"

let selectedIds = [];
function CarriersLogs() {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);
  const [fileValid, setFileValid] = useState(null);
  const [fileError, setFileError] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState("");

  const [carrier_id, setCarrierID] = useState("");
  const [max_usage, setMaxUsage] = useState("");
  const [max_usage_import, setMaxUsageImport] = useState("");
  const [selectedCreateCarrier, setSelectedCreateCarrier] = useState("");
  const [modal, setModal] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const columns = [
    {
      name: 'ID',
      // selector: 'id',
      selector: row=>row.id,
    //  sortable: true,
      width: '70px'
    },
    {
      name: 'Parent ID',
      // selector: 'parent_id',
      selector: row=>row.parent_id,
    //  sortable: true,
      width: '120px',
      cell: row => row.parent_id?row.parent_id:"-"
    },
    {
      name: 'Carrier',
      selector: 'carrier_name',
    //  sortable: true,
      width: '100px',
      cell: row => row.carrier_name?row.carrier_name:"-"
    },
    {
      name: 'Carrier ID',
      selector: row=>row.carrier_id,
      // selector: 'carrier_id',
    //  sortable: true,
      minWidth: '350px'
    },
    {
      name: 'Usage',
      selector: row=>row.record_completed,
    //  sortable: true,
      width: '100px',
    },
    {
      name: 'Max Usage',
      // selector: 'max_usage',
      selector: row=>row.max_usage,

    //  sortable: true,
      width: '120px',
      cell: row => row.max_usage?row.max_usage:"-"
    },
    {
      name: 'Status',
      selector: row=>row.status,
    //  sortable: true,
      width: '100px',
      // cell: (row) =>
      //   <>
      //     {row.status===1?<span className={"badge badge-success"}>Active</span>:(row.status === 2? <span className={"badge badge-danger"}>Expired</span>: <span className={"badge badge-warning"}>Inactive</span>)}
      //   </>
    },
    {
      name: 'Created At',
      selector: 'updated_at',
    //  sortable: true,
      width:"210px",
      cell: (row) => moment(row.created).tz(moment.tz.guess()).format('MMM DD, YYYY hh:ss A')
    },
    {
      name: 'Updated At',
      selector: 'updated_at',
    //  sortable: true,
      width:"210px",
      cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:ss A')
    }
  ];

  const handleStatusChange = async (e) => {
    const { value } = e.target;
    const id = value.split(",")[1];
    const status = value.split(",")[0];
    let body = {
      id: id,
      status: status
    };
    post('/admin/update-carrier-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        retrieveCarrierIds();
        toast.success(response.message);
      }
      else{
        toast.error(response.message);
      }
    })
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName('carrier-id-checkbox');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }

  // const tableData = ;
  const retrieveCarrierIds = async () => {
    try {
      get('/admin/get-carrier-ids-all', {headers}).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carrier-names', {headers}).then((response) => {
        setCarriers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveCarrierIds();
    retrieveCarriers();
  }, []);

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(selectedCarrier !== "") {
      const formData = new FormData();
      formData.append('file', fileInput.current.files[0]);
      formData.append('carrier_id', selectedCarrier);
      formData.append('max_usage', max_usage_import);

      postFile("/admin/import-carrier-ids", formData).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveCarrierIds();
          setMaxUsageImport("");
          setSelectedCarrier("");
          setModal(!modal);
        }
        else {
          toast.error(response.message);
        }
      })
      // You can now send the formData object using Axios or fetch
    }
    else {
      toast.error("Please select a carrier");
    }
  };

  const handleCarrierIdCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      selectedIds.push(value);
    }
    else {
      let index = selectedIds.findIndex(el => el == value);
      selectedIds.splice(index, 1);
      // selectedOrders.filter((el) => el !== value);
    }

    // console.log("selectedIds",selectedIds);
  }

  const handleExport = async () => {
    if(selectedIds.length > 0) {
      const data = selectedIds.toString().replaceAll(",","\n");
      const blob = new Blob([data], {type: "text/csv;charset=utf-8"});
      saveAs(blob, 'carrier-ids-'+moment(new Date).format("DD-MMM-YYYY")+'.csv');
      selectedIds = [];
      toast.success("Carrier ids exported successfully");
      uncheckAll();
    }
    else {
      toast.error("Please select at least one carrier id");
    }
  }

  const handleCarrier = async (e) => {
    const {value} = e.target;
    setSelectedCarrier(value);
  }

  const handleCreateCarrier = async (e) => {
    const {value} = e.target;
    setSelectedCreateCarrier(value);
  }

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    let body = {
      carrier: selectedCreateCarrier,
      carrier_id: carrier_id,
      max_usage: max_usage
    };

    post('/admin/create-carrier-id', body, {headers}).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        retrieveCarrierIds();
        setMaxUsage("");
        setSelectedCreateCarrier("");
        setCarrierID("");
        setModalCreate(!modalCreate);
      }
      else {
        toast.error(response.message);
      }
    })
  }


  //meta title
  document.title = `Carrier IDs Logs | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Logs" breadcrumbItem="Carrier IDs" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Carriers IDs Logs...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={50} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
CarriersLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default CarriersLogs;