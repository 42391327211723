// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"
import { amountFormat } from "../../../assets/js/numberFormatter"
import { Bar } from "react-chartjs-2"
import config from "./../../../config"

function TopLabelGenerators() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(false);
  const [type, setType] = useState("today");

  const data = {
    labels: filteredData.map(el => el.first_name + " " + el.last_name),
    datasets: [
      {
        label: "Balance Spent",
        animated: true,
        fill: true,
        backgroundColor: "#52af06",
        barThickness: 20,
        data: filteredData.map(el => amountFormat(el.total_amount?el.total_amount:0)),
        borderSkipped: false
      },
      {
        label: "Label Generated",
        animated: true,
        fill: true,
        backgroundColor: "#fc8d42",
        barThickness: 20,
        data: filteredData.map(el => el.count),
        borderSkipped: false,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true, //This will do the task
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            zeroLineColor: localStorage.getItem("theme")=="dark" ? "#a6b0c4":"black"
          },
          ticks: {
            fontColor: localStorage.getItem("theme")=="dark" ? "white":"black",
            suggestedMin: 0, // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true, // minimum value will be 0.
            stepSize: 20 // this worked as expected
          },
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: {
            zeroLineColor: localStorage.getItem("theme")=="dark" ? "#a6b0c4":"black"
          },
          ticks: {
            fontColor: localStorage.getItem("theme")=="dark" ? "white":"black",
          },
        },
      ],
    },
  }

  const retrieveTopLabelGenerators = async (type) => {
    try {
      setPending(true)
      const body = {
        type
      }
      post('/admin/get-top-label-generators', body, {headers}).then((response) => {
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'User',
      selector: (row) => row.first_name + " " + row.last_name,
      // sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email
      // sortable: true,
    },
    {
      name: 'Label Count',
      selector: (row) => row.count
    },
    {
      name: 'Balance Spent Today',
      selector: (row) => row.total_amount?"$"+amountFormat(row.total_amount):"-"
    }
  ];

  useEffect(() => {
    retrieveTopLabelGenerators(type);
  }, []);

  const handleChange = (e) => {
    setType(e.target.value)
    retrieveTopLabelGenerators(e.target.value);
  }

  //meta title
  document.title = `Top Label Generators | ${config.APP_DETAILS.APP_NAME}`;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Report" breadcrumbItem="Top Label Generators" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className={"col-md-4"}>
              <label>Filter</label>
              <select onChange={handleChange} className="form-select">
                <option value="today">Today</option>
                <option value="last-7-days">Last 7 Days</option>
                <option value="last-30-days">Last 30 Days</option>
              </select>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {filteredData.length > 0 ?
              <div style={{height:"300px"}}>
                <Bar data={data} options={options} />
              </div> : null}
            <br/>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Stores Connections...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
TopLabelGenerators.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default TopLabelGenerators;