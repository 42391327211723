import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';

const Profile = ({ toggle = () => { }, onSave = () => {} }) => {
    // const src = './example/einshtein.jpg';
    const src = '/src/assets/images/users/avatar-8.jpg';
    const [cropedImage, setCropedImage] = useState(null);
    const [imageName, setImageName] = useState(null);

    const onClose = () => {
        // setPreview(null);
        setCropedImage(null);
    };

    const onCrop = (preview) => {
        console.log("images -profile ==> ", preview)
        
        setCropedImage(preview);

    };

    const onBeforeFileLoad = (elem) => {
        
        // const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const validTypes = ['jpeg', 'png', 'jpg'];
        if (elem.target.files[0].size > 71680) {
            toast.error("File is too big!");
            elem.target.value = "";
        }
        // if (!validTypes.includes(elem.type)) {
        if (!validTypes.includes(elem.target.value.split(".").slice(-1)[0])) {
            // console.log("images -profile ==> ", elem.target.value.split(".").slice(-1).)
            console.log("images -profile ==> ", elem.target.value.split(".").slice(-1)[0])
            toast.error("Invalid file type! Please select a JPG and PNG image.");
          return  elem.target.value = "";
        }
        setImageName(elem.target.value)
    };
    const handleCancel = () => {
        onClose()
        toggle()
    }
    const handleSave = (cropedImage) => {
        onSave({img:cropedImage, imageName})
        onClose()
        toggle()
    }
    return (
        <div className='flex flex-col'>
            <div>
                <Avatar
                    // width={390}
                    width={"auto"}
                    height={300}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    // src={src}
                    src={null}
                    label="Upload Profile"
                />
            </div>
            <div className="d-flex justify-content-end mt-3"  >
                <Button type="submit" color="success" onClick={() => handleSave(cropedImage)} >
                    Save
                </Button>
                <Button type="submit" color="secondary" className='ms-2' onClick={handleCancel} >
                    Cancel
                </Button>
            </div>
        </div>

    );
};


export default Profile
// <div>
//     {
//         !Open ? <div className='flex w-96'>
//             <Avatar
//                 // width={390}
//                 width={"auto"}
//                 height={295}
//                 onCrop={onCrop}
//                 onClose={onClose}
//                 onBeforeFileLoad={onBeforeFileLoad}
//                 src={src}
//                 lanbel="Upload Profile"
//             />
//             <button className='bg-success' onClick={() => setPreview(cropedImage)}> save</button>
//             {preview && <img src={preview} alt="Preview" />}
//         </div> : <div>Upload Profile</div>
//     }
// </div>