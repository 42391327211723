import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import LoginFooter from "components/LoginFooter"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import "../../assets/css/loginfooter.css"
import namelogo from "assets/images/nullship-logo.png"
// import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png"
import img12 from "assets/images/img12.png"
// import header background img
import bgimg from "assets/images/bgimg.png"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
// import logo from "../../assets/images/logo.svg";
// import logo from "../../assets/images/nullship-icon.png";
import logo from "../../assets/images/ainimationlogo.gif"
import { post } from "../../helpers/api_helper"
import config from "../../config"
import { Toaster } from "react-hot-toast"
import { toast } from "react-hot-toast"
import "./stylesheet.css"
import AuthHeader from "components/Common/AuthHeader"
import AuthImg from "../../assets/images/AuthImg.svg"
import AuthFooter from "components/Common/AuthFooter"
import EnvelopeIcon from "../../assets/images/Envelope.svg"
import ResetPassword from "../../assets/images/ResetPassword.svg"
import { FaInfoCircle } from "react-icons/fa"
const ForgetPasswordPage = props => {
  //meta title
  document.title = `Forget Password | ${config.APP_DETAILS.APP_NAME}`

  const dispatch = useDispatch()

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(handleForgetPassword(values))
    },
  })

  const handleForgetPassword = async values => {
    try {
      console.log("values", values)
      const { email } = values
      let loading = toast.loading("Sending password reset email...")
      await post("/user/forget-password", { email: email }).then(response => {
        // console.log("response", response);
        toast.remove(loading)
        if (response.status === 200) {
          setResponseSuccess(true)
          setResponseMessage(response.message)

          setTimeout(() => {
            setResponseMessage("")
            setResponseSuccess(null)
          }, 3000)
          // props.history.push('/dashboard');
        } else {
          setResponseSuccess(false)
          setResponseMessage(response.message)
        }
      })
    } catch (e) {
      // console.log(e);
    }
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <Toaster position="top-center" reverseOrder={false} />
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5"
                                                // height={"120px"}
          width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      {/* className={"col-md-12 p-2"} */}
      <div>
        {/* className=" mx-auto loginpage" */}
        <div
          className="col-xl-11"
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: "10px",
              margin: 0,
            }}
          >
            <CardBody
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Row>
                <Col xl={6} className="segap p-0 ps-3 pe-3 ps-md-5 pe-md-5">
                  <div className="account-pages  pt-sm-3">
                    <Container>
                      <Row className="justify-content-center">
                        <Col
                          md={12}
                          lg={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "98vh",
                            marginBottom: "20px",
                          }}
                        >
                          <div className="overflow-hidden d-flex flex-column align-items-center justify-content-center">
                            <AuthHeader
                              label="Changed your mind?"
                              link="/login"
                              btnLabel="Go Back"
                            />
                            <div className="d-flex flex-column align-items-center justify-content-center mt-3 width80">
                              <img src={ResetPassword} alt="ResetPassword" />
                              <h3 className="mt-2">Reset Password</h3>
                              <p className="mb-3">
                                Enter email address to reset your password
                              </p>
                              <div
                                style={{
                                  border: "1px solid #e1e4ea",
                                  width: "100%",
                                  height: "1px",
                                  marginTop: "20px",
                                }}
                              ></div>
                            </div>

                            <CardBody className="pt-3 width80">
                              <div className="p-2">
                                {responseSuccess && !responseSuccess ? (
                                  <Alert
                                    color="danger"
                                    style={{ marginTop: "13px" }}
                                  >
                                    {responseMessage}
                                  </Alert>
                                ) : null}
                                {responseSuccess && responseSuccess ? (
                                  <Alert
                                    color="success"
                                    style={{ marginTop: "13px" }}
                                  >
                                    {responseMessage}
                                  </Alert>
                                ) : null}

                                <Form
                                  className="form-horizontal"
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                  }}
                                >
                                  {/* {responseError ? (
                                    <Alert color="danger">
                                      {responseMessage}
                                    </Alert>
                                  ) : null} */}

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Email{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                        }}
                                      >
                                        *
                                      </span>
                                    </Label>
                                    <InputGroup
                                      className={
                                        validation.touched.email &&
                                        validation.errors.email
                                          ? "1px solid red"
                                          : ""
                                      }
                                      style={{
                                        border:
                                          validation.touched.email &&
                                          validation.errors.email
                                            ? "1px solid red"
                                            : "1px solid #ced4da",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <InputGroupText
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                        }}
                                      >
                                        <img
                                          src={EnvelopeIcon}
                                          alt="EnvelopeIcon"
                                        />
                                      </InputGroupText>
                                      <Input
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        type="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                          validation.touched.email &&
                                          validation.errors.email
                                            ? true
                                            : false
                                        }
                                        style={{
                                          border: "none",
                                        }}
                                      />
                                    </InputGroup>
                                    {validation.touched.email &&
                                    validation.errors.email ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.email}
                                      </FormFeedback>
                                    ) : null}
                                  </div>

                                  <div className="text-with-icon">
                                    <FaInfoCircle
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <span>
                                      Enter the email with which you've
                                      registered.
                                    </span>
                                  </div>
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn text-white m-auto btn-block ntb w-100"
                                      type="submit"
                                      style={{
                                        backgroundColor: "#835af4",
                                        borderRadius: "12px",
                                      }}
                                      onMouseEnter={e =>
                                        (e.target.style.backgroundColor =
                                          "#6999e0")
                                      }
                                      onMouseLeave={e =>
                                        (e.target.style.backgroundColor =
                                          "#835af4")
                                      }
                                    >
                                      Reset
                                    </button>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center flex-column mt-2">
                                    <p>Don't have access anymore?</p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        borderBottom: "2px solid",
                                      }}
                                    >
                                      Try another method
                                    </p>
                                  </div>
                                  {/* <Row className="m-auto">
                                    <Col className="text-center">
                                      <button
                                        className="btn btn-dark w-50 m-auto btn-block ntb"
                                        type="submit"
                                      >
                                        Reset
                                      </button>
                                    </Col>
                                  </Row> */}
                                </Form>
                              </div>
                            </CardBody>
                          </div>
                          <AuthFooter />
                        </Col>
                      </Row>
                    </Container>
                    {/* <div className="bg-soft bg-soft-primary">
                      <Row>
                        <Col xs={9}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">
                              Reset your password
                            </h5>
                            <p className={"text-dark"}>
                              Send reset instructions to your email address.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <CardBody className="pt-0"> */}
                    {/* <div> */}
                    {/*<Link to="/">*/}
                    {/* <div className="avatar-md profile-user-wid mb-4"> */}
                    {/* <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            // className="rounded-circle"
                            height="34"
                          />
                        </span> */}
                    {/* </div> */}
                    {/*</Link>*/}
                    {/* </div> */}
                    {/* <div className="p-2">
                        {responseSuccess && !responseSuccess ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null}
                        {responseSuccess && responseSuccess ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null} */}
                    {/* 
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row className="m-auto">
                            <Col className="text-center">
                              <button
                                className="btn btn-dark w-50 m-auto btn-block ntb"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </Form> */}
                    {/* </div> */}
                    {/* </CardBody> */}
                  </div>
                  {/* <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="login" className="fw-medium text-danger">
                        Login
                      </Link>{" "}
                    </p> */}
                  {/*<p>*/}
                  {/*  © {new Date().getFullYear()} Skote. Crafted with{" "}*/}
                  {/*  <i className="mdi mdi-heart text-danger" /> by Themesbrand*/}
                  {/*</p>*/}
                  {/* </div> */}
                </Col>

                <Col
                  xl={6}
                  style={{
                    background: "#fcf7fa",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={AuthImg}
                      alt="AuthImg"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
