// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem, ModalBody
} from "reactstrap"
import Select from "react-select/base"
import config from "./../../../config"


function UserTicketGenerate(props) {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);

  const getCategories = () => {
    get('/user/get-categories', {headers}).then((response) => {
      setCategories(response.data);
    });
  }

  useEffect(() => {
    getCategories();
  }, []);

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      message: '',
      category: '',
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Please Enter Message"),
      category: Yup.string().required("Please Select A Category"),
    }),
    onSubmit: (values) => {
      dispatch(handleGenerateTicket(values));
    }
  });

  const handleGenerateTicket = (values) => {
    try {
      const {message, category} = values;
      let loading = toast.loading("Generating Ticket...");
      document.body.style.pointerEvents = "none";
      const formData = new FormData();
      for (let i=0;i<fileInput.current.files.length;i++) {
        formData.append('files', fileInput.current.files[i]);
      }
      formData.append('message', message);
      formData.append('category', category);
      formData.append('id', JSON.parse(localStorage.getItem("authUser")).id);
      postFile('/user/generate-ticket', formData, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        if(response.status === 200) {
          toast.success(response.message);
          setTimeout(() => {
            props.history.push("/my/tickets");
          },2000)
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  //meta title
  document.title = `Generate Ticket | ${config.APP_DETAILS.APP_NAME}`;


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={config.APP_DETAILS.APP_NAME} sourceItem="Tickets" breadcrumbItem="Generate Ticket" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Ticket Information</CardTitle>
                <div className="">
                  <form
                    className="form-horizontal"
                    encType={"multipart/form-data"}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Message</Label>
                      <Input
                        name="message"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Message"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.message || ""}
                        invalid={
                          validation.touched.message && validation.errors.message ? true : false
                        }
                      />
                      {validation.touched.message && validation.errors.message ? (
                        <FormFeedback type="invalid">{validation.errors.message}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Category</Label>
                      <select
                        className="form-select"
                        name="category"
                        // onChange={validation.handleChange}
                        required={true}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.category || ""}
                      >
                        <option selected={true}>Select a Category</option>
                        {categories.map((part, id) => {
                          return (<option key={id} value={part.name}>{part.name}</option>)
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Select Attachments</Label>
                      <input
                        type="file"
                        // required={true}
                        className="form-control"
                        ref={fileInput}
                        multiple={true}
                        accept={"image/*,.pdf,.xlsx,.csv"}
                        onChange={handleChange} />
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/my/tickets') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Generate
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
UserTicketGenerate.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default UserTicketGenerate;